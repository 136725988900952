import React from "react";

import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  ScatterSeries,
  Category,
  DataLabel,
  SeriesDirective,
} from "@syncfusion/ej2-react-charts";
import {
  HtmlEditor,
  RichTextEditorComponent,
} from "@syncfusion/ej2-react-richtexteditor";

import "../../assets/custom-stylesheet/excel_table.css";

const CosmeticPrint = ({
  Melting,
  Hydraulic,
  CosmeticGridData,
  centerPoints,
  chartData,
  XniceNumbers,
  Mold_Name,
  Session_Name,
  YniceNumbers,
  textRender,
  Comment,
  PressureUnits,
  TemperatureUnits,
}) => {
  return (
    <div className="Print">
      <div>
        <h1> Cosmetic Process Data </h1>
      </div>

      {/* Div for input's */}
      <div className="Input-Table">
        <table>
          <thead>
            <tr>
              <th> Mold Id </th>
              <th> Session Name </th>
              <th> Pressure Units </th>
              <th> Temperature Units </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {Mold_Name} </td>
              <td> {Session_Name} </td>
              <td> {PressureUnits} </td>
              <td> {TemperatureUnits} </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Div for Grid */}
      <div>
        <h3> Data View </h3>
      </div>

      <div className="excel-table">
        <table>
          <thead>
            <tr>
              <th>
                {" "}
                <span> {Melting} </span>{" "}
              </th>

              <th>
                {" "}
                <span> Low {Hydraulic} </span>{" "}
              </th>

              <th>
                {" "}
                <span> High {Hydraulic} </span>{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {CosmeticGridData.map((NewRow, rowId) => (
              <tr key={rowId}>
                <td> {NewRow.Melt_Temp} </td>
                <td> {NewRow.Low} </td>
                <td> {NewRow.High} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Div for Chart */}
      <div className="Printable-Chart">
        <ChartComponent
          title="Cosmetic Process Study"
          width="1100"
          primaryXAxis={{
            title: `${Melting}`,
            minimum: XniceNumbers.min,
            maximum: XniceNumbers.max,
            interval: XniceNumbers.step,
            lineStyle: { color: "black" },
          }}
          primaryYAxis={{
            title: `${Hydraulic}`,
            minimum: YniceNumbers.min,
            maximum: YniceNumbers.max,
            interval: YniceNumbers.step,
            lineStyle: { color: "black" },
          }}
          tooltip={{ enable: true, shared: false }}
          textRender={textRender}
        >
          <Inject services={[LineSeries, Category, DataLabel, ScatterSeries]} />

          <SeriesCollectionDirective>
            <SeriesDirective
              type="Line"
              dataSource={chartData}
              xName="x"
              yName="y"
              marker={{ visible: true }}
              fill="rgb(255,0,0)"
              width={2.5}
            ></SeriesDirective>

            <SeriesDirective
              dataSource={centerPoints}
              xName="x"
              yName="y"
              width={2}
              marker={{
                dataLabel: { visible: true },
                shape: "Diamond",
                width: 10,
                height: 10,
              }}
              type="Scatter"
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      {/* Div for Comment */}
      <div className="Print pb-4">
        <div>
          <h3> Comments </h3>
        </div>

        <div style={{ border: "2px solid black" }}>
          <RichTextEditorComponent value={Comment} height={200} width={800}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default CosmeticPrint;
