import React, { useState, useEffect } from 'react';
import {
    Category,
    ChartComponent,
    ColumnSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    Inject,
    Tooltip,
    ScrollBar,
    LineSeries,
    StripLine,
    AxesDirective,
    AxisDirective,
    StripLinesDirective,
    StripLineDirective,
} from '@syncfusion/ej2-react-charts';

function calculateNiceNumbers(min, max, numTicks) {

    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
        niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
        niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
        niceStep = 5 * magnitudePow;
    } else {
        niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from({ length: numNiceTicks }, (_, i) => niceMin + i * niceStep);

    return {
        min: niceMin,
        max: niceMax,
        step: niceStep,
        ticks: niceTicks,
    };
}

const MRBarChart = ({ chartData, upperLimit, lowerLimit, average, subGroupSize, minValue, maxValue }) => {

    const [isChartVisible, setChartVisible] = useState(true);

    const [clickedX, setClickedX] = useState(null);

    const lines = { width: 0 };

    const primaryxAxis = {
        lineStyle: { color: 'black' },
        valueType: 'Double',
        minimum: minValue,
        maximum: maxValue,
        interval: 1,
        majorGridLines: {
            width: 0,
        },
        labelPlacement: 'onticks',
        stripLines: [
            {
                start: clickedX - 0.02,
                end: clickedX,
                color: 'Blue',
                visible: true,
                opacity: 0.5,
                zIndex: 'Behind',
            },
        ]
    };

    const noOfTicks = 5;

    const calNiceNum = calculateNiceNumbers(lowerLimit, upperLimit, noOfTicks);

    // console.log(calNiceNum)

    const primaryyaxis = {
        lineStyle: { color: 'black' },
        title: 'MR Chart',
        minimum: calNiceNum.min,
        maximum: calNiceNum.max,
        interval: calNiceNum.step,
        majorGridLines: {
            color: 'tranparent',
        },
    };

    const handlePointClick = (args) => {

        // Extract x and y coordinates from the event object
        const xCoordinate = args.point.x;

        setClickedX(xCoordinate);

    };

    useEffect(() => {
        if (chartData && chartData.length > 0) {

            setChartVisible(true);
        } else {
            setChartVisible(false);
        }
    }, [chartData]);

    // Generate x values based on the length of the chartData
    const originalXValues = chartData.map((_, index) => index + 1);

    // Extend x values till the last point if subgroupSize is 1
    const extendedXValues = subGroupSize === 1 ? [...originalXValues, originalXValues.length + 1] : originalXValues;

    // Create data points for upper limit, average, and lower limit with extended x values
    const data1 = extendedXValues.map((x) => ({ x, y: upperLimit }));
    const data2 = extendedXValues.map((x) => ({ x, y: average }));
    const data3 = extendedXValues.map((x) => ({ x, y: lowerLimit }));

    return (
        <>
            <div>
                {isChartVisible && (
                    <ChartComponent
                        id="chart2"
                        border={{ width: 1, color: 'black' }}
                        primaryXAxis={primaryxAxis}
                        tooltip={{ enable: true }}
                        primaryYAxis={primaryyaxis}
                        pointClick={handlePointClick}
                        width='1250'
                        height='200vh'
                    >
                        {/* yAxisName='yAxis1' */}
                        <Inject services={[ColumnSeries, Tooltip, Category, ScrollBar, LineSeries, StripLine]} />

                        <AxesDirective>

                            <AxisDirective rowIndex={0} name='yAxis1' opposedPosition={true} majorGridLines={lines} lineStyle={lines} minimum={calNiceNum.min - calNiceNum.step || null} maximum={calNiceNum.max || null} interval={calNiceNum.step || null} majorTickLines={{ width: '0' }}>

                                <StripLinesDirective>

                                    <StripLineDirective start={upperLimit} end={upperLimit * 0.0001} text='UCL' color='transparent' visible={true} opacity={0.03} zIndex='Over' textStyle={{ fontWeight: 'bold' }} horizontalAlignment='End'>
                                    </StripLineDirective>

                                    <StripLineDirective start={lowerLimit} end={lowerLimit + lowerLimit * 0.0001} text='LCL' color='transparent' visible={true} opacity={0.03} zIndex='Over' textStyle={{ fontWeight: 'bold' }} horizontalAlignment='End'>
                                    </StripLineDirective>

                                    <StripLineDirective start={average} end={average + average * 0.0001} text='Avg' color='transparent' visible={true} opacity={0.03} zIndex='Over' textStyle={{ fontWeight: 'bold' }} horizontalAlignment='End'>
                                    </StripLineDirective>

                                </StripLinesDirective>

                            </AxisDirective>
                        </AxesDirective>

                        <SeriesCollectionDirective>

                            <SeriesDirective
                                dataSource={chartData}
                                xName='x'
                                yName='y'
                                // name="Data"
                                yAxisName='yAxis1'
                                marker={{ visible: true, width: 2.5, height: 2, fill: "Black" }}
                                fill="Black"
                                width={1.5}
                            />

                            {/* Line for upper limit */}
                            <SeriesDirective
                                dataSource={data1}
                                xName="x"
                                yName="y"
                                // name="Upper Limit"
                                type="Line"
                                fill="red"
                                yAxisName='yAxis1'
                                visible={true}
                                width={1.5} />

                            {/* Line for average */}
                            <SeriesDirective
                                dataSource={data2}
                                xName="x"
                                yName="y"
                                // name="Average"
                                type="Line"
                                fill="blue"
                                yAxisName='yAxis1'
                                visible={true}
                                width={1.5} />


                            {/* Line for lower limit */}
                            <SeriesDirective
                                dataSource={data3}
                                xName="x"
                                yName="y"
                                // name="Lower Limit"
                                type="Line"
                                fill="red"
                                yAxisName='yAxis1'
                                visible={true}
                                width={1.5} />


                        </SeriesCollectionDirective>

                    </ChartComponent>
                )}
            </div>

        </>
    )
}

export default MRBarChart;