import React, { useEffect } from 'react';
import { SpreadsheetComponent, SheetsDirective, SheetDirective } from '@syncfusion/ej2-react-spreadsheet';
import { getRangeIndexes } from '@syncfusion/ej2-spreadsheet';
import '../App.css';

const ColdGrid1 = ({ column, ColdGridData, ColdRunnerSpreadsheet, setColToBeDeleted, setRowToBeDeleted, rowCount, PopulateSheet, colCount, setColCount, GetDataFromSheet, setColHeader, setArgs, RenderHeaders, showAlert }) => {

    const protectSettings = { selectCells: true };

    const scrollSettings = { isFinite: true };

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
            args.content = 'This cell is read only';
        }

        // Edit the dialog content using the dialogBeforeOpen event.
        if (args.dialogName === 'ValidationErrorDialog') {
            args.cancel = true
        }

    }

    useEffect(() => {

        // Adding custom function for calculating the percentage between two cells.
        ColdRunnerSpreadsheet.current.lockCells('A2:Z300', false);

        // eslint-disable-next-line
    }, [column])

    const getIndex = () => {

        // Getting the index of the selected row and storing in a variable
        let cell = ColdRunnerSpreadsheet.current.getActiveSheet().activeCell;

        let cellIdx = getRangeIndexes(cell);

        setRowToBeDeleted(cellIdx[0] + 1);

        if (column.length <= 2 || cellIdx[1] === 0) {
            setColToBeDeleted(null);
        }
        else {
            setColToBeDeleted(cellIdx[1]);
            setColHeader(column[cellIdx[1]].header)
        }

        GetDataFromSheet()

    }

    function onCreated() {

        //Applies data validation to specified range in the active sheet.
        ColdRunnerSpreadsheet.current.addDataValidation(
            {
                type: 'Decimal',
                operator: 'Between',
                value1: '-10000.0000',
                value2: '10000.0000',
            },
            `A1:Z${ColdGridData.length}`
        );

    }

    const cellEditing = (args) => {

        if (args.value !== args.oldValue && !isNaN(args.value)) {

            ColdRunnerSpreadsheet.current.updateCell({ value: args.value }, args.address);

            showAlert.current = true

            getIndex()

        }else {

            showAlert.current = false

        }

    };

    const UnlockCells = () => {

        PopulateSheet()

        if (ColdRunnerSpreadsheet) {
            ColdRunnerSpreadsheet.current.lockCells('A1:Z100', false);
        }

        ColdRunnerSpreadsheet.current.setColumnsWidth(90, ['A:Z']);

        ColdRunnerSpreadsheet.current.getActiveSheet().rowCount = rowCount || 5

    }

    const beforeCellRenderHandler = (args) => {

        PopulateSheet()

        setColCount(column.length)

        setArgs(args)

        RenderHeaders()

    }

    return (
        <>

            <div className="spreadsheet" onMouseEnter={UnlockCells}>

                <SpreadsheetComponent onBlur={getIndex} cellEdited={getIndex} cellEditing={cellEditing} ref={ColdRunnerSpreadsheet} width={"100%"} height={200} showFormulaBar={false} showSheetTabs={false} showRibbon={false} dialogBeforeOpen={dialogBeforeOpen.bind(this)} scrollSettings={scrollSettings} beforeCellRender={beforeCellRenderHandler} created={onCreated} allowAutoFill={false}enableContextMenu={false} allowImage={false}>

                    <SheetsDirective>

                        <SheetDirective colCount={colCount} isProtected={true} protectSettings={protectSettings}>

                        </SheetDirective>

                    </SheetsDirective>

                </SpreadsheetComponent>

            </div>

        </>
    )
}

export default ColdGrid1;
