import React from "react";

// Chart from syncfusion
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
} from "@syncfusion/ej2-react-charts";

import {
  HtmlEditor,
  RichTextEditorComponent,
} from "@syncfusion/ej2-react-richtexteditor";

import "../../assets/custom-stylesheet/excel_table.css";

const ViscosityPrint = ({
  Injection_Speed_Units,
  IntensificationRatio,
  Pressure_Units,
  Injection_Speed,
  Mold_Name,
  Session_Name,
  Comment,
  ShearRateNiceNumbers,
  niceNumbers,
  InjectionniceNumbers,
  InjectionSpeedArray,
  YTitle,
}) => {
  return (
    <div className="Print">
      <div>
        <h1> Viscosity Curve Data </h1>
      </div>

      {/* Div for input's */}
      <div className="Input-Table">
        <table>
          <thead>
            <tr>
              <th> Mold Id </th>
              <th> Session Name </th>
              <th> Injection Speed Units </th>
              <th> Intensification Ratio </th>
              <th> Pressure Units </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {Mold_Name} </td>
              <td> {Session_Name} </td>
              <td>
                {" "}
                {Injection_Speed_Units ? Injection_Speed_Units : "in/sec"}{" "}
              </td>
              <td> {IntensificationRatio} </td>
              <td> {Pressure_Units ? Pressure_Units : "N/A"} </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Div for Grid */}
      <div>
        <h3> Data View </h3>
      </div>

      <div>
        <div className="excel-table">
          <table>
            <thead>
              <tr>
                <th> Injection Speed </th>
                <th> Fill Time </th>
                <th> Peak Inj Press </th>
                <th> Viscosity </th>
                <th> Shear Rate </th>
                <th> Absolute Drop Viscosity </th>
                <th> % Drop Viscosity </th>
              </tr>
            </thead>
            <tbody>
              {InjectionSpeedArray.map((NewRow, rowId) => (
                <tr key={rowId}>
                  <td> {NewRow["Injection Speed"]} </td>

                  <td> {NewRow["FillTime (sec)"]} </td>

                  <td> {NewRow["Peak Inj Press"]} </td>

                  <td> {NewRow["Viscosity"] ? NewRow["Viscosity"] : "-"} </td>

                  <td> {NewRow["Shear Rate"] ? NewRow["Shear Rate"] : "-"} </td>

                  <td>
                    {" "}
                    {NewRow["FillTime (sec)"] && NewRow["Peak Inj Press"]
                      ? rowId === 0
                        ? "-"
                        : InjectionSpeedArray[rowId].Viscosity === "" ||
                          InjectionSpeedArray[rowId - 1].Viscosity === "" ||
                          InjectionSpeedArray[rowId - 1].Viscosity === 0
                        ? "-"
                        : InjectionSpeedArray[rowId].Fill_Time === ""
                        ? "-"
                        : Math.round(
                            InjectionSpeedArray[rowId - 1].Viscosity -
                              InjectionSpeedArray[rowId].Viscosity
                          )
                      : "--"}{" "}
                  </td>

                  <td>
                    {" "}
                    {NewRow["FillTime (sec)"] && NewRow["Peak Inj Press"]
                      ? rowId === 0
                        ? "-"
                        : InjectionSpeedArray[rowId].Viscosity === "" ||
                          InjectionSpeedArray[rowId - 1].Viscosity === "" ||
                          InjectionSpeedArray[rowId - 1].Viscosity === 0
                        ? "-"
                        : InjectionSpeedArray[rowId].Fill_Time === ""
                        ? "-"
                        : Number(
                            (Math.round(
                              InjectionSpeedArray[rowId - 1].Viscosity -
                                InjectionSpeedArray[rowId].Viscosity
                            ) *
                              100) /
                              InjectionSpeedArray[rowId - 1].Viscosity
                          ).toFixed(1)
                      : "--"}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Div for Chart */}
      <div className="Printable-Chart">
        <div
          className={
            Injection_Speed ? "ViscosityChart" : "Viscosity_Showable_Chart"
          }
        >
          <ChartComponent
            width="1000"
            title="Viscosity Curve"
            primaryXAxis={
              InjectionniceNumbers.min &&
              InjectionniceNumbers.max &&
              InjectionniceNumbers.step
                ? {
                    title: `Injection Speed (${Injection_Speed_Units})`,
                    minimum: InjectionniceNumbers.min,
                    maximum: InjectionniceNumbers.max,
                    interval: InjectionniceNumbers.step,
                    lineStyle: { color: "black" },
                  }
                : {
                    title: `Injection Speed (${Injection_Speed_Units})`,
                    lineStyle: { color: "black" },
                  }
            }
            primaryYAxis={
              niceNumbers.min && niceNumbers.max && niceNumbers.step
                ? {
                    title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
                    minimum: niceNumbers.min,
                    maximum: niceNumbers.max,
                    interval: niceNumbers.step,
                    lineStyle: { color: "black" },
                  }
                : {
                    title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
                    lineStyle: { color: "black" },
                  }
            }
          >
            <Inject services={[LineSeries, Category, DataLabel]} />

            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={InjectionSpeedArray}
                type="Line"
                xName="Injection Speed"
                yName="Viscosity"
                marker={{ visible: true }}
                fill="rgb(2,0,4)"
                width={2.5}
              ></SeriesDirective>
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>

        <div
          className={
            Injection_Speed ? "Viscosity_Showable_Chart" : "ViscosityChart"
          }
        >
          <ChartComponent
            width="1000"
            title="Viscosity Curve"
            primaryXAxis={
              ShearRateNiceNumbers.min &&
              ShearRateNiceNumbers.max &&
              ShearRateNiceNumbers.step
                ? {
                    title: "Shear Rate (1 / sec)",
                    valueType: "Double",
                    minimum: ShearRateNiceNumbers.min,
                    maximum: ShearRateNiceNumbers.max,
                    interval: ShearRateNiceNumbers.step,
                  }
                : {
                    title: "Shear Rate (1 / sec)",
                    valueType: "Double",
                  }
            }
            primaryYAxis={
              niceNumbers.min && niceNumbers.max && niceNumbers.interval
                ? {
                    title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
                    minimum: niceNumbers.min,
                    maximum: niceNumbers.max,
                    interval: niceNumbers.step,
                  }
                : {
                    title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
                  }
            }
          >
            <Inject services={[LineSeries, Category, DataLabel]} />

            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={InjectionSpeedArray}
                type="Line"
                xName="Shear Rate"
                yName="Viscosity"
                marker={{ visible: true }}
                fill="rgb(2,0,4)"
                width={2.5}
              ></SeriesDirective>
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      </div>

      {/* Div for Comment */}
      <div className="Print">
        <div>
          <h3> Comments </h3>
        </div>

        <div style={{ border: "2px solid black" }}>
          <RichTextEditorComponent value={Comment} height={160} width={800}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default ViscosityPrint;
