import { combineReducers } from 'redux';
import auth from './auth';
import database from './database';
import compatibility from './compatibility';

export default combineReducers({
    auth,
    database,
    compatibility
});
