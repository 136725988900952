import React, { useEffect, useState } from "react";
import DataService from "../../../services/ApiService";
import "../../App.css";

function Summary({ session, mold }) {
  const [data, setData] = useState({ anova_table: null, results: null });

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [noOfDimensions, setnoOfDimensions] = useState([]);

  const [fetchval, setFetchVal] = useState(true);

  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
    // Dropdown Cavity-set Data
    DataService.GetDefineResponse(session, mold)
      .then((res) => {
        const dimensionJSON = res.data["dimension"];

        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];

        setnoOfDimensions(dataArray);
      })
      .then(() => {
        GetSummary();
      })
      .then(() => {
        SelectDropdown();
      })
      .then(() => {
        setShowTable(true);
      })
      .catch((err) => {
        // console.error("Error sending data to Django:", err);
      });

    // console.log(noOfDimensions);

    if (fetchval) {
      setFetchVal(false);
      setShowTable(true);
    }

    if (selectedIndex !== null) {
      GetSummary();
    }
  }, [fetchval, session, selectedIndex]);

  const { p_values } = data.results || {};

  // Set Index upon selection from dropdown
  const handleIndexChange = (event) => {
    setSelectedIndex(parseInt(event.target.value, 10));
  };

  const GetSummary = () => {
    // API Call for Anova Table Data
    DataService.Anova(session)
      .then((res) => {
        setData(res.data);
        // console.log("anova tableresponse",res)
        setShowTable(true);
      })
      .catch((err) => {
        // console.error("Error sending data to Django:", err);
      });
  };

  // API => Post selected dropdown value
  const SelectDropdown = () => {
    return new Promise(async (resolve, reject) => {
      const selectedDimension = noOfDimensions[selectedIndex];

      try {
        // const decry = atob(sessionId);
        DataService.SelectDropdown(session, {
          selected: selectedDimension,
        })
          .then((res) => {})
          .catch((err) => {
            // console.error("Error sending data to Django:", err);
          });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <div className="m-3">
      <div className="mb-2">

      {/* Select Cavity Dropdown */}
        <select value={selectedIndex} onChange={handleIndexChange}>
          {noOfDimensions.map((dimension, index) => (
            <option key={index} value={index}>
              {dimension.charAt(0).toUpperCase() + dimension.slice(1)}
            </option>
          ))}
        </select>
      </div>

      {/* <Button onClick={handleClick}>Compute</Button> */}

      <div>
      {/* Anova Table */}

        {data.anova_table && showTable && (
          <>
           
            <table className="summary-table" id="anovatable">
            <thead>
              <tr>
              <th></th>
              <th colSpan={5} style={{ textAlign: 'center' }}>ANOVA Table</th>
              </tr>
            </thead>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center',width: '20%', whiteSpace: 'nowrap' }}>Factors</th>
                  <th style={{ textAlign: 'center',width: '5%', whiteSpace: 'nowrap' }}>Sum of Square</th>
                  <th style={{ textAlign: 'center',width: '3%', whiteSpace: 'nowrap' }}>Degrees of Freedom</th>
                  <th style={{ textAlign: 'center',width: '5%', whiteSpace: 'nowrap' }}>Mean Square</th>
                  <th style={{ textAlign: 'center',width: '5%', whiteSpace: 'nowrap' }}>Fvalue</th>
                  <th style={{ textAlign: 'center',width: '5%', whiteSpace: 'nowrap' }}>Pvalue</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data.anova_table).map(
                  ([key, values], index) => {
                    const newKey = key.replace(":", "*").replace(/_/g, " ");
                    return (
                      <tr key={key}>
                        <td style={{ textAlign: 'left'}}>{newKey}</td>
                        <td style={{ textAlign: 'right'}}>{Number(values.SS).toFixed(5)}</td>
                        <td style={{ textAlign: 'right'}}>{parseInt(values.df)}</td>
                        <td style={{ textAlign: 'right'}}>{Number(values.MS).toFixed(5)}</td>
                        <td style={{ textAlign: 'right'}}>{Number(values.F).toFixed(5)}</td>
                        {p_values && (
                          <td style={{ textAlign: 'right'}}>{Number(p_values[index]).toFixed(5)}</td>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>

            <br />
          </>
        )}
      </div>
    </div>
  );
}

export default Summary;
