import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Speed = ({
  SpeedEnglishDecimals,
  setSpeedEnglishDecimals,
  SpeedMetricDecimals,
  setSpeedMetricDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert
}) => {
  // Function to store the metric units data
  const StoreSpeedMetricUnits = () => {
    showAlert.current = true;

    // setSpeedEnglishDecimals(0.12);

    SpeedUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Speed[0].unit_id,
      unit_name: StoredUnits[0].Unit_Data[0].Speed[0].unit_name,
      decimals: SpeedMetricDecimals,
    };

    // console.log(SpeedUnits.current)

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreSpeedEnglishUnits = () => {
    showAlert.current = true;

    // setSpeedMetricDecimals(0.1);

    SpeedUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Speed[1].unit_id || null,
      unit_name: StoredUnits[0].Unit_Data[0].Speed[1].unit_name || null,
      decimals: SpeedEnglishDecimals,
    };

    // console.log(SpeedUnits.current)

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML input template to bind with the grid for metric part
  function SpeedMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="SpeedUnit"
        defaultChecked={
          SpeedUnits.current.unit_id ===
          StoredUnits[0].Unit_Data[0].Speed[0].unit_id
            ? true
            : false
        }
        onClick={StoreSpeedMetricUnits}
      />
    );
  }

  // HTML input template to bind with the grid for english part
  function SpeedEnglishRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="SpeedUnit"
        defaultChecked={
          SpeedUnits.current.unit_id ===
          StoredUnits[0].Unit_Data[0].Speed[1].unit_id
            ? true
            : false
        }
        onClick={StoreSpeedEnglishUnits}
      />
    );
  }

  const StoreMetricDecimals = (e) => {
    setSpeedMetricDecimals(e.target.value);

    SpeedUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Speed[0].unit_id || null,
      unit_name: StoredUnits[0].Unit_Data[0].Speed[0].unit_name || null,
      decimals: parseFloat(e.target.value),
    };

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML drop down template to bind with the grid for metric part
  function SpeedDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={SpeedMetricDecimals ? SpeedMetricDecimals : 0.1}
        onChange={StoreMetricDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  const StoreSpeedEnglishDecimals = (e) => {
    setSpeedEnglishDecimals(e.target.value);

    SpeedUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Speed[1].unit_id || null,
      unit_name: StoredUnits[0].Unit_Data[0].Speed[1].unit_name || null,
      decimals: parseFloat(e.target.value),
    };

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML drop down template to bind with the grid for english part
  function SpeedEnglishDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={SpeedEnglishDecimals ? SpeedEnglishDecimals : 0.12}
        onChange={StoreSpeedEnglishDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  // Data for Speed grid
  let speedData = [
    {
      Speed: "Injection Speed",
      MetricUnit: `${StoredUnits[0].Unit_Data[0].Speed[0].unit_name}`
        ? `${StoredUnits[0].Unit_Data[0].Speed[0].unit_name}`
        : null,
      Metric: 20,
      MetricDecimals: 0.1,
      EnglishUnit: `${StoredUnits[0].Unit_Data[0].Speed[1].unit_name}`
        ? `${StoredUnits[0].Unit_Data[0].Speed[1].unit_name}`
        : null,
      English: 20,
      EnglishDecimals: 0.12,
    },
  ];

  // Columns for Speed grid
  const SpeedColumns = [
    {
      field: "Speed",
      headerText: "Speed",
      width: 100,
    },
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      headerText: "Metric",
      template: SpeedMetricRadioTemplate.bind(this),
      width: 100,
    },
    {
      field: "MetricDecimals",
      template: SpeedDropDownTemplate.bind(this),
      headerText: "Decimals",
      width: 100,
    },
    {
      field: "EnglishUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "English",
      headerText: "English",
      width: 100,
      template: SpeedEnglishRadioTemplate.bind(this),
    },
    {
      field: "EnglishDecimals",
      template: SpeedEnglishDropDownTemplate.bind(this),
      headerText: "Decimals",
      width: 100,
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={speedData}
        columns={SpeedColumns}
      ></GridComponent>
    </div>
  );
};

export default Speed;
