import {
  GET_STORED_UNITS,
  NO_STORED_UNITS,
  USER_SELECTED_UNITS,
  NO_SELECTED_UNITS,
  NO_MATERIAL_DATA,
  GET_MATERIAL_DATA,
  GET_MACHINE_DATA,
  NO_MACHINE_DATA,
  GET_MOLD_DATA,
  NO_MOLD_DATA,
  GET_FIELDS_DATA,
  NO_FIELDS_DATA
} from "../actions/types";

const initialState = {
  StoredUnits: [],
  SelectedUnits: [],
  MaterialData: [],
  MachineData: [],
  MoldData: [],
  FieldsData: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STORED_UNITS:
      sessionStorage.setItem("StoredUnits", JSON.stringify(payload));
      return {
        ...state,
        StoredUnits: payload,
      };
    case NO_STORED_UNITS:
      return {
        ...state,
        StoredUnits: [],
      };
    case USER_SELECTED_UNITS:
      sessionStorage.setItem("SelectedUnits", JSON.stringify(payload));
      return {
        ...state,
        SelectedUnits: payload,
      };
    case NO_SELECTED_UNITS:
      return {
        ...state,
        SelectedUnits: [
          {
            Area: {
              unit_id: 1,
              decimals: 0.12,
              unit_name: "sq cm",
            },
            Time: {
              unit_id: 3,
              decimals: 0,
              unit_name: "sec",
            },
            Speed: {
              unit_id: 6,
              decimals: 0.1,
              unit_name: "mm/sec",
            },
            Weight: {
              unit_id: 8,
              decimals: 0.12,
              unit_name: "Gms",
            },
            Distance: {
              unit_id: 10,
              decimals: 0.12,
              unit_name: "mm",
            },
            Pressure: {
              unit_id: 12,
              decimals: 0.12,
              unit_name: "MPa",
            },
            Temperature: {
              unit_id: 16,
              decimals: 0,
              unit_name: "deg C",
            },
          },
        ],
      };
    case GET_MATERIAL_DATA:
      sessionStorage.setItem("MaterialData", JSON.stringify(payload));
      return {
        ...state,
        MaterialData: payload,
      };
    case NO_MATERIAL_DATA:
      return {
        ...state,
        MaterialData: [],
      };
    case GET_MACHINE_DATA:
      sessionStorage.setItem("MachineData", JSON.stringify(payload));
      return {
        ...state,
        MachineData: payload,
      };
    case NO_MACHINE_DATA:
      return {
        ...state,
        MachineData: [],
      };
    case GET_MOLD_DATA:
      sessionStorage.setItem("MoldData", JSON.stringify(payload));
      return {
        ...state,
        MoldData: payload,
      };
    case NO_MOLD_DATA:
      return {
        ...state,
        MoldData: [],
      };
    case GET_FIELDS_DATA:
      sessionStorage.setItem("FieldsData", JSON.stringify(payload));
      return {
        ...state,
        FieldsData: payload,
      };
    case NO_FIELDS_DATA:
      return {
        ...state,
        FieldsData: [],
      };
    default:
      return state;
  }
}
