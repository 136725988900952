import React, { useState, useEffect, useRef } from "react";
import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import { Button } from "reactstrap";
import ColdGrid1 from "../Grids/ColdGrid1";
import { nanoid } from "nanoid";
import ColdAddColumn from "../columns&rows/ColdAddColumn";
import ColdAddRow from "../columns&rows/ColdAddRow";
import { data, data2 } from "../data/Cold_runner";
import ColdGrid2 from "../Grids/ColdGrid2";
import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../App.css";
import ColdEdit from "../modals/ColdEdit";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";

import ColdPrint from "../printables/ColdPrint";

import { useReactToPrint } from "react-to-print";

import { getCell } from "@syncfusion/ej2-react-spreadsheet";

export let chartInstance;

const ColdRunner = ({ showAlert, ToSaveColdData, ColdData }) => {
  let ColdRunnerSpreadsheet = useRef();

  let ColdRunnerCalcSpreadsheet = useRef();

  let ColdRunnerChart = useRef();

  const [ColToBeDeleted, setColToBeDeleted] = useState(1);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  const [rowCount, setRowCount] = useState(5);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Alert, setAlert] = useState(false);

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      showAlert.current = true;
      setAlert(true);
    }
  };

  const [modal, setModal] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const [modal2, setModal2] = useState();

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const [modal3, setModal3] = useState();

  const toggle3 = () => {
    setModal3(!modal3);
  };

  // ************ Functions to deal with column ************
  const [header, setHeader] = useState();
  const [column, setColumn] = useState(data);
  const [isColumnId, setIsColumnId] = useState(null);
  const [toggleEdit, setToggleEdit] = useState(true);
  const [grid2, setGrid2] = useState("value1");
  const [ColdGridHeader, setColdGridHeader] = useState("Weight 1");

  const [colCount, setColCount] = useState(column.length);

  const [ColHeader, setColHeader] = useState("");

  const [args, setArgs] = useState("");

  const RenderHeaders = () => {
    // console.log(column)

    // Condition to check whether the rendered element is header cell.
    for (let i = 0; i < column.length; i++) {
      if (
        args.colIndex === i &&
        args.element.classList.contains("e-header-cell")
      ) {
        const text = column[i].header;
        args.element.innerText = text;
      }
    }
  };

  const addHeader = (e) => {
    e.preventDefault();
    setHeader(e.target.value);
  };

  const addColumn = () => {
    if (!header) {
    } else {
      const newColumn = {
        id: nanoid(),
        header: header,
        edit: true,
        delete: true,
      };

      setColumn([...column, newColumn]);

      setColCount(colCount + 1);

      setHeader("");

      showAlert.current = true;

      setAlert(true);
    }
  };

  const GetDataFromSheet = () => {
    // Getting the data from the CavitySpreadsheet cells
    let ColdDataObj = {};
    let ColdDataArray = [];

    // console.log(ColdGridData.length)

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < column.length; j++) {
        ColdDataObj[`value${j}`] =
          getCell(i, j, ColdRunnerSpreadsheet.current.getActiveSheet()).value ||
          null;
      }

      ColdDataArray.push(ColdDataObj);

      ColdDataObj = {};
    }

    setColdGridData(ColdDataArray);

    // console.log(ColdDataArray)
  };

  const PopulateSheet = () => {
    const alphabetArray = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    setColCount(column.length);

    for (let j = 1; j <= column.length; j++) {
      for (let i = 1; i <= ColdGridData.length; i++) {
        ColdRunnerSpreadsheet.current.updateCell(
          { value: ColdGridData[i - 1][`value${j - 1}`] },
          `${alphabetArray[j - 1]}${i}`
        );
      }
    }

    // console.log(ColdGridData)
  };

  const editColumnHeader = () => {
    if (header && !toggleEdit) {
      setColumn(
        column.map((element) => {
          if (element.id === isColumnId) {
            return { ...element, header: header };
          }
          return element;
        })
      );

      ColdRunnerSpreadsheet.current.refresh();

      RenderHeaders();

      setHeader("");

      setIsColumnId(null);

      setColdGridHeader(header);

      showAlert.current = true;

      setAlert(true);
    } else {
      ColdRunnerSpreadsheet.current.refresh();
    }
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    if (!ColToBeDeleted || column.length <= 2) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
      setColHeader(column[ColToBeDeleted].header);
    }
  };

  const deleteColumn = () => {
    ColdRunnerSpreadsheet.current.delete(
      ColToBeDeleted,
      ColToBeDeleted,
      "Column"
    );

    setColumn((prevArray) => {
      const newArray = [...prevArray];

      newArray.splice(ColToBeDeleted, 1);

      return newArray;
    });

    for (let j = 1; j < column.length; j++) {
      for (let i = 0; i < ColdGridData.length; i++) {
        delete ColdGridData[i][`value${ColToBeDeleted}`];
      }
    }

    GetDataFromSheet();

    setDeleteConfirmToggle(!DeleteConfirmToggle);

    setColdGridHeader("");

    setChartData([]);

    ColdRunnerSpreadsheet.current.refresh();

    showAlert.current = true;

    setAlert(true);
  };

  const editColumn = (id) => {
    setIsColumnId(id);
    setToggleEdit(false);
  };

  const editCancel = () => {
    setIsColumnId(null);
    setToggleEdit(true);
  };

  // ************ Functions to deal with row ***************
  const [row, setRow] = useState(null);
  const [ColdGridData, setColdGridData] = useState(data2);
  const [chartData, setChartData] = useState([]);

  const addRow = (e) => {
    e.preventDefault();
    setRow(e.target.value);
  };

  const increaseRow = () => {
    if (row) {
      setRowCount(parseInt(rowCount) + parseInt(row));

      showAlert.current = true;

      setAlert(true);

      toggle2();
    } else {
      alert("Please enter number of rows.");
    }
  };

  const deleteRow2 = () => {
    ColdRunnerSpreadsheet.current.delete(
      rowToBeDeleted,
      rowToBeDeleted,
      "Row",
      0
    );

    setRowCount(parseInt(rowCount) - 1);

    ColdGridData.splice(rowToBeDeleted - 1, 1);

    // console.log(rowToBeDeleted)

    showAlert.current = true;

    setAlert(true);
  };

  // To get the Session Id from url using useParams hook
  var { sessionId, moldName, sessionName } = useParams();

  // Variable to store the a Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Session_Name, setSession_Name] = useState();

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // Event the current session's viscosity data
  const handleGet = (SessionId) => {
    if (SessionId) {
      DataService.FetchGateSeal(SessionId)
        .then((res) => {
          if (res.data) {
            ToSaveColdData.current = false;

            // storing sheet column data and number of columns
            setColumn(res.data.Column_Details);

            setColCount(res.data.Column_Details.length);

            // storing sheet row data
            setColdGridData(res.data.Column_Data);

            setComment(res.data.Comment);

            // console.log(res.data)

            setColdGridHeader(res.data.Column_Details[1].header);

            ColdRunnerSpreadsheet.current.refresh();
          } else {
            setColumn(data);
            setColdGridData(data2);
          }
        })
        .catch((err) => {});
    } else {
      setColumn(data);
      setColdGridData(data2);
    }
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      Column_Details: column,
      Column_Data: ColdGridData,
      Comment: Comment ? Comment : "N/A",
      PrintData: {
        column: column,
        ColdGridData: ColdGridData,
        grid2: grid2,
        Mold_Name: Mold_Name,
        Session_Name: Session_Name,
        ColdGridHeader: ColdGridHeader,
        Comment: Comment,
        chartData: chartData,
        WeightniceNumbers: WeightniceNumbers,
        TimeniceNumbers: TimeniceNumbers,
      },
    };

    ColdData.current = data;

    // eslint-disable-next-line
  }, [ColdGridData, column, Comment]);

  const getTimeValues = (data) => {
    return new Promise((resolve, reject) => {
      const Time = data
        .filter((item) => item["value0"] !== null)
        .map((item) => item["value0"]);

      resolve(Time);
    });
  };

  const CheckForTime = (Time) => {
    return new Promise((resolve, reject) => {
      if (Time.length > 0) {
        const TimeSet = new Set(Time).size !== Time.length;

        // console.log(TimeSet);

        if (TimeSet) {
          reject(TimeSet);
        } else {
          resolve(TimeSet);
        }
      } else {
      }
    });
  };

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [TimeniceNumbers, setTimeNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [WeightniceNumbers, setWeightNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  // Event to set the Mix, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    let weightArray = [],
      timeArray = [];

    // This is the event to sort the data based on Time
    const compareValue = (a, b) => {
      // Check if either object has null values
      const hasNullA = Object.values(a).some((value) => value === "");
      const hasNullB = Object.values(b).some((value) => value === "");

      if (hasNullA && !hasNullB) {
        return 1; // Move object with null values to the end
      } else if (!hasNullA && hasNullB) {
        return -1; // Keep object with values at the top
      } else {
        // Compare based on the value0 property for objects without null values
        return a.value0 - b.value0;
      }
    };

    // This is the event to sort the data based on Weight
    const compareValue2 = (a, b) => {
      return a[`${grid2}`] - b[`${grid2}`];
    };

    for (let i = 0; i < ColdGridData.length; i++) {
      if (ColdGridData[i].value0) {
        weightArray.push(ColdGridData[i]);
        timeArray.push(ColdGridData[i]);
      } else {
      }
    }

    ColdGridData.sort(compareValue);
    timeArray.sort(compareValue);
    weightArray.sort(compareValue2);

    setChartData(timeArray);

    // console.log(timeArray);

    PopulateSheet(timeArray);

    let margin;

    const Data = ColdGridData.filter((data) => {
      return data.value0 ? data.value0 !== "" : null;
    });

    if (Data.length > 0) {
      margin =
        ((parseInt(timeArray[timeArray.length - 1].value0) -
          parseInt(timeArray[0].value0)) /
          100) *
        5;

      setTimeNiceNumbers(
        calculateNiceNumbers(
          timeArray[0].value0 - margin,
          timeArray[timeArray.length - 1].value0 + margin,
          5
        )
      );

      if (grid2) {
        setWeightNiceNumbers(
          calculateNiceNumbers(
            weightArray[0][`${grid2}`],
            weightArray[weightArray.length - 1][`${grid2}`],
            5
          )
        );
      }
    }

    // console.log(WeightniceNumbers, weightArray, grid2)
  };

  const [TimeAlert, setTimeAlert] = useState(true);

  const ToggleTimeAlert = () => {
    setTimeAlert(!TimeAlert);
  };

  const [InsufficientDataAlertForChart, setInsufficientDataAlertForChart] =
    useState(true);

  const ToggleInsufficientDataAlertForChart = () => {
    setInsufficientDataAlertForChart(!InsufficientDataAlertForChart);
  };

  // Event to Scroll to Chart
  const PlotChart = () => {
    getTimeValues(ColdGridData).then((Time) => {
      if (Time.length > 0) {
        CheckForTime(Time)
          .then((TimeSet) => {
            // console.log(TimeSet);

            scaleGraph();
          })
          .catch((TimeSet) => {
            ToggleTimeAlert();
            setChartData([]);
          });
      } else {
        ToggleInsufficientDataAlertForChart();
        setChartData([]);
      }
    });
  };

  const PlotChartOnLoad = () => {
    getTimeValues(ColdGridData).then((Time) => {
      if (Time.length > 0) {
        CheckForTime(Time)
          .then((TimeSet) => {
            scaleGraph();
          })
          .catch((TimeSet) => {});
      } else {
      }
    });
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) PlotChartOnLoad();

    // eslint-disable-next-line
  }, [ColdGridData]);

  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));

    // After that it call's this event to fetch the data
    handleGet(SessionId);

    // eslint-disable-next-line
  }, [sessionId, SessionId, moldName, sessionName]);

  const [showPrint, setShowPrint] = useState(false);

  const componentRef = useRef();

  const printPage = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setShowPrint(true),
    onAfterPrint: () => setShowPrint(false),
  });

  const handlePrint = () => {
    setShowPrint(true);

    setTimeout(() => {
      printPage();
    }, 100);
  };

  const setGrid = (e) => {
    var val = document.getElementById("Cold-Column");

    var ColdHeader = val.options[val.selectedIndex].text;

    setGrid2(`value${e.target.value}`);

    setColdGridHeader(ColdHeader);

    // console.log(ColdHeader);

    setChartData([]);
  };

  return (
    <>
      <div className="Cold">
        <div>
          <Modal isOpen={!TimeAlert} centered={true}>
            <ModalHeader toggle={ToggleTimeAlert}> Nautilus </ModalHeader>
            <ModalBody>
              <span> Injection Speed values cannot repeat. </span>
            </ModalBody>
            <ModalFooter>
              <Button color="dark" onClick={ToggleTimeAlert}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={!InsufficientDataAlertForChart} centered={true}>
            <ModalHeader toggle={ToggleInsufficientDataAlertForChart}>
              {" "}
              Nautilus{" "}
            </ModalHeader>
            <ModalBody>
              <span> The data is Insufficient. </span>
            </ModalBody>
            <ModalFooter>
              <Button
                color="dark"
                onClick={ToggleInsufficientDataAlertForChart}
              >
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={DeleteAlertToggle} centered={true}>
            <ModalHeader toggle={DeleteModalAlertToggle}>
              Delete Alert.
            </ModalHeader>

            <ModalBody>
              {column.length > 2
                ? "Select a column to delete"
                : "Atleast one column is mandatory."}
            </ModalBody>

            <ModalFooter>
              <Button color="dark" onClick={DeleteModalAlertToggle}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={DeleteConfirmToggle} centered={true}>
            <ModalHeader toggle={DeleteModalConfirmToggle}>
              Delete Confirmation.
            </ModalHeader>

            <ModalBody>
              {ColHeader
                ? `Are you sure you want to delete ${ColHeader} column ?`
                : ""}
            </ModalBody>

            <ModalFooter>
              <Button color="dark" onClick={deleteColumn}>
                {" "}
                Delete{" "}
              </Button>
              <Button color="dark" onClick={DeleteModalConfirmToggle}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <section className="card sixstepCard p-3 ml-2">
          <div className="b-primary b-r-4 ">
            <div className="pt-2 pb-2 pr-2 pl-1">
              <div className="d-flex col-md-12 ">
                <div>
                  <ColdAddColumn
                    modal={modal}
                    toggle={toggle}
                    addColumn={addColumn}
                    addHeader={addHeader}
                  />
                </div>
                <div>
                  <ColdAddRow
                    modal2={modal2}
                    toggle2={toggle2}
                    addRow={addRow}
                    increaseRow={increaseRow}
                  />
                </div>
                <div>
                  <ColdEdit
                    modal3={modal3}
                    toggle3={toggle3}
                    column={column}
                    addHeader={addHeader}
                    editColumnHeader={editColumnHeader}
                    editCancel={editCancel}
                    editColumn={editColumn}
                  />
                </div>

                <div>
                  <button
                    className="btn btn-warning btn-air-warning mr-2"
                    type="button"
                    onClick={DeleteModalConfirmToggle}
                  >
                    {" "}
                    Delete Column{" "}
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-warning btn-air-warning mr-2"
                    type="button"
                    onClick={deleteRow2}
                  >
                    {" "}
                    Delete Row{" "}
                  </button>
                </div>

                <div>
                  <button
                    className="btn btn-primary btn-air-primary mr-2"
                    type="button"
                    onClick={handleShow}
                  >
                    Comment
                  </button>
                </div>

                <div>
                  <button
                    className="btn btn-secondary btn-air-secondary mr-2"
                    onClick={handlePrint}
                  >
                    {" "}
                    Print{" "}
                  </button>
                </div>

                <Modal isOpen={show} centered>
                  <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                  <ModalBody>
                    <RichTextEditorComponent
                      change={getComment}
                      value={Comment}
                      saveInterval="1"
                      toolbarSettings={toolbarSettings}
                      height={250}
                    >
                      <Inject services={[Toolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="dark" onClick={handleClose}>
                      {" "}
                      Save & Close{" "}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>

            <div>
              <div className="d-flex p-2">
                <div className="col-md-6" width={{ height: "35%" }}>
                  <div className="p-1" onClick={() => setToPlotChart(false)}>
                    {/* Grid 1 */}
                    <ColdGrid1
                      ColdGridData={ColdGridData}
                      Alert={Alert}
                      ColdRunnerSpreadsheet={ColdRunnerSpreadsheet}
                      setColToBeDeleted={setColToBeDeleted}
                      setRowToBeDeleted={setRowToBeDeleted}
                      rowCount={rowCount}
                      setColdGridData={setColdGridData}
                      setGrid2={setGrid2}
                      setColCount={setColCount}
                      colCount={colCount}
                      PopulateSheet={PopulateSheet}
                      column={column}
                      setRowCount={setRowCount}
                      GetDataFromSheet={GetDataFromSheet}
                      setColHeader={setColHeader}
                      setArgs={setArgs}
                      RenderHeaders={RenderHeaders}
                      setColdGridHeader={setColdGridHeader}
                      showAlert={showAlert}
                      className="coldGrid"
                    />
                  </div>

                  <div className="p-1">
                    {/* Grid 2 */}
                    <ColdGrid2
                      column={column}
                      ColdGridData={ColdGridData}
                      ColdGridHeader={ColdGridHeader}
                      grid2={grid2}
                      ColToBeDeleted={ColToBeDeleted}
                      ColdRunnerCalcSpreadsheet={ColdRunnerCalcSpreadsheet}
                      rowCount={rowCount}
                      setRowCount={setRowCount}
                      ColHeader={ColHeader}
                      className="coldGrid"
                    />
                  </div>
                </div>

                <div className="m-1 col-md-6" width={{ height: "65%" }}>
                  {/* <div className="col-md-10"> */}

                  <ChartComponent
                    className="chartComponent"
                    tooltip={{ enable: true }}
                    ref={ColdRunnerChart}
                    width="100%"
                    height="400"
                    border={{ width: 1, color: "darkblue" }}
                    title="Gate Seal Study"
                    primaryXAxis={{
                      title: "Time",
                      minimum: TimeniceNumbers.min || null,
                      maximum: TimeniceNumbers.max || null,
                      interval: TimeniceNumbers.step || null,
                      lineStyle: { color: "black" },
                    }}
                    primaryYAxis={{
                      title:
                        `${ColdGridHeader}` === "Time"
                          ? ""
                          : `${ColdGridHeader}`,
                      minimum: WeightniceNumbers.min || null,
                      maximum: WeightniceNumbers.max || null,
                      interval: WeightniceNumbers.step || null,
                      lineStyle: { color: "black" },
                    }}
                  >
                    <Inject
                      services={[LineSeries, Category, DataLabel, Tooltip]}
                    />

                    <SeriesCollectionDirective>
                      {/* chartData is the name of the Array which contains our data and again grid2 will be varying */}
                      <SeriesDirective
                        type="Line"
                        fill="rgb(2,0,4)"
                        width={2.5}
                        dataSource={chartData}
                        xName="value0"
                        yName={grid2 ? grid2 : "value1"}
                        marker={{ visible: true }}
                      ></SeriesDirective>
                    </SeriesCollectionDirective>
                  </ChartComponent>

                  {/* </div> */}

                  <div>
                    <div className="ml-4">
                      <div className="d-flex mt-1">
                        <div className="mr-2">
                          <label
                            className="lbl_design mr-1 mt-2"
                            style={{ fontSize: 12 }}
                          >
                            {" "}
                            Y-Axis:{" "}
                          </label>
                        </div>

                        <div style={{ width: "200px" }}>
                          <select
                            // style={{ height:24 }}
                            className="form-control digits mt-1"
                            id="Cold-Column"
                            onChange={setGrid}
                            onClick={(e) => setGrid2(`value${e.target.value}`)}
                          >
                            {column.map((value, key) => (
                              <React.Fragment key={key}>
                                {value.id === 0 ? (
                                  "-"
                                ) : (
                                  <option value={key === 0 ? key + 1 : key}>
                                    {" "}
                                    {value.header}{" "}
                                  </option>
                                )}
                              </React.Fragment>
                            ))}
                          </select>
                        </div>

                        <div>
                          <button
                            className="btn btn-primary btn-air-primary ml-1"
                            onClick={PlotChart}
                          >
                            {" "}
                            Show Graph{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ************************* Printable Part ********************* */}
      <section
        className={showPrint ? "Printable_Part" : "Showable_Part"}
        ref={componentRef}
      >
        <ColdPrint
          column={column}
          ColdGridData={ColdGridData}
          grid2={grid2}
          Mold_Name={Mold_Name}
          Session_Name={Session_Name}
          ColdGridHeader={ColdGridHeader}
          Comment={Comment}
          chartData={chartData}
          WeightniceNumbers={WeightniceNumbers}
          TimeniceNumbers={TimeniceNumbers}
        />
      </section>
    </>
  );
};

export default ColdRunner;
