import React from "react";
import { Table } from "react-bootstrap";
import "../../../assets/custom-stylesheet/grid_stylecss.css";

const NoteTable = () => {
  return (
    <>
     <p
        style={{
          color: "blue",
          textDecoration: "underline", // This will underline the text
          fontWeight: "bold",
        }}
      >
        Notes:
      </p>
       <Table bordered>
      <thead>
        <tr>
          <th>Confidence Rating</th>
          <th>Significance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          
        >
          <td style={{
            backgroundColor: "#d4edda", // Light green
            color: "#155724",           // Dark green text
          }}>A</td>
          <td style={{
            backgroundColor: "#d4edda", // Light green
            color: "#155724",           // Dark green text
          }}>
            Dimension will always be within specification between all values
            used in DOE. The Dimension is low risk.
          </td>
        </tr>
        <tr
          
        >
          <td style={{
            backgroundColor: "#d1ecf1", // Light blue
            color: "#0c5460",           // Dark blue text
          }}>B</td>
          <td style={{
            backgroundColor: "#d1ecf1", // Light blue
            color: "#0c5460",           // Dark blue text
          }}>
            Dimension is process sensitive and must be monitored. There exists a
            risk of being out of specification.
          </td>
        </tr>
        <tr
         
        >
          <td  style={{
            backgroundColor: "#f8d7da", // Light red
            color: "#721c24",           // Dark red text
          }}>C</td>
          <td  style={{
            backgroundColor: "#f8d7da", // Light red
            color: "#721c24",           // Dark red text
          }}>
            It is impossible to make the part within specification by process
            change only.
          </td>
        </tr>
      </tbody>
    </Table>
    </>
  );
};

export default NoteTable;
