import React, {useState, useEffect, useRef } from "react";
import DataService from "../../../services/ApiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import QuantitativeGrid from "./QuantitativeGrid";
import SuggestedDimGrid from "./SuggestedDimGrid";
import SuggestedDim from "./SuggestedDim";
import "../../App.css";
import "../Splitter.css";

const SuggestedDimStats = () => {
  const { sessionId } = useParams();

  const decry = atob(sessionId);

  const [NumericalPredictionResponse, setNumericalPredictionResponse] =
    useState([]);

  const [SpinnerData, setSpinnerData] = useState([]);

  const [ResponseName, setResponseName] = useState([]);

  const [ChartTitle, setChartTitle] = useState([]);

  const [LowValues, setLowValues] = useState();

  const [Limit, setLimit] = useState([]);

  const [rangeColumnData, setRangeColumnData] = useState([]);

  const [GoldSpotIndex, setGoldSpotIndex] = useState();

  const [GoldSpotSetting, setGoldSpotSetting] = useState();

  const [PredictedValues, setPredictedValues] = useState([]);

  const [Specifications, setSpecifications] = useState();

  useEffect(() => {
    const NumericalAPI = () => {
      return new Promise(async (resolve, reject) => {
        try {
          DataService.NumericalPrediction(decry).then((res) => {
            const responseData = res.data;

            setNumericalPredictionResponse(responseData);

            setSpinnerData(responseData.spinner_data);

            setLowValues(responseData.low);

            setResponseName(responseData.range_column_data);

            setChartTitle(responseData.title);

            setRangeColumnData(responseData.range_column_data);

            setLimit(responseData.limit);

            setGoldSpotIndex(responseData.suggestedDim_GoldSpotIndex);

            setGoldSpotSetting(responseData.suggestedDim_GoldSpotSetting);

            setSpecifications(responseData.specifications);

            let PredictedValuesArray = [];

            for (let i = 0; i < responseData.dimension.length; i++) {
              PredictedValuesArray.push(responseData.dimension[i].dimension[0]);
            }

            setPredictedValues(PredictedValuesArray);
          });

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    NumericalAPI();
  }, [decry]);

  // Step 1: Group data by the dimension name
  const grouped_data = {};

  rangeColumnData.forEach((item) => {
    const [dimension, high, low] = item;
    const parts = dimension.split("~");
    const title = dimension.split("-");
    const titleValue = title[2];
    // const key = parts.slice(2).join("~"); // Extract the part of the dimension name after "cav-x-"
    const cavity_info = parts[1]; // Extract the cavity info (e.g., "cav-4-ABC")

    if (!grouped_data[titleValue]) {
      grouped_data[titleValue] = [];
    }
    grouped_data[titleValue].push({ cavity_info, titleValue, high, low });
  });

  // Step 2: Format the grouped data into the desired structure
  const formatted_data = {};

  Object.keys(grouped_data).forEach((key) => {
    formatted_data[key] = [];
    grouped_data[key].forEach((item) => {
      const { cavity_info, titleValue, high, low } = item;
      try {
        // Extract the cavity number from the cavity info (e.g., "cav-4-ABC")
        const cavity_number = parseInt(cavity_info.split("-")[1], 10);
        formatted_data[key].push({
          title: titleValue,
          x: cavity_number,
          high: high,
          low: low,
        });
      } catch (error) {}
    });
  });

  // Step 3: Collect the formatted data into an array
  const range_col = [];
  Object.values(formatted_data).forEach((value) => {
    range_col.push(value);
  });

  const newRange = [];
  ChartTitle.forEach((title) => {
    if (formatted_data[title]) {
      newRange.push(formatted_data[title]);
    }
  });

  let x_axis = range_col.map((subArray) => [
    subArray[0].x,
    subArray[subArray.length - 1].x,
  ]);

  let x = x_axis[0];

  let scalled_x = range_col.map((subArray) => [
    subArray[0].x - 1,
    subArray[subArray.length - 1].x + 1,
  ]);

  let x_limits = scalled_x[0];
  // console.log(x)

  let numGroup = 0;
  if (x !== undefined) {
    for (let val = x[0]; val <= x[1]; val++) {
      numGroup++;
      // console.log("numGroup",numGroup)
    }
  }

  const res_array = Limit.map((entry) => {
    const entryResults = [];
    for (let val = x_limits[0]; val <= x_limits[1]; val++) {
      entryResults.push({
        X: val,
        usl: entry.usl,
        nom: entry.nom,
        lsl: entry.lsl,
      });
    }
    return entryResults;
  });

  // Calculate the number of groups based on the length of PredictedValues and x array
  let numGroups;
  if (x !== undefined) {
    numGroups = PredictedValues.length / numGroup;
  }

  // console.log(numGroups)

  let newscatterData = [];
  let scatter_j_array = []
  if(x){
    for (let i=x[0];i<=x[1];i++){
      scatter_j_array.push(i)
    }
    
  }


  for (let i = 0; i < numGroups; i++) {
    let group = [];
    for (let j = 0; j < scatter_j_array.length; j++) {
      group.push({ x: scatter_j_array[j], y: PredictedValues[i * scatter_j_array.length + j] });
    }
    newscatterData.push(group);
  }



  let PredictedValuesArray_goldspot = [];
  let newgoldspotData = [];

  if (NumericalPredictionResponse) {
    for (let i = 0; i < PredictedValues.length; i++) {
      PredictedValuesArray_goldspot.push(
        NumericalPredictionResponse.dimension[i].dimension[GoldSpotIndex]
      );
    }
  }

  for (let i = 0; i < numGroups; i++) {
    let group_goldspot = [];
    for (let j = 0; j < x.length; j++) {
      group_goldspot.push({
        x: x[j],
        y: PredictedValuesArray_goldspot[i * x.length + j],
      });
    }
    newgoldspotData.push(group_goldspot);
  }

  // const pane1Content = () => {
  //   return (
  //     <>
  //       <section className="b-primary">
  //         <div className="p-2">
  //           <b style={{ textDecoration: "underline" }}> Simulated Process </b>
  //         </div>

  //         <div className="mb-4 p-2">
  //           <QuantitativeGrid
  //             Low={LowValues}
  //             SpinnerData={SpinnerData}
  //             NumericalPrediction={NumericalPredictionResponse}
  //             setPredictedValues={setPredictedValues}
  //             GoldSpotSetting={GoldSpotSetting}
  //           />
  //         </div>

  //         <div className="p-2">
  //           <span style={{ textDecoration: "underline" }}>
  //             {" "}
  //             <b> Predicted Dimensions </b>{" "}
  //           </span>
  //         </div>

  //         <div className="p-2">
  //           <SuggestedDimGrid
  //             PredictedValues={PredictedValues}
  //             ResponseName={ResponseName}
  //             Specifications={Specifications}
  //           />
  //         </div>
  //         <div className="p-2" style={{ color: "red" }}>
  //           PLEASE NOTE THAT THE RESULTS ABOVE ARE BASED ON THE MEASUREMENTS
  //           ENTERED BY THE USER. THE SOFTWARE PREDICTS THE ABOVE BASED ON THOSE
  //           ENTERED MEASUREMENTS. PLEASE ENSURE THAT THE DATA ENTERED IS
  //           RELIABLE.
  //         </div>
  //       </section>
  //     </>
  //   );
  // };

  // const pane2Content = () => {
  //   return (
  //     <>
  //       <section className="b-primary">
  //         <div style={{ overflow: "auto" }}>
  //           <SuggestedDim
  //             title={ChartTitle}
  //             Result={res_array}
  //             RangeColumnData={newRange}
  //             ScatterData={newscatterData}
  //             x_axis={x}
  //             GoldSpot={newgoldspotData}
  //           />
  //         </div>
  //       </section>
  //     </>
  //   );
  // };

  const [pane1Width, setPane1Width] = useState(45);
  const [pane2Width, setPane2Width] = useState(55);
  const draggingRef = useRef(null);

  const handleMouseDown = (e, paneIndex) => {
    draggingRef.current = { startX: e.clientX, paneIndex };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (draggingRef.current) {
      const deltaX = e.clientX - draggingRef.current.startX;
      const totalWidth = window.innerWidth;
      const newWidthPercentage = (deltaX / totalWidth) * 100;

      if (draggingRef.current.paneIndex === 1) {
        setPane1Width((prev) => Math.max(5, prev + newWidthPercentage));
        setPane2Width((prev) => Math.max(5, prev - newWidthPercentage));
      } else if (draggingRef.current.paneIndex === 2) {
        setPane2Width((prev) => Math.max(5, prev + newWidthPercentage));
      }

      draggingRef.current.startX = e.clientX;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    draggingRef.current = null;
  };

  return (
    <>
      {/* <div className="b-primary b-r-4 p-2">
        <div className="d-flex">
          <SplitterComponent
            id="expand-method"
            height="550px"
            width="100%"
          >
            <PanesDirective>
              <PaneDirective content={pane1Content} />
              <PaneDirective content={pane2Content} />
            </PanesDirective>
          </SplitterComponent>
        </div>
      </div> */}

<div
        className="b-primary"
      >
        
        {/* <section className="card"> */}

          <div className="splitter">

            <div
              className="pane"
              style={{ width: `${pane1Width}%` }}
            >
              <div className="content">

              <section className="b-primary">
              <div className="p-2">
            <b style={{ textDecoration: "underline" }}> Simulated Process </b>
          </div>

          <div className="mb-4 p-2">
            <QuantitativeGrid
              Low={LowValues}
              SpinnerData={SpinnerData}
              NumericalPrediction={NumericalPredictionResponse}
              setPredictedValues={setPredictedValues}
              GoldSpotSetting={GoldSpotSetting}
            />
          </div>

          <div className="p-2">
            <span style={{ textDecoration: "underline" }}>
              {" "}
              <b> Predicted Dimensions </b>{" "}
            </span>
          </div>

          <div className="p-2">
            <SuggestedDimGrid
              PredictedValues={PredictedValues}
              ResponseName={ResponseName}
              Specifications={Specifications}
            />
          </div>
          <div className="p-2" style={{ color: "red" }}>
            PLEASE NOTE THAT THE RESULTS ABOVE ARE BASED ON THE MEASUREMENTS
            ENTERED BY THE USER. THE SOFTWARE PREDICTS THE ABOVE BASED ON THOSE
            ENTERED MEASUREMENTS. PLEASE ENSURE THAT THE DATA ENTERED IS
            RELIABLE.
          </div>
          </section>
              </div>

            </div>

            <div
              className="resizer"
              onMouseDown={(e) => handleMouseDown(e, 1)}
            ></div>
            
            

            <div
              className="pane"
              style={{ width: `${pane2Width}%` }}
            >
              <div className="content">

              <section>
          <div style={{ overflow: "auto", height: "87vh" }}>
            <SuggestedDim
              title={ChartTitle}
              Result={res_array}
              RangeColumnData={newRange}
              ScatterData={newscatterData}
              x_axis={x}
              GoldSpot={newgoldspotData}
            />
          </div>
        </section>
               


              </div>
            </div>
            
          </div>

        {/* </section> */}
      </div>






    </>
  );
};

export default SuggestedDimStats;
