import React from "react";
import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Inject,
} from "@syncfusion/ej2-react-charts";
import {
  HtmlEditor,
  RichTextEditorComponent,
} from "@syncfusion/ej2-react-richtexteditor";

const CoolingPrint = ({
  column,
  CoolingTimeGridData,
  grid2,
  Mold_Name,
  Session_Name,
  Comment,
  chartData,
  TimeniceNumbers,
  DimniceNumbers,
  CoolingGridHeader,
}) => {
  return (
    <div className="Print">
      <div>
        <h1> Cooling Time Data </h1>
      </div>

      {/* Div for input's */}
      <div className="Input-Table">
        <table>
          <thead>
            <tr>
              <th> Mold Id </th>
              <th> Session Name </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {Mold_Name} </td>
              <td> {Session_Name} </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Div for Grid */}
      <div>
        <h3> Data View </h3>
      </div>

      <div className="Printable-Grid">
        <table>
          <thead>
            <tr>
              {column.map((value, key) => (
                <React.Fragment key={key}>
                  <th>
                    <div>
                      <div>
                        <span> {value.header} </span>
                      </div>
                    </div>
                  </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody className="grid_style">
            {CoolingTimeGridData.map((value, key1) => (
              <tr key={key1}>
                {column.map((index, key2) => (
                  <React.Fragment key={key2}>
                    <td>
                      {" "}
                      <input
                        type="text"
                        name={index.header}
                        style={{ backgroundColor: "#fff" }}
                        defaultValue={
                          CoolingTimeGridData[key1][`value${key2}`] || ""
                        }
                        className="form-control"
                        readOnly
                      />{" "}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Div for Chart */}
      <div className="Printable-Chart">
        <ChartComponent
          title="Cooling Time Study Chart"
          primaryXAxis={{
            title: "Cooling Time Study",
            minimum: TimeniceNumbers.min || null,
            maximum: TimeniceNumbers.max || null,
            interval: TimeniceNumbers.step || null,
          }}
          primaryYAxis={{
            title:
              `${CoolingGridHeader}` === "Cooling Time Study"
                ? ""
                : `${CoolingGridHeader}`,
            minimum: DimniceNumbers.min || null,
            maximum: DimniceNumbers.max || null,
            interval: DimniceNumbers.step || null,
          }}
        >
          <Inject services={[LineSeries, Category, DataLabel]} />

          <SeriesCollectionDirective>
            {/* CoolingTimeGridData is the name of the Array which contains our data and again grid2 will be varying */}
            <SeriesDirective
              type="Line"
              fill="rgb(2,0,4)"
              width={2.5}
              dataSource={chartData}
              xName="value0"
              yName={grid2 ? grid2 : "value1"}
              marker={{ visible: true }}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      {/* Div for Comment */}
      <div className="Print pb-4">
        <div>
          <h3> Comments </h3>
        </div>

        <div style={{ border: "2px solid black" }}>
          <RichTextEditorComponent value={Comment} height={200} width={800}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default CoolingPrint;
