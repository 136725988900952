import React from 'react';

function ComputedStatistics({ computeClicked, data }) {

    // console.log(data)

    return (
        <div id="Statistics_Grid" className='b-primary'>

            <table style={{ width: '100%' }} className='responsive-table'>
                <tbody>

                    <tr>
                        <th>Readings</th>
                        <td>{data.values.length || ''}</td>
                        <th>Sub Group Size</th>
                        <td>{data.sub_group_size || ''}</td>
                    </tr>

                    <tr>
                        <th colSpan={2} className='category'>Tolerance Range</th>
                        <th colSpan={2} className='category'>Data Range</th>
                    </tr>

                    <tr>
                        <th>USL</th>
                        <td>{Number(data.upper_limit).toFixed(2) || ''}</td>
                        <th>Average</th>
                        <td>{Number(data.average).toFixed(3) || ''}</td>
                    </tr>

                    <tr>
                        <th>Target</th>
                        <td>{Number(data.target).toFixed(2) || ''}</td>
                        <th></th>
                        <td></td>
                    </tr>

                    <tr>
                        <th>LSL</th>
                        <td>{Number(data.lower_limit).toFixed(2) || ''}</td>
                        <th>Data Range</th>
                        <td>{Number(data.range).toFixed(3) || ''}</td>
                    </tr>

                    <tr>
                        <th colSpan={2} className='category'>Potential Capability</th>
                        <th colSpan={2} className='category'>Overall Capability</th>
                    </tr>

                    <tr>
                        <th>Standard Deviation</th>
                        <td>{data.sd !== null ? data.sd.toFixed(3) || '' : ''}</td>
                        <th>Standard Deviation</th>
                        <td>{data.overall_sd !== null
                            ? data.overall_sd.toFixed(3)
                            || ''
                            : ''}</td>
                    </tr>

                    <tr>
                        <th>Cp</th>
                        <td>{Number(data.cp).toFixed(3)
                            || ''}</td>
                        <th>Pp</th>
                        <td>{Number(data.pp).toFixed(3)
                            || ''}</td>
                    </tr>

                    <tr>
                        <th>Cpk</th>
                        <td>{Number(data.cpk).toFixed(3)
                            || ''}</td>
                        <th>Ppk</th>
                        <td>{Number(data.ppk).toFixed(3)
                            || ''}</td>
                    </tr>

                    <tr>
                        <th colSpan={4} className='category'>Estimated Parts Per Millium</th>
                    </tr>

                    <tr>
                        <th colSpan={2} className='category'></th>
                        <th colSpan={2} className='category'>Based on Overall</th>
                    </tr>

                    <tr>
                        <th colSpan={2}>PPM &lt; LSL</th>
                        <td colSpan={2}>{Number(data.ppm[0]).toFixed(2)
                            || ''}
                        </td>
                    </tr>

                    <tr>
                        <th colSpan={2}>PPM &gt; USL</th>
                        <td colSpan={2}>{Number(data.ppm[1]).toFixed(2)
                            || ''}</td>
                    </tr>

                    <tr>
                        <th colSpan={2}>PPM</th>
                        <td colSpan={2}>{Number(data.ppm[2]).toFixed(2)
                            || ''}</td>
                    </tr>
                </tbody>
            </table>

        </div>

    );
}

export default ComputedStatistics;
