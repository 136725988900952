import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGOUT,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNIN_SUCCESS,
    SIGNIN_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL
} from '../actions/types';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null
};

export default function (state = initialState, action) {

    const { type, payload } = action;

    switch (type) {

        case LOGIN_SUCCESS:
            sessionStorage.setItem('access', payload.access);
            sessionStorage.setItem('refresh', payload.refresh);
            sessionStorage.setItem('isAuthenticated', true);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh
            }
        case SIGNIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload
            }

        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null
            }

        case LOGIN_FAIL:
        case SIGNIN_FAIL:
        case LOGOUT:
            sessionStorage.removeItem('access');
            sessionStorage.removeItem('refresh');
            sessionStorage.removeItem('StoredUnits');
            sessionStorage.removeItem('SelectedUnits');
            sessionStorage.removeItem('MachineData');
            sessionStorage.removeItem('MoldData');
            sessionStorage.removeItem('MaterialData');
            sessionStorage.removeItem('PPS_DATA');
            sessionStorage.removeItem('FieldsData');
            sessionStorage.removeItem('isAuthenticated');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null
            }

        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            }

        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
            }

        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
            return {
                ...state
            }

        default:
            return state
    }
};
