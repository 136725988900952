import React, { useState, useRef, useEffect } from "react";
import LoadGrid from "./LoadGrid";
// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";
// Syncfusion chart control
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  StripLine,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import AddRow from "./AddRow";
import {
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "reactstrap";
import DataService from "../../../services/ApiService";
import { toast } from "react-toastify";

const LoadSensitivity = () => {
  let LoadSpreadsheet = useRef();

  // To store the session Id getting from URL
  const [SessionId, setSessionId] = useState();

  // Getting session Id from URL
  var { sessionId } = useParams();

  const [Pressure_Units, setPressure_Units] = useState(null);

  // State and Event for the comment modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  const [Alert, setAlert] = useState(false);

  const [LoadGridData, setLoadGridData] = useState([]);

  const [rowCount, setRowCount] = useState(10);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  // Boolean variable to switch between the save and update button
  const [showSave, setShowSave] = useState(true);

  // As the user enter's the number of row's it get's set in this variable.
  const [row, setRow] = useState(5);

  // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Viscosity Grid.
  const [LoadRowAddModal, setLoadRowAddModal] = useState();

  const ToggleAddRowModal = () => {
    setLoadRowAddModal(!LoadRowAddModal);
  };

  const PopulateLoadSheet = (CalcData) => {
    for (let i = 0; i < CalcData.length; i++) {
      LoadSpreadsheet.current.updateCell(
        { value: CalcData[i]["Injection Speed"] },
        `A${i + 1}`
      );
      LoadSpreadsheet.current.updateCell(
        { value: CalcData[i]["Fill Time - In Mold (sec)"] },
        `B${i + 1}`
      );
      LoadSpreadsheet.current.updateCell(
        { value: CalcData[i]["Peak Press at Transfer - In Mold"] },
        `C${i + 1}`
      );
      LoadSpreadsheet.current.updateCell(
        { value: CalcData[i]["Fill Time - Air Shot (sec)"] },
        `D${i + 1}`
      );
      LoadSpreadsheet.current.updateCell(
        { value: CalcData[i]["Peak Press at Transfer - Air Shot"] },
        `E${i + 1}`
      );

      LoadSpreadsheet.current.updateCell(
        {
          value: `${CalcData[i]["Load Sensitivity"]} %${Pressure_Units === null ||
              Pressure_Units === "psi" ||
              Pressure_Units === "ppsi (Plastic Pressure)"
              ? "Kpsi"
              : Pressure_Units
            }`,
        },
        `F${i + 1}`
      );
    }
  };

  // This is the event to do the above said thing.
  const addRow = (e) => {
    e.preventDefault();

    // Storing the number entered
    setRow(e.target.value);
    // console.log(e.target.value);
  };

  // This is the event which gets called as the user click's ok in the add row modal and increases the row count.
  const increaseRow = () => {
    // Updating the total rows variable
    setRowCount(parseInt(rowCount) + parseInt(row));

    setAlert(true);

    setRow(null);
  };

  // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
  const deleteRow2 = (id) => {
    if (rowCount > 1) {
      LoadSpreadsheet.current.delete(rowToBeDeleted, rowToBeDeleted, "Row", 0);

      setRowCount(parseInt(rowCount) - 1);

      LoadGridData.splice(rowToBeDeleted, 1);

      // console.log(LoadGridData)

      setAlert(true);
    }
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      setAlert(true);
    }
  };

  const handlePressureUnits = (e) => {
    e.preventDefault();

    if (e.target.value) {
      setPressure_Units(e.target.value);
    } else {
      setPressure_Units("%Kpsi");
    }
  };

  const [InjSpeedArray, setInjSpeedArray] = useState([]);

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [InjectionniceNumbers, setInjectionNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [LoadNiceNumbers, setLoadNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  // Event to set the Min, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    if (LoadGridData.length > 0) {
      // To store the sorted Array
      let InjectionArray = [],
        LoadArray = [];

      const CompareInjectionSpeed = (a, b) => {
        return a["Injection Speed"] - b["Injection Speed"];
      };

      const CompareLoad = (a, b) => {
        return (
          parseFloat(a["Load Sensitivity"]) - parseFloat(b["Load Sensitivity"])
        );
      };

      for (let i = 0; i < LoadGridData.length; i++) {
        if (LoadGridData[i]["Injection Speed"]) {
          InjectionArray.push(LoadGridData[i]);
          LoadArray.push(LoadGridData[i]);
        } else {
        }
      }

      InjectionArray.sort(CompareInjectionSpeed);
      LoadArray.sort(CompareLoad);

      setInjSpeedArray(InjectionArray);

      if (InjectionArray[0]["Injection Speed"]) {
        let Xmargin =
          ((InjectionArray[InjectionArray.length - 1]["Injection Speed"] -
            InjectionArray[0]["Injection Speed"]) /
            100) *
          5;

        setInjectionNiceNumbers(
          calculateNiceNumbers(
            InjectionArray[0]["Injection Speed"] - Xmargin,
            InjectionArray[InjectionArray.length - 1]["Injection Speed"] +
            Xmargin,
            5
          )
        );
      }

      if (LoadArray[0]["Load Sensitivity"]) {
        let YMargin =
          ((parseFloat(LoadArray[LoadArray.length - 1]["Load Sensitivity"]) -
            parseFloat(LoadArray[0]["Load Sensitivity"])) /
            100) *
          5;

        setLoadNiceNumbers(
          calculateNiceNumbers(
            parseFloat(LoadArray[0]["Load Sensitivity"]) - YMargin,
            parseFloat(LoadArray[LoadArray.length - 1]["Load Sensitivity"]),
            5
          )
        );
      }

      // console.log(InjectionArray)
    } else {
    }
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) scaleGraph();

    // console.log(LoadGridData)

    // eslint-disable-next-line
  }, [LoadGridData]);

  // Event to Call the POST request API and save the data
  const saveData = () => {
    const data = {
      session: SessionId,
      LoadGridData: LoadGridData,
      Comment: Comment ? Comment : "N/A",
    };

    // console.log(data)

    DataService.SaveLoadData(data)
      .then((res) => {
        // console.log(res)

        setAlert(false);

        setShowSave(false);

        toast("Data has been saved", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => { });
  };

  // Event to Call the PUT request API and update the data
  const UpdateData = () => {
    const data = {
      session: SessionId,
      LoadGridData: LoadGridData,
      Comment: Comment ? Comment : "N/A",
    };

    // console.log(data)

    DataService.UpdateLoadData(SessionId, data)
      .then((res) => {
        // console.log(res)

        setAlert(false);

        setShowSave(false);

        toast("Data has been saved", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => { });
  };

  const GetData = (SessionId) => {
    DataService.GetLoadData(SessionId)
      .then((res) => {
        if (res.data) {
          // console.log(res.data)
          setLoadGridData(res.data.LoadGridData);
          PopulateLoadSheet(res.data.LoadGridData);
          setComment(res.data.Comment);
          setShowSave(false);
        } else {
          setLoadGridData([]);
        }
      })
      .catch((err) => { });
  };

  // Event that will be called as soon as the Viscosity Curve Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    if (SessionId) {
      GetData(SessionId);
    } else {
    }

    // console.log(SessionId)

    // eslint-disable-next-line
  }, [sessionId, SessionId]);

  return (
    <div className="card equipmentDash p-3 ml-2 mt-4">
      <section className="b-primary b-r-4 mb-2">

        <div className="m-1">


          <div className="pt-2 pb-2 pr-2 pl-1">

            <div className="d-flex col-md-12 mb-2">
              <div>
                <button
                  className="btn btn-info btn-air-info mr-2"
                  type="button"
                  onClick={ToggleAddRowModal}
                >
                  {" "}
                  Add Row{" "}
                </button>
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  type="button"
                  onClick={deleteRow2}
                >
                  {" "}
                  Delete Row{" "}
                </button>
                <AddRow
                  ToggleAddRowModal={ToggleAddRowModal}
                  LoadRowAddModal={LoadRowAddModal}
                  addRow={addRow}
                  increaseRow={increaseRow}
                />
              </div>

              <div className="m-0 d-flex">
                <div>
                  <label htmlFor="Temp_Units" className="lbl_design mt-1">
                    {" "}
                    Pressure Units:{" "}
                  </label>
                </div>
                <div>
                  <select
                    className="ml-2 form-control mr-2"
                    style={{ width: "150px" }}
                    onChange={handlePressureUnits}
                    name="Back_Press_Units"
                  >
                    <option> psi </option>
                    <option> Bar </option>
                    <option> Mpa </option>
                  </select>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  type="button"
                  onClick={handleShow}
                >
                  Comment
                </button>

                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  onClick={scaleGraph}
                >
                  {" "}
                  Show Graph{" "}
                </button>

                {/* {showSave ? (
                  <button
                    className="btn btn-secondary btn-air-secondary mr-2"
                    type="button"
                    onClick={saveData}
                  >
                    {" "}
                    Save{" "}
                  </button>
                ) : (
                  <button
                    className="btn btn-fifth btn-air-fifth mr-2"
                    type="button"
                    onClick={UpdateData}
                  >
                    {" "}
                    Update{" "}
                  </button>
                )} */}

                <Modal isOpen={show} centered>
                  <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                  <ModalBody>
                    <RichTextEditorComponent
                      change={getComment}
                      value={Comment}
                      saveInterval="1"
                      toolbarSettings={toolbarSettings}
                      height={250}
                    >
                      <Inject services={[Toolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="dark" onClick={handleClose}>
                      {" "}
                      Save & Close{" "}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>

          </div>


          <div>
            <div className="m-2 col-md-12">
              <div onClick={() => setToPlotChart(false)}>
                <LoadGrid
                  LoadSpreadsheet={LoadSpreadsheet}
                  LoadGridData={LoadGridData}
                  setLoadGridData={setLoadGridData}
                  rowCount={rowCount}
                  setRowToBeDeleted={setRowToBeDeleted}
                  PopulateLoadSheet={PopulateLoadSheet}
                  Pressure_Units={Pressure_Units}
                  Alert={Alert}
                />
              </div>

              <div className="mt-1">
                <ChartComponent
                id="charts"
                  className="equipmentChartGrid mt-3"
                  // width="680"
                  width="100%"
                  border={{ width: 1, color: "darkblue" }}
                  tooltip={{ enable: true }}
                  height="250"
                  primaryXAxis={{
                    title: `Injection Speed`,
                    minimum: InjectionniceNumbers.min,
                    maximum: InjectionniceNumbers.max,
                    interval: InjectionniceNumbers.step,
                  }}
                  primaryYAxis={{
                    title: `Load Sensitivity`,
                    minimum: LoadNiceNumbers.min,
                    maximum: LoadNiceNumbers.max,
                    interval: LoadNiceNumbers.step,
                  }}
                >
                  <Inject
                    services={[
                      LineSeries,
                      Category,
                      DataLabel,
                      StripLine,
                      Tooltip,
                    ]}
                  />

                  <SeriesCollectionDirective>
                    <SeriesDirective
                      dataSource={InjSpeedArray}
                      type="Line"
                      xName="Injection Speed"
                      yName="Load Sensitivity"
                      marker={{ visible: true }}
                      fill="orange"
                      width={2.5}
                    ></SeriesDirective>
                  </SeriesCollectionDirective>
                </ChartComponent>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
};

export default LoadSensitivity;
