import React from "react";
import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Tooltip,
  Crosshair,
  ScatterSeries,
} from "@syncfusion/ej2-react-charts";

import "../Chart.css";

function ContourPlot({ data, n, x_axis, y_axis, axis_elements, limit, num_subarrays }) {
  const parsedData = JSON.parse(data);

  // console.log(parsedData)

  const groups = []; // Initialize an array to hold the groups


function splitArray(arr, numArrays) {
  // Initialize empty arrays for each subarray
  const subarrays = Array.from({ length: numArrays }, () => []);

  // Distribute elements into subarrays based on index
  arr.forEach((item, index) => {
      const subarrayIndex = index % numArrays;
      subarrays[subarrayIndex].push(item);
  });

  return subarrays;
}


const result = splitArray(parsedData, num_subarrays);

result.forEach((subarray, index) => {
  // console.log(`Array ${index + 1}:`, subarray);
  groups.push(subarray)
});

// console.log("API response",groups)

  let transformedGroups = groups.map((group) => {
    return group.map((item) => ({
      x: item[axis_elements[0]],
      y: item.Calculated_regression,
    }));
  });

  const bottomLimit = parseFloat(y_axis[0]);
  const upperLimit = parseFloat(y_axis[1]);
  // console.log("filter range",bottomLimit,upperLimit)

const filtered_group = transformedGroups.map((sublist) => {
  let outsidePoints_smaller = sublist.filter(entry => entry.y < bottomLimit );
  let outsidePoints_greater = sublist.filter(entry => entry.y > upperLimit);
    // console.log(outsidePoints)
    outsidePoints_smaller.sort((a, b) => a.y - b.y);
    outsidePoints_greater.sort((a, b) => a.y - b.y);
  // let plotPoints = [];
  let newSublist = [];

  if (outsidePoints_smaller.length > 0) {
    // Find the closest point below the bottom limit
    outsidePoints_smaller.reverse();
    newSublist.push(outsidePoints_smaller[0])
  }
 
  sublist.forEach((entry, index) => {
    if (entry.y >= bottomLimit && entry.y <= upperLimit) {
      // console.log(entry.y)
      // Add the preceding point if it exists and hasn't been added already
      if (index > 0 && !newSublist.includes(sublist[index - 1])) {
        newSublist.push(sublist[index - 1]);
      }
      newSublist.push(sublist[index]);
      // Add the succeeding point if it exists and hasn't been added already
      if (index < sublist.length - 1 && !newSublist.includes(sublist[index + 1])) {
        newSublist.push(sublist[index + 1]);
      }
    }
  }
  );
  if (outsidePoints_greater.length>0){
    const closestGreaterPoint = outsidePoints_greater[0];
    const isPresent = sublist.some(entry => entry === closestGreaterPoint);

    // If it's not present, add it to newSublist
    if (!isPresent) {
        newSublist.push(closestGreaterPoint);
    }
   
    // newSublist.push(outsidePoints_greater[0])
  }
  return newSublist;
});

// console.log("calculated values",transformedGroups)
// console.log("filtered_group", filtered_group);


  // Removing underscores and storing in a new array
  let newArray = axis_elements.map((s) => s.replace(/_/g, " "));

  const primaryxAxis = {
    crosshairTooltip: { enable: true, fill: "black" },
    minimum: parseFloat(x_axis[0]),
    title: newArray[0],
    maximum: parseFloat(x_axis[1]),
    interval: (parseFloat(x_axis[1]) - parseFloat(x_axis[0])) / 10,
    majorGridLines: {
      width: 0,
    },
    lineStyle: { color: "black" },
  };

  const primaryyAxis = {
    crosshairTooltip: { enable: true, fill: "black" },
    minimum: parseFloat(y_axis[0]),
    maximum: parseFloat(y_axis[1]),
    title: newArray[1],
    interval: (parseFloat(y_axis[1]) - parseFloat(y_axis[0])) / 10,
    rangePadding: "None",
    majorGridLines: {
      width: 0,
    },
    lineStyle: { color: "black" },
  };
// console.log((parseFloat(y_axis[1]) - parseFloat(y_axis[0])) / 10)
  const crosshair = { enable: true, line: { width: 2, color: "black" } };

  const marker = { visible: false };

  return (
    // Passing data to chartcomponent
    <div>
      <ChartComponent
        tooltip={{ enable: false }}
        primaryYAxis={primaryyAxis}
        primaryXAxis={primaryxAxis}
        width={"1000px"}
        height={"400px"}
        border={{ width: 1, color: "darkblue" }}
        title="Dynamic Process Window"
        crosshair={crosshair}
      >
        <Inject services={[Tooltip, Crosshair,LineSeries, ScatterSeries]} />

        <SeriesCollectionDirective>
          {/* {filtered_group.map((seriesData, index) => (
            <SeriesDirective
              key={index}
              dataSource={seriesData}
              animation={{ enable: false }}
              dataLabel={false}
              xName="x"
              yName="y"
              type="Scatter"
              marker={marker}
              fill="blue"
              width={0}
              // name={`Series_Scatter ${index + 1}`}
            ></SeriesDirective>
          ))} */}

         

          {filtered_group.map((seriesData, index) => (
            <SeriesDirective
              key={index}
              dataSource={seriesData}
              animation={{ enable: false }}
              dataLabel={false}
              xName="x"
              yName="y"
              type="Line"
              marker={marker}
              fill="blue"
              width={1.5}
              // name={`Series_Line ${index + 1}`}
            ></SeriesDirective>
          ))}
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
}

export default ContourPlot;
