import React, { useEffect } from 'react';
import '../App.css';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, ColumnsDirective, ColumnDirective, RowsDirective, RowDirective, CellsDirective, CellDirective } from '@syncfusion/ej2-react-spreadsheet';

const CavityGrid2 = ({ column, CavityGridData, Total, Average, MaxPart, MinPart, Percentage, Range, CavityCalcSpreadsheet, colCount }) => {

    const protectSettings = { selectCells: true };

    const scrollSettings = { isFinite: true };

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
           args.cancel = true
        }

    }

    useEffect(() => {

        // console.log('Average', Average, 'Max', MaxPart, 'Min', MinPart, 'Range', Range)

        // console.log(Average, Percentage)

    }, [Average, MaxPart, MinPart, Percentage])

    return (
        <>

            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div id="Cavity-Calc-Grid">

                                    <SpreadsheetComponent id="cavityGrid2_Spreadsheet" ref={CavityCalcSpreadsheet} width={"99%"} height={210} showFormulaBar={false} showSheetTabs={false} showRibbon={false} dialogBeforeOpen={dialogBeforeOpen.bind(this)} scrollSettings={scrollSettings} allowAutoFill={false} enableContextMenu={false} allowImage={false}>

                                        <SheetsDirective>

                                            <SheetDirective frozenRows={1} showHeaders={false} rowCount={CavityGridData.length + 7} colCount={colCount} isProtected={true} protectSettings={protectSettings}>

                                                <RowsDirective>

                                                    <RowDirective>

                                                        <CellsDirective>

                                                            {column.map((Coldata, ColKey) => {
                                                                return (

                                                                    <CellDirective key={ColKey} value={Coldata.header}></CellDirective>

                                                                )
                                                            })}

                                                        </CellsDirective>

                                                    </RowDirective>

                                                    <RowDirective>

                                                        <CellsDirective>
                                                            {column.map((value1, key1) => (

                                                                <CellDirective key={key1} value={key1 === 0 ? "Total Fill" : Total[key1 - 1] || ''}></CellDirective>

                                                            ))}
                                                        </CellsDirective>

                                                    </RowDirective>

                                                    <RowDirective>

                                                        <CellsDirective>

                                                            {column.map((value1, key1) => (

                                                                <CellDirective key={key1} value={key1 === 0 ? "Average Fill" : Average[key1 - 1] || ''}></CellDirective>

                                                            ))}

                                                        </CellsDirective>

                                                    </RowDirective>

                                                    <RowDirective>

                                                        <CellsDirective>

                                                            {column.map((value1, key1) => (

                                                                <CellDirective key={key1} value={key1 === 0 ? "Range" : isFinite(Range[key1 - 1]) ? Range[key1 - 1] : '' || ''}></CellDirective>

                                                            ))}

                                                        </CellsDirective>

                                                    </RowDirective>

                                                    <RowDirective>

                                                        <CellsDirective>

                                                            {column.map((value1, key1) => (

                                                                <CellDirective key={key1} value={key1 === 0 ? "Max Part Wt." : isFinite(MaxPart[key1 - 1]) ? MaxPart[key1 - 1] : '' || ''}></CellDirective>

                                                            ))}

                                                        </CellsDirective>

                                                    </RowDirective>

                                                    <RowDirective>

                                                        <CellsDirective>

                                                            {column.map((value1, key1) => (

                                                                <CellDirective key={key1} value={key1 === 0 ? "Min Part Wt." : isFinite(MinPart[key1 - 1]) ? MinPart[key1 - 1] : '' || ''}></CellDirective>

                                                            ))}

                                                        </CellsDirective>

                                                    </RowDirective>

                                                    <RowDirective>

                                                        <CellsDirective>
                                                            {column.map((value1, key1) => (

                                                                <CellDirective key={key1} value={key1 === 0 ? "% Variation from Average" : '--'}></CellDirective>
                                                            ))}

                                                        </CellsDirective>

                                                    </RowDirective>

                                                    {CavityGridData.map((RowData, RowKey) => {
                                                        return (

                                                            <RowDirective key={RowKey}>

                                                                <CellsDirective>

                                                                    {column.map((Coldata, ColKey) => {
                                                                        return (

                                                                            <CellDirective key={RowKey} value={ColKey === 0 ? RowData["Cavity_No"] : Percentage[ColKey - 1] === undefined ? ('-') : (Percentage[ColKey - 1][RowKey]) === 0 ? 0 : (Percentage[ColKey - 1][RowKey]) || ''}></CellDirective>

                                                                        )
                                                                    })}

                                                                </CellsDirective>

                                                            </RowDirective>

                                                        )
                                                    })}

                                                </RowsDirective>

                                                <ColumnsDirective>

                                                    {column.map((index, Colkey) => (

                                                        <ColumnDirective width={Colkey === 0 ? 110 : 80} key={Colkey} />

                                                    ))}

                                                </ColumnsDirective>

                                            </SheetDirective>

                                        </SheetsDirective>

                                    </SpreadsheetComponent>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default CavityGrid2;