import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import App from "./components/app";
import { Provider } from "react-redux";
import store from "./store";

import { connect } from "react-redux";

// Import custom Components
import Dashboard from "./components/dashboard/Dashboard";

// pages
import SixStepStudy from "./components/sixstepstudy/SixStepStudy";
import LogIn from "./pages/login";
import Signup from "./pages/signup";
import ResetPwd from "./pages/resetPwd";
import Activate from "./pages/Activate";
import ResetPwdConfirm from "./pages/ResetPwdConfirm";
import SessionGrid from "./components/dashboard/SessionGrid";
import { registerLicense } from "@syncfusion/ej2-base";

import CalculatorGrid from "./components/dashboard/CalculatorGrid";
import ConversionGrid from "./components/dashboard/ConversionGrid";

import MeltSessions from "./components/Meltpreparation/MeltSessions";
import MeltDashboard from "./components/Meltpreparation/MeltDashboard";
import EquipmentSessions from "./components/EquipmentQualification/EquipmentSessions";
import EquipmentDashboard from "./components/EquipmentQualification/EquipmentDashboard";
import QualitySessions from "./components/QualityData/QualitySessions";
import QualityDashboard from "./components/QualityData/QualityDashboard";

import PPS from "./components/PlasticProcessSheet/PPS";

import CreateSession from "./components/doe/NewSession/CreateSession";
import DefineVariables from "./components/doe/NewSession/DefineVariables";
import DefineResponse from "./components/doe/NewSession/DefineResponse";
import AnalysisTab from "./components/doe/AnalysisTab/AnalysisTab";
import DoeSessions from "./components/doe/DoeSessions";
import DoenewSession from "./components/doe/DoeNewSession"

import Database from "./components/database/Database";
import ManagementUnit from "./components/optionnsettings/Options/ManagementUnit";

// import MeltPreparation from "./components/MeltPreparationNew/MeltPreparation";

import MaterialNew from "./components/database/Options/MaterialDatabase/MaterialNew";
import MaterialEdit from "./components/database/Options/MaterialDatabase/MaterialEdit";
import MaterialView from "./components/database/Options/MaterialDatabase/MaterialView";
import MaterialDuplicate from "./components/database/Options/MaterialDatabase/MaterialDuplicate";

import MoldDatabase from "./components/database/Options/MoldDatabase/MoldDB";
import MoldNew from "./components/database/Options/MoldDatabase/MoldNew";
import MoldEdit from "./components/database/Options/MoldDatabase/MoldEdit";
import MoldDuplicate from "./components/database/Options/MoldDatabase/MoldDuplicate";
import MoldView from "./components/database/Options/MoldDatabase/MoldView";

import MachineNew from "./components/database/Options/MachineDatabase/MachineNew";
import MachineEdit from "./components/database/Options/MachineDatabase/MachineEdit";
import MachineView from "./components/database/Options/MachineDatabase/MachineView";
import MachineDuplicate from "./components/database/Options/MachineDatabase/MachineDuplicate";

import Compatible from "./components/compatibility/Compatible";

import OptionSetting from "./components/optionnsettings/OptionSetting";
import RequireAuth from "./RequireAuth";
import PPSSessions from "./components/PlasticProcessSheet/PPSSessions";
// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Qdk1jXX5Zc3xUTmRf"
);

const Root = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route path="/login" component={LogIn} />

            <Route path="/signup" component={Signup} />

            <Route path="/reset-password" component={ResetPwd} />

            <Route path="/activate/:uid/:token" component={Activate} />

            <Route
              exact
              path="/email/reset/confirm/:uid/:token"
              component={ResetPwdConfirm}
            />

            <Route
              exact
              path="/"
              render={(props) => (
                <RequireAuth>
                  <LogIn {...props} />
                </RequireAuth>
              )}
            />

            <App>
              <Route
                path="/dashboard/mold"
                render={(props) => (
                  <RequireAuth>
                    <Dashboard {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:MoldName/:Mold_Id/session"
                render={(props) => (
                  <RequireAuth>
                    <SessionGrid {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/session"
                render={(props) => (
                  <RequireAuth>
                    <SessionGrid {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                exact
                path="/sixstepstudy/:moldId/:sessionId/:moldName/:sessionName/sixstepstudy"
                render={(props) => (
                  <RequireAuth>
                    <SixStepStudy {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/calculators"
                render={(props) => (
                  <RequireAuth>
                    <CalculatorGrid {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/conversions"
                render={(props) => (
                  <RequireAuth>
                    <ConversionGrid {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/meltpreparation/sessions"
                render={(props) => (
                  <RequireAuth>
                    <MeltSessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:moldId/:moldName/meltpreparation/sessions"
                render={(props) => (
                  <RequireAuth>
                    <MeltSessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/:sessionName/:moldId/:moldName/meltpreparation"
                render={(props) => (
                  <RequireAuth>
                    <MeltDashboard {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/equipmentqualification/sessions"
                render={(props) => (
                  <RequireAuth>
                    <EquipmentSessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:moldId/:moldName/equipmentqualification/sessions"
                render={(props) => (
                  <RequireAuth>
                    <EquipmentSessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/:sessionName/:moldId/:moldName/equipmentqualification"
                render={(props) => (
                  <RequireAuth>
                    <EquipmentDashboard {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/qualitydata/sessions"
                render={(props) => (
                  <RequireAuth>
                    <QualitySessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:moldId/:moldName/qualitydata/sessions"
                render={(props) => (
                  <RequireAuth>
                    <QualitySessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/:sessionName/:moldId/:moldName/qualitydata"
                render={(props) => (
                  <RequireAuth>
                    <QualityDashboard {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/DOE/doe-session"
                render={(props) => (
                  <RequireAuth>
                    <DoeSessions {...props} />
                  </RequireAuth>
                )}
              />
              
              <Route
                path="/dashboard/DOE/doe-new-session"
                render= {(props) =>(
                  <RequireAuth>
                    <DoenewSession  {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/:sessionName/doe"
                render={(props) => (
                  <RequireAuth>
                    <CreateSession {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/DOE/define-variables"
                render={(props) => (
                  <RequireAuth>
                    <DefineVariables {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/DOE/define-response"
                render={(props) => (
                  <RequireAuth>
                    <DefineResponse {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/DOE/summary"
                render={(props) => (
                  <RequireAuth>
                    <AnalysisTab {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Database"
                render={(props) => (
                  <RequireAuth>
                    <Database {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/compatibility/Compatible"
                render={(props) => (
                  <RequireAuth>
                    <Compatible {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/optionnsettings/OptionSetting"
                render={(props) => (
                  <RequireAuth>
                    <OptionSetting {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/optionnsettings/ManagementUnit"
                render={(props) => (
                  <RequireAuth>
                    <ManagementUnit {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/MaterialNew"
                render={(props) => (
                  <RequireAuth>
                    <MaterialNew {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MaterialEdit"
                render={(props) => (
                  <RequireAuth>
                    <MaterialEdit {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MaterialView"
                render={(props) => (
                  <RequireAuth>
                    <MaterialView {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MaterialDuplicate"
                render={(props) => (
                  <RequireAuth>
                    <MaterialDuplicate {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="database/Options/MoldDB"
                render={(props) => (
                  <RequireAuth>
                    <MoldDatabase {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/MoldNew"
                render={(props) => (
                  <RequireAuth>
                    <MoldNew {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MoldEdit"
                render={(props) => (
                  <RequireAuth>
                    <MoldEdit {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MoldView"
                render={(props) => (
                  <RequireAuth>
                    <MoldView {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MoldDuplicate"
                render={(props) => (
                  <RequireAuth>
                    <MoldDuplicate {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/MachineNew"
                render={(props) => (
                  <RequireAuth>
                    <MachineNew {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MachineEdit"
                render={(props) => (
                  <RequireAuth>
                    <MachineEdit {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MachineView"
                render={(props) => (
                  <RequireAuth>
                    <MachineView {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/database/Options/:RowId/MachineDuplicate"
                render={(props) => (
                  <RequireAuth>
                    <MachineDuplicate {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/PPS/sessions"
                render={(props) => (
                  <RequireAuth>
                    <PPSSessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:moldId/:moldName/PPS/sessions"
                render={(props) => (
                  <RequireAuth>
                    <PPSSessions {...props} />
                  </RequireAuth>
                )}
              />

              <Route
                path="/dashboard/:sessionId/:sessionName/:moldId/:moldName/PPS"
                render={(props) => (
                  <RequireAuth>
                    <PPS {...props} />
                  </RequireAuth>
                )}
              />
            </App>
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default Root;

ReactDOM.render(<Root />, document.getElementById("root"));
