// Distance
export function cm_To_mm(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 10;
  // console.log( value )
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function mm_To_cm(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 10;
  // console.log( value )
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function mm_To_in(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 25.4;
  // console.log( value )
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function in_To_mm(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 25.4;
  // console.log( value )
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function cm_To_in(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 2.54;
  // console.log( value )
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function in_To_cm(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 2.54;
  // console.log( value )
  return parseFloat(CalcValue).toFixed(Decimals);
}
