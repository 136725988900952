import React from "react";
import "../App.css";
import "./style.css";

import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Areas from './AreaConversion';
import Distance from './DistanceConversion';
import Temperature from './TemperatureConversion';
import Volume from './VolumeConversion';
import Weight from './WeightConversion';
import Pressure from './PressureConversion';
import Tonnage from './TonnageConversion';

const Conversions = () => {
  // const history = useHistory();
  // Declare the names of the tab's
  let headertext = [
    { text: "Area" },
    { text: "Distance" },
    { text: "Temperature" },
    { text: "Volume" },
    { text: "Weight" },
    { text: "Pressure" },
    { text: "Tonnage" },

  ];

  function content0() {
    return (
      <div className="card p-3 ml-2">
        <Areas />
      </div>
    );
  }

  function content1() {
    return (
      <div className="card p-3 ml-2">
        <Distance />
      </div>
    );
  }

  function content2() {
    return (
      <div className="card p-3 ml-2">
        <Temperature />
      </div>
    );
  }

  function content3() {
    return (
      <div className="card p-3 ml-2">
        <Volume />
      </div>
    );
  }

  function content4() {
    return (
      <div className="card p-3 ml-2">
        <Weight />
      </div>
    );
  }

  function content5() {
    return (
      <div className="card p-3 ml-2">
        <Pressure />
      </div>
    );
  }

  function content6() {
    return (
      <div className="card p-3 ml-2">
        <Tonnage />
      </div>
    );
  }


  return (
    
    <>

<div className="d-flex">
          <div className="col-md-12">
               

      {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
      <TabComponent heightAdjustMode="Auto" id="defaultTab" style={{padding:"0 16",}}>
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                  <TabItemDirective header={headertext[2]} content={content2} />
                  <TabItemDirective header={headertext[3]} content={content3} />
                  <TabItemDirective header={headertext[4]} content={content4} />
                  <TabItemDirective header={headertext[5]} content={content5} />
                  <TabItemDirective header={headertext[6]} content={content6} />
                </TabItemsDirective>
              </TabComponent>
      </div>
      </div>
      
      {/* <Tabs defaultActiveKey="Area" className="mb-3" fill>
        <Tab eventKey="Area" title="Area">
          <Container>
            <Row>
              <Col>
                <Areas />
              </Col>
              
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="Distance" title="Distance">
          <Container>
            <Row>
              <Col>
                <Distance />
              </Col>
              
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="Temperature" title="Temperature">
          <Container>
            <Row>
              <Col>
                <Temperature />
              </Col>
              
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="Volume" title="Volume">
          <Container>
            <Row>
              <Col>
                <Volume />
              </Col>
              
            </Row>
          </Container>
        </Tab>

        <Tab eventKey="Weight" title="Weight">
          <Container>
            <Row>
              <Col>
                <Weight />
              </Col>
              
            </Row>
          </Container>
        </Tab>

        <Tab eventKey="Pressure" title="Pressure">
          <Container>
            <Row>
              <Col>
                <Pressure />
              </Col>
              
            </Row>
          </Container>
        </Tab>

        <Tab eventKey="Tonnage" title="Tonnage">
          <Container>
            <Row>
              <Col>
                <Tonnage />
              </Col>
              
            </Row>
          </Container>
        </Tab>
      </Tabs> */}
    </>
  );
};

export default Conversions;
