import React, { useState } from 'react'
// import { TabComponent, TabItemDirective, TabItemsDirective } from "@syncfusion/ej2-react-navigations";
import Header2 from '../common/header-component/header2';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ProcessCapability from './ProcessCapability/ProcessCapability';

const QualityDashboard = () => {

    const history = useHistory();

    // const showAlert = useRef(false);

    const [MoldId, setMoldId] = useState();
    const [MoldName, setMoldName] = useState('mold');
    const [Session_Name, setSession_Name] = useState('');

    // function confirmAction(e) {

    //     let confirmAction = window.confirm('If you click on OK the data will not be saved and If you click on Cancel then you will stay on the same page. Save the data to move to the next step');

    //     if (confirmAction) {
    //         showAlert.current = false;
    //     } else {
    //         e.cancel = true;
    //     }

    // }

    // const tabSelecting = (e) => {

    //     if (showAlert.current) {
    //         confirmAction(e)
    //     }

    // }

    // Event to get back to Session's
    const goBack = () => {

        history.push(`/dashboard/${MoldId}/${MoldName}/qualitydata/sessions`)

    }

    return (
        <>

            <Header2 Title="Quality Data" />

            <div className="mt-2 container-fluid">

                <div className="d-flex justify-content-between ml-3 pt-3 pb-3">

                    <div className="d-flex">
                        <div>
                            <span className="BreadCrum" onClick={goBack} style={{ fontSize: '14px', color: 'blue' }}> Sessions </span>
                        </div>
                        <div>
                            <span className="BreadCrum" style={{ fontSize: '16px' }}> {">"} </span>
                        </div>
                        <div>
                            <span className="BreadCrum" style={{ fontSize: '14px' }}> Quality Data </span>
                        </div>
                    </div>

                    {/* <div>
                        <div className="ml-4">
                            <span className="Mold-Session"> Session Name : {Session_Name} </span>
                        </div>
                    </div> */}

                </div>

                <div className="study-container">

                    {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}

                    <div>
                        <ProcessCapability setSession_Name={setSession_Name} setMoldId={setMoldId} setMoldName={setMoldName} />
                    </div>

                </div>

                <ToastContainer />
            </div>
        </>
    )
}

export default QualityDashboard