import React from "react";
import { useHistory } from "react-router-dom";

const BreadCrumb = ({ Current_Page }) => {

  const history = useHistory();

  return (
    <div className="d-flex">
      <div>
        <span
          className="BreadCrum"
          style={{ fontSize: "14px", color: "blue" }}
          onClick={() => {
            history.push({
              pathname: "/database/Database",
              TabIdx: 0,
            });
          }}
        >
          {" "}
          Database{" "}
        </span>
      </div>
      <div>
        <span className="BreadCrum" style={{ fontSize: "16px" }}>
          {" "}
          {">"}{" "}
        </span>
      </div>
      <div>
        <span
          className="BreadCrum"
          style={{ fontSize: "14px", color: "blue" }}
          onClick={() => {
            history.push({
              pathname: "/database/Database",
              TabIdx: 0,
            });
          }}
        >
          {" "}
          Material Database{" "}
        </span>
      </div>
      <div>
        <span className="BreadCrum" style={{ fontSize: "16px" }}>
          {" "}
          {">"}{" "}
        </span>
      </div>
      <div>
        <span className="BreadCrum" style={{ fontSize: "14px" }}>
          {" "}
          {Current_Page} Material{" "}
        </span>
      </div>
    </div>
  );
};

export default BreadCrumb;
