import React from "react";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  // ColumnsDirective,
  // ColumnDirective,
} from "@syncfusion/ej2-react-spreadsheet";
import { getRangeIndexes } from "@syncfusion/ej2-spreadsheet";
// import { getCellIndexes, getCellAddress } from '@syncfusion/ej2-spreadsheet';

const ViscocityGrid = ({
  ViscosityGridData,
  setViscosityGridData,
  rowCount,
  ViscositySpreadsheet,
  setRowToBeDeleted,
  IntensificationRatio,
  PopulateViscositySheet,
  showAlert,
}) => {
  const protectSettings = { selectCells: true };

  const scrollSettings = {
    isFinite: true,
    enableVirtualization: false,
  };

  let isPaste = false;

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  const GetValues = () => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      // JavaScript
      const tdElements = document.querySelectorAll("#Viscosity_Sheet .e-cell");

      tdElements.forEach((td) => {
        const cellValue = td.textContent;

        if (cellValue) {
          TempArray.push(cellValue);
        } else {
          TempArray.push(null);
        }
      });

      resolve(TempArray);

      setViscosityGridData([]);

      // console.log(TempArray)
    });
  };

  const ConvertToJson = (DataArray) => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      let NumberOfRow = 0;

      for (let i = 0; i < Math.ceil(DataArray.length / 7); i++) {
        TempArray.push({
          "Injection Speed": DataArray[0 + NumberOfRow],
          "FillTime (sec)": DataArray[1 + NumberOfRow],
          "Peak Inj Press": DataArray[2 + NumberOfRow],
          Viscosity: "",
          "Shear Rate": "",
          "Absolute Drop Viscosity": "",
          "% Drop Viscosity": "",
        });

        NumberOfRow = NumberOfRow + 7;
      }

      resolve(TempArray);

      // console.log(TempArray);
    });
  };

  const PerformCalcs = (TabelObjData) => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      for (let i = 0; i < TabelObjData.length; i++) {
        if (i === 0) {
          TempArray.push({
            "Injection Speed": TabelObjData[0]["Injection Speed"]
              ? TabelObjData[0]["Injection Speed"]
              : null,
            "FillTime (sec)": TabelObjData[0]["FillTime (sec)"]
              ? TabelObjData[0]["FillTime (sec)"]
              : null,
            "Peak Inj Press": TabelObjData[0]["Peak Inj Press"]
              ? TabelObjData[0]["Peak Inj Press"]
              : null,
            Viscosity: Math.round(
              TabelObjData[0]["FillTime (sec)"] *
                TabelObjData[0]["Peak Inj Press"] *
                IntensificationRatio
            ),
            "Shear Rate": TabelObjData[0]["FillTime (sec)"]
              ? Number(1 / TabelObjData[0]["FillTime (sec)"]).toFixed(3)
              : null,
            "Absolute Drop Viscosity": "--",
            "%Drop Viscosity": "--",
          });
        } else {
          TempArray.push({
            "Injection Speed": TabelObjData[i]["Injection Speed"]
              ? TabelObjData[i]["Injection Speed"]
              : null,
            "FillTime (sec)": TabelObjData[i]["FillTime (sec)"]
              ? TabelObjData[i]["FillTime (sec)"]
              : null,
            "Peak Inj Press": TabelObjData[i]["Peak Inj Press"]
              ? TabelObjData[i]["Peak Inj Press"]
              : null,
            Viscosity:
              Math.round(
                TabelObjData[i]["FillTime (sec)"] *
                  TabelObjData[i]["Peak Inj Press"] *
                  IntensificationRatio
              ) === 0
                ? 0
                : Math.round(
                    TabelObjData[i]["FillTime (sec)"] *
                      TabelObjData[i]["Peak Inj Press"] *
                      IntensificationRatio
                  ),

            "Shear Rate": TabelObjData[i]["FillTime (sec)"]
              ? Number(
                  parseFloat(1 / TabelObjData[i]["FillTime (sec)"])
                ).toFixed(3)
              : null,

            "Absolute Drop Viscosity":
              TabelObjData[i]["FillTime (sec)"] &&
              TabelObjData[i - 1]["FillTime (sec)"]
                ? Number(
                    Math.round(
                      TabelObjData[i - 1]["FillTime (sec)"] *
                        TabelObjData[i - 1]["Peak Inj Press"] *
                        IntensificationRatio
                    )
                  ) -
                  Number(
                    Math.round(
                      TabelObjData[i]["FillTime (sec)"] *
                        TabelObjData[i]["Peak Inj Press"] *
                        IntensificationRatio
                    )
                  )
                : "--",

            "%Drop Viscosity":
              TabelObjData[i]["FillTime (sec)"] &&
              TabelObjData[i - 1]["FillTime (sec)"]
                ? // Previous Drop minus Current Drop
                  isNaN(
                    (
                      ((Math.round(
                        TabelObjData[i - 1]["FillTime (sec)"] *
                          TabelObjData[i - 1]["Peak Inj Press"] *
                          IntensificationRatio
                      ) -
                        Math.round(
                          TabelObjData[i]["FillTime (sec)"] *
                            TabelObjData[i]["Peak Inj Press"] *
                            IntensificationRatio
                        )) *
                        // Multiplying by 100
                        100) /
                      // Finally Dividing by Previous Drop
                      Math.round(
                        TabelObjData[i - 1]["FillTime (sec)"] *
                          TabelObjData[i - 1]["Peak Inj Press"] *
                          IntensificationRatio
                      )
                    ).toFixed(1)
                  )
                  ? // Checking for NaN
                    "-"
                  : (
                      ((Math.round(
                        TabelObjData[i - 1]["FillTime (sec)"] *
                          TabelObjData[i - 1]["Peak Inj Press"] *
                          IntensificationRatio
                      ) -
                        Math.round(
                          TabelObjData[i]["FillTime (sec)"] *
                            TabelObjData[i]["Peak Inj Press"] *
                            IntensificationRatio
                        )) *
                        // Multiplying by 100
                        100) /
                      // Finally Dividing by Previous Drop
                      Math.round(
                        TabelObjData[i - 1]["FillTime (sec)"] *
                          TabelObjData[i - 1]["Peak Inj Press"] *
                          IntensificationRatio
                      )
                    ).toFixed(1)
                : "--",
          });
        }
      }

      resolve(TempArray);

      // console.log(TempArray)
    });
  };

  const getData = () => {
    // Getting the index of the selected row and storing in a variable
    let cell = ViscositySpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    setRowToBeDeleted(cellIdx[0]);

    GetValues().then((DataArray) => {
      ConvertToJson(DataArray).then((TabelObjData) => {
        PerformCalcs(TabelObjData).then((CalcData) => {
          PopulateViscositySheet(CalcData);

          setViscosityGridData(CalcData);

          // console.log(CalcData)
        });
      });
    });

    // console.log('called')
  };

  function onCreated() {
    //Applies data validation to specified range in the active sheet.
    ViscositySpreadsheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-10000.0000",
        value2: "10000.0000",
      },
      `A1:C${ViscositySpreadsheet.current.getActiveSheet().rowCount || 10}`
    );

    ViscositySpreadsheet.current.lockCells(
      `A1:C${ViscositySpreadsheet.current.getActiveSheet().rowCount || 10}`,
      false
    );

    PopulateViscositySheet(ViscosityGridData);
  }

  const UnlockCells = () => {
    ViscositySpreadsheet.current.getActiveSheet().rowCount =
      rowCount > 0 ? rowCount : 10;
  };

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }
  }

  const cellEditing = (args) => {
    if (args.value !== args.oldValue && !isNaN(args.value)) {
      ViscositySpreadsheet.current.updateCell(
        { value: args.value },
        args.address
      );

      showAlert.current = true;

      getData();

      // console.log(args)
    } else {
      showAlert.current = false;
    }
  };

  const beforeCellRenderHandler = (args) => {
    // Condition to check whether the rendered element is header cell.
    if (
      args.colIndex === 0 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Injection Speed";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 1 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Fill Time";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 2 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Peak Inj Press";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 3 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Viscosity";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 4 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Shear Rate";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 5 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "Absolute Drop Viscosity";
      args.element.innerText = text;
    }
    if (
      args.colIndex === 6 &&
      args.element.classList.contains("e-header-cell")
    ) {
      const text = "%Drop Viscosity";
      args.element.innerText = text;
    }
  };

  return (
    <div className="viscosity-grid" onMouseEnter={UnlockCells}>
      <div className="spreadsheet ml-2" id="Viscosity_Sheet">
        <SpreadsheetComponent
          onBlur={getData}
          cellEdited={getData}
          cellEditing={cellEditing}
          ref={ViscositySpreadsheet}
          height={400}
          width={"100%"}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          scrollSettings={scrollSettings}
          allowAutoFill={false}
          created={onCreated}
          beforeCellRender={beforeCellRenderHandler}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
          enableContextMenu={false}
          allowImage={false}
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={rowCount}
              colCount={7}
              isProtected={true}
              protectSettings={protectSettings}
            >
              {/* <ColumnsDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
                <ColumnDirective width={75}></ColumnDirective>
              </ColumnsDirective> */}
            </SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>
      </div>
    </div>
  );
};

export default ViscocityGrid;
