import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Area = ({
  AreaMetricDecimals,
  AreaEnglishDecimals,
  setAreaMetricDecimals,
  setAreaEnglishDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert,
}) => {
  // Function to store the metric units data
  const StoreAreaMetricUnits = () => {
    showAlert.current = true;

    setAreaEnglishDecimals(0.12);

    AreaUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Area[0].unit_id,
      unit_name: StoredUnits[0].Unit_Data[0].Area[0].unit_name,
      decimals: AreaMetricDecimals,
    };

    // console.log(AreaUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreAreaEnglishUnits = () => {
    showAlert.current = true;

    setAreaMetricDecimals(0.12);

    AreaUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Area[1].unit_id,
      unit_name: StoredUnits[0].Unit_Data[0].Area[1].unit_name,
      decimals: AreaEnglishDecimals,
    };

    // console.log(AreaUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  const StoreAreaMetricDecimals = (e) => {
    showAlert.current = true;

    setAreaEnglishDecimals(e.target.value);

    AreaUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Area[0].unit_id,
      unit_name: StoredUnits[0].Unit_Data[0].Area[0].unit_name,
      decimals: parseFloat(e.target.value),
    };

    // console.log(AreaUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreAreaEnglishDecimals = (e) => {
    showAlert.current = true;

    setAreaMetricDecimals(e.target.value);

    AreaUnits.current = {
      unit_id: StoredUnits[0].Unit_Data[0].Area[1].unit_id,
      unit_name: StoredUnits[0].Unit_Data[0].Area[1].unit_name,
      decimals: parseFloat(e.target.value),
    };

    // console.log(AreaUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML input template to bind with the grid for metric part
  function AreaMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="AreaUnit"
        defaultChecked={
          AreaUnits.current.unit_id ===
          StoredUnits[0].Unit_Data[0].Area[0].unit_id
            ? true
            : false
        }
        onClick={StoreAreaMetricUnits}
      />
    );
  }

  // HTML input template to bind with the grid for english part
  function AreaEnglishRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="AreaUnit"
        defaultChecked={
          AreaUnits.current.unit_id ===
          StoredUnits[0].Unit_Data[0].Area[1].unit_id
            ? true
            : false
        }
        onClick={StoreAreaEnglishUnits}
      />
    );
  }

  // HTML drop down template to bind with the grid for metric part
  function AreaDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={AreaMetricDecimals}
        onChange={StoreAreaMetricDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  // HTML drop down template to bind with the grid for english part
  function AreaEnglishDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={AreaEnglishDecimals}
        onChange={StoreAreaEnglishDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  let AreaData = [
    {
      MetricUnit: `${StoredUnits[0].Unit_Data[0].Area[0].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.12,
      EnglishUnit: `${StoredUnits[0].Unit_Data[0].Area[1].unit_name}` || null,
      English: 20,
      EnglishDecimal: 0.12,
    },
  ];

  const AreaColumn = [
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      template: AreaMetricRadioTemplate.bind(this),
      headerText: "Metric",
      width: 100,
    },
    {
      field: "MetricDecimal",
      headerText: "Decimals",
      width: 100,
      template: AreaDropDownTemplate.bind(this),
    },
    {
      field: "EnglishUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "English",
      headerText: "English",
      width: 100,
      template: AreaEnglishRadioTemplate.bind(this),
    },
    {
      field: "EnglishDecimal",
      headerText: "Decimals",
      width: 100,
      template: AreaEnglishDropDownTemplate.bind(this),
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={AreaData}
        columns={AreaColumn}
      ></GridComponent>
    </div>
  );
};

export default Area;
