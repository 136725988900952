import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";

const MinFlowRateEnglish = () => {
  const [waterFlowData, setWaterFlowData] = useState({
    pipeDiameter: "",
    waterTemperature: "",
    minGPM: "",
  });

  const [waterFlowResult, setWaterFlowResult] = useState(0);

  useEffect(() => {
    const calculateResult = () => {
      let pd = waterFlowData.pipeDiameter;
      let wt = waterFlowData.waterTemperature;

      if (pd > 0 && wt > 0) {
        let ans =
          (4000 *
            (pd *
              (2.414 *
                10 ** -2 *
                10 ** (247.8 / ((wt - 32) / 1.8 + 273.15 - 140))))) /
          3160;

        setWaterFlowResult(Number(ans.toFixed(2)));
      }
    };

    calculateResult();
  }, [waterFlowData]);

  const handleChange = (event) => {
    setWaterFlowData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>

      <div className="mb-4">
        <span>
          <b> Calculation of Minimum Flow rate for Turbulent Flow </b>
        </span>
      </div>

      <div>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>Pipe Diameter (inches)</Form.Label>{" "}
              </Col>
              <Col sm={4}>
                <Form.Control
                  name="pipeDiameter"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0.0-9.0]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={waterFlowData.pipeDiameter}
                  onChange={handleChange}
                  min="0"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>Water Temperature (deg F)</Form.Label>{" "}
              </Col>
              <Col sm={4}>
                <Form.Control
                  name="waterTemperature"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0.0-9.0]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={waterFlowData.waterTemperature}
                  onChange={handleChange}
                  min="0"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>
                  Min Required GPM for turbulent flow (Re {">"} 4000)
                </Form.Label>{" "}
              </Col>
              <Col sm={4}>
                <Form.Control
                  name="minGPM"
                  type="number"
                  value={waterFlowResult}
                  readOnly
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default MinFlowRateEnglish;
