import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
// Import TextEditor Functionality from Syncfusion
import {
    HtmlEditor,
    RichTextEditorComponent,
    Toolbar,
    Inject
} from "@syncfusion/ej2-react-richtexteditor";

const Button_Section = ({ ChangeGraph, ToggleAddRowModal, deleteRow2, ViscocityAddRow, ViscosityAddRowModal, addRow, increaseRow, handleClose, handleShow, PlotChart, handlePrint, show, toolbarSettings, getComment, Comment }) => {

    return (

        <div className="mb-4 d-flex justify-content-between">

            <div className='d-flex justify-content-between col-md-8'>

                <div className='d-flex justify-content-evenly'>

                    <div>
                        <button
                            className="btn btn-info btn-air-info mr-2"
                            type="button"
                            onClick={ToggleAddRowModal}
                        >
                            {" "}
                            Add Row{" "}
                        </button>
                    </div>

                    <div>
                        <button
                            className="btn btn-warning mr-2"
                            type="button"
                            onClick={deleteRow2}
                        >
                            {" "}
                            Delete Row{" "}
                        </button>
                    </div>

                {/* </div>

                <div className='d-flex justify-content-evenly'> */}

                    <div>
                        <button
                            className="btn btn-primary btn-air-primary mr-2"
                            onClick={PlotChart}
                        >
                            {" "}
                            Show Graph{" "}
                        </button>
                    </div>

                    <div>
                        <button
                            className="btn btn-secondary btn-air-secondary mr-2"
                            onClick={handlePrint}
                        >
                            {" "}
                            Print{" "}
                        </button>
                    </div>

                    <div className="d-flex">
                        <div className='mt-2 ml-1 mr-2' style={{fontSize:12}}>
                            X-Axis:
                        </div>
                        <div className='mr-3' style={{height:22}}>
                            <select
                                className="form-control digits"
                                onChange={ChangeGraph}
                            >
                                <option> Injection Speed </option>
                                <option> Shear Rate </option>
                            </select>
                        </div>
                    </div>
                    <div>
                    <button
                        className="btn btn-primary btn-air-primary"
                        type="button"
                        onClick={handleShow}
                    >
                        Comment
                    </button>
                </div>



                </div>

                <ViscocityAddRow
                    ToggleAddRowModal={ToggleAddRowModal}
                    ViscosityAddRowModal={ViscosityAddRowModal}
                    addRow={addRow}
                    increaseRow={increaseRow}
                />
            </div>

            <div className='d-flex'>
                
               

                <Modal isOpen={show} centered>
                    <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                    <ModalBody>
                        <RichTextEditorComponent
                            change={getComment}
                            value={Comment}
                            saveInterval="1"
                            toolbarSettings={toolbarSettings}
                            height={250}
                        >
                            <Inject services={[Toolbar, HtmlEditor]} />
                        </RichTextEditorComponent>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleClose}>
                            {" "}
                            Save & Close{" "}
                        </Button>
                    </ModalFooter>
                </Modal>

            </div>

        </div>

    )
}

export default Button_Section