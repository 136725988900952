import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";

const FlowTypeMetric = () => {
  const [waterFlowData, setWaterFlowData] = useState({
    pipeDiameter: "",
    waterTemperature: "",
    flowRate: "",
  });

  const [waterFlowResult, setWaterFlowResult] = useState(0);

  useEffect(() => {
    const calculateResult = () => {
      let pd = parseFloat(waterFlowData.pipeDiameter);
      let wt = parseFloat(waterFlowData.waterTemperature);
      let fr = parseFloat(waterFlowData.flowRate);

      if (pd > 0 && wt > 0 && fr > 0) {
        let ans =
          (3160 * (fr / 3.785)) /
          ((pd / 25.4) *
            (2.414 * 10 ** -2 * 10 ** (247.8 / (wt + 273.15 - 140))));

        setWaterFlowResult(ans.toFixed(0));
      }
    };

    calculateResult();
  }, [waterFlowData]);

  const handleChange = (event) => {
    setWaterFlowData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>

      <div className="mb-4">
        <span>
          <b> Calculations for Type of Flow </b>
        </span>
      </div>

      <div>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>Pipe Diameter (mm)</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  name="pipeDiameter"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0.0-9.0]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={waterFlowData.pipeDiameter}
                  onChange={handleChange}
                  min="0"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>Water Temperature (deg C) </Form.Label>{" "}
              </Col>
              <Col sm={4}>
                <Form.Control
                  name="waterTemperature"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0.0-9.0]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={waterFlowData.waterTemperature}
                  onChange={handleChange}
                  min="0"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>Flow Rate (LPM)</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  name="flowRate"
                  type="text"
                  value={waterFlowData.flowRate}
                  onKeyPress={(event) => {
                    if (!/[0.0-9.0]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                  min="0"
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicnumber">
            <Row>
              <Col sm={6}>
                <Form.Label>Actual Reynold's Number</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0.0-9.0]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={waterFlowResult}
                  readOnly
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default FlowTypeMetric;
