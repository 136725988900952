import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  Toolbar,
  Page,
} from "@syncfusion/ej2-react-grids";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// CSS
import "../../../App.css";

import DataService from "../../../../services/ApiService";
import { connect } from "react-redux";

const MoldDatabase = ({ user }) => {
  var grid;

  const toolbar = ["Search"];

  const history = useHistory();

  const pathN = () => {
    history.push({
      pathname: `/database/Options/MoldNew`,
    });
  };

  const pathE = () => {
    // console.log(selectedRowIndexes.Machine_Id);

    if (selectedRowIndexes.Mold_Id) {
      let RowId = btoa(selectedRowIndexes.Mold_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MoldEdit`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const pathV = () => {
    if (selectedRowIndexes.Mold_Id) {
      let RowId = btoa(selectedRowIndexes.Mold_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MoldView`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const pathD = () => {
    if (selectedRowIndexes.Mold_Id) {
      let RowId = btoa(selectedRowIndexes.Mold_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MoldDuplicate`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const [selectedRowIndexes, setSelectedRowIndexes] = useState({
    rowIdx: "",
    Mold_Id: "",
  });

  const [AllmoldData, setAllmoldData] = useState([]);

  const [SelectRowModal, setSelectRowModal] = useState(false);

  // To get the Name of the selected material for the respective molds.
  let MaterialData = JSON.parse(sessionStorage.getItem("MaterialData")) || [];

  const selectionSettings = { type: "Single" };

  const click = () => {
    setSelectedRowIndexes({
      rowIdx: grid.getSelectedRowIndexes(),
      Mold_Id: grid.getSelectedRecords()[0]?.id,
    });
  };

  const [column, setColumn] = useState([
    {
      field: "Mold_No",
      headerText: "Mold No",
      textAlign: "left",
    },
    {
      field: "Material_Id",
      headerText: "Mat ID",
      textAlign: "left",
    },
    {
      field: "Platen_Orientation",
      headerText: "Orientation",
      textAlign: "left",
    },
    {
      field: "Number_of_Bases",
      headerText: "No.of Bases",
      textAlign: "right",
    },
    {
      field: "Cycle_Time",
      headerText: "Cycle Time",
      textAlign: "right",
    },
    {
      field: "Mold_Stack_Height",
      headerText: "Stack Ht",
      textAlign: "right",
    },
    {
      field: "Mold_Vertical_Height",
      headerText: "Vertical Ht",
      textAlign: "right",
    },
    {
      field: "Mold_Width",
      headerText: "Width",
      textAlign: "right",
    },
    {
      field: "Number_of_Core_Pulls",
      headerText: "No. of Core Pulls",
      textAlign: "right",
    },
    {
      field: "Hot_Runner_Volume",
      headerText: "HR Vol",
      textAlign: "right",
    },

    {
      field: "Req_Mold_Open_Stroke",
      headerText: "Req Mold Open Stroke",
      textAlign: "right",
    },
  ]);

  const [DeleteConfirm, setDeleteConfirm] = useState(false);

  const [DeleteConfirmHeader, setDeleteConfirmHeader] = useState("");

  const ToggleDeleteConfirm = () => {
    if (selectedRowIndexes.Mold_Id) {
      setDeleteConfirm(!DeleteConfirm);

      setDeleteConfirmHeader(
        selectedRowIndexes.Mold_Id
          ? AllmoldData[selectedRowIndexes.rowIdx].Mold_No
          : ""
      );

      // console.log(selectedRowIndexes);
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  function created(args) {
    if (grid) {
      document
        .getElementById(grid.element.id + "_searchbar")
        .addEventListener("keyup", (args) => {
          var gridObj = document.getElementById("MoldGrid").ej2_instances[0];
          gridObj.search(args.target.value);
        });
    }
  }

  const OnDelete = () => {
    if (selectedRowIndexes.Mold_Id) {
      const updatedRows = [...AllmoldData].filter((value, idx) => {
        return value.id !== selectedRowIndexes.Mold_Id;
      });

      setAllmoldData(updatedRows);

      DataService.DeleteMold(selectedRowIndexes.Mold_Id).then((res) => {
        // console.log(res.data.Machine_Data);

        ToggleDeleteConfirm();
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const GetData = (id) => {

    DataService.FetchMold(id).then((res) => {

      if (res.data.length > 0) {
        let TempArray = [];

        for (let i = 0; i < res.data.length; i++) {
          
          const tempObject = {
            id: res.data[i].id,
            Mold_No: res.data[i].Mold_Id,
            Platen_Orientation: res.data[i].Platen_Orientation,
            Number_of_Bases: res.data[i].Number_Of_Bases,

            Material_Id:
              MaterialData.length > 0
                ? MaterialData.find(
                    (unit) =>
                      unit.id === parseInt(res.data[i].Mold_Data.Material_Id)
                  )
                  ? MaterialData.find(
                      (unit) =>
                        unit.id === parseInt(res.data[i].Mold_Data.Material_Id)
                    ).Material_Id
                  : ""
                : "",
          };

          TempArray.push(Object.assign(tempObject, res.data[i].Mold_Data));
        }

        setAllmoldData([...AllmoldData, ...TempArray]);

      } else {
        setAllmoldData([]);
      }
    });

    DataService.GetFieldsData(id).then((res) => {
      // console.log(res.data)

      if (res.data.length > 0) {

        if (res.data[0].Mold_Custom_Fields.length > 0) {

          // console.log([...column, ...res.data[0].Mold_Custom_Fields]);

          setColumn([...column, ...res.data[0].Mold_Custom_Fields]);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);
    }
  }, [user]);

  useEffect(() => {
    // console.log(AllmoldData);
  }, [AllmoldData]);

  return (
    <>
      <Modal isOpen={SelectRowModal} centered={true} style={{ width: "300" }}>
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> Please click on a row to select. </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => setSelectRowModal(!SelectRowModal)}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={DeleteConfirm} centered={true} style={{ width: "400px" }}>
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span>
            {" "}
            Do you want to delete Material Id {DeleteConfirmHeader} ?{" "}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={OnDelete}>
            {" "}
            Delete{" "}
          </Button>
          <Button color="primary" onClick={ToggleDeleteConfirm}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="card p-3 ml-2">
        <div className="d-flex col-md-12">
          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-info btn-air-info mr-2"
              onClick={pathN}
            >
              New
            </button>
          </div>

          <div className="pt-2 pb-2">
            <button
              className="btn btn-secondary btn-air-secondary mr-2"
              onClick={pathE}
            >
              Edit
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={pathV}
            >
              View
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={pathD}
            >
              Duplicate
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-warning btn-air-warning mr-2"
              onClick={ToggleDeleteConfirm}
            >
              Delete
            </button>
          </div>

          <div className="pt-2 pb-2">
            <button className="btn btn-secondary btn-air-secondary mr-2">
              Print
            </button>
          </div>
        </div>

        <div className="table-responsive" id="DBTable">
          <table className="table">
            <tr>
              <td onClick={click}>
                <GridComponent
                  id="MoldGrid"
                  ref={(g) => (grid = g)}
                  toolbar={toolbar}
                  dataSource={AllmoldData}
                  columns={column}
                  allowPaging={true}
                  pageSettings={{ pageSize: 10 }}
                  selectionSettings={selectionSettings}
                  created={created.bind(this)}
                  height={300}
                >
                  <Inject services={[Toolbar, Page]} />
                </GridComponent>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoldDatabase);
