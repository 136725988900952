import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

const MoldingCalculatorForm = ({ calculateResult, result }) => {

    const [moldingCalculationsInput, setMoldingCalculationsInput] = useState({
        partVolume: '',
        runnerVolume: '',
        specificGravity: '',
        projectedArea: '',
        runnerProjectedArea: '',
        numberOfRunners: '',
        numberOfCavities: '',
        averageCycleTime: '',
        shotCapacity: '',
        tonsPerInch: '',
        screwDiameter: '',
    });

    useEffect(() => {

        let flag = true;

        for (let key in moldingCalculationsInput) {

            if (moldingCalculationsInput[key] <= 0)
                flag = false;

        }

        if (flag)
            calculateResult(moldingCalculationsInput);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moldingCalculationsInput]);

    const handleChange = (event) => {
        setMoldingCalculationsInput(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
    };

    return (

        <div>

            <div style={{ fontWeight: "bold", fontSize: '25px'}}>
                <span> Inputs </span>
            </div>

            <hr></hr>

            <div>
                <Form className='d-flex flex-column justify-content-center'>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Part Volume</Form.Label> </Col>

                            <Col sm={3}> <Form.Control name="partVolume" type="text" value={moldingCalculationsInput.partVolume} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} required /> </Col>

                            <Col> <Form.Control type="text" value={"cubic cm"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Runner Volume</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="runnerVolume" type="text" value={moldingCalculationsInput.runnerVolume} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"cubic cm"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <p>OR</p>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Part Weight</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="partWeight" type="text" defaultValue={result.partWeight} onKeyPress={(event) => {
                                   if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"gms"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}>  <Form.Label>Runner Weight</Form.Label> </Col>

                            <Col sm={3}>

                                <Form.Control name="runnerWeight" type="text" defaultValue={result.runnerWeight} onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />

                            </Col>

                            <Col> <Form.Control type="text" value={"gms"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <hr />

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Specific Gravity</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="specificGravity" type="text" value={moldingCalculationsInput.specificGravity} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <span> {"gms / cubic cm"} </span> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Projected Area</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="projectedArea" type="text" value={moldingCalculationsInput.projectedArea} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"sq. inch"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Runner Projected Area</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="runnerProjectedArea" type="text" value={moldingCalculationsInput.runnerProjectedArea} onChange={handleChange} min="0" />
                            </Col>

                            <Col> <Form.Control type="text" value={"sq. inch"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}>
                                <Form.Label>Number of Runners</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="numberOfRunners" type="text" value={moldingCalculationsInput.numberOfRunners} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Number of Cavities</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="numberOfCavities" type="text" value={moldingCalculationsInput.numberOfCavities} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Average Cycle Time</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="averageCycleTime" type="text" value={moldingCalculationsInput.averageCycleTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"sec"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}>  <Form.Label>Shot Capacity of Machine</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="shotCapacity" type="text" value={moldingCalculationsInput.shotCapacity} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"gms"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Tons/Sq. Inch for material</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="tonsPerInch" type="text" value={moldingCalculationsInput.tonsPerInch} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"tons"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicnumber">

                        <Row>

                            <Col sm={3}> <Form.Label>Screw Diameter</Form.Label> </Col>

                            <Col sm={3}>
                                <Form.Control name="screwDiameter" type="text" value={moldingCalculationsInput.screwDiameter} onChange={handleChange} min="0" onKeyPress={(event) => {
                                    if (!/[0.0-9.0]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                            </Col>

                            <Col> <Form.Control type="text" value={"mm"} style={{ width: '100px' }} readOnly /> </Col>

                        </Row>

                    </Form.Group>

                </Form>
            </div>

        </div>
    )
}

export default MoldingCalculatorForm