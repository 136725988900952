import {
  Metrictons_To_UStons,
  kN_To_UStons,
} from "../../FuncsForConversions/Tonnage/index";

import {
  cm_To_mm,
  mm_To_cm,
  mm_To_in,
  in_To_mm,
  cm_To_in,
  in_To_cm,
} from "../../FuncsForConversions/Distance/index";

import { oz_To_g } from "../../FuncsForConversions/Speed/index";

const ConvertInputFields = ({
  TempData,
  MachineData,
  TempMoldDataArray,
  Calc,
}) => {
  let Specific_Gravity = TempMoldDataArray[0]
    ? parseFloat(TempMoldDataArray[0].Material_Data.Specific_Gravity)
    : null;

  let Mold_Shot_Wt = !isNaN(Calc.MoldshotWeightACT)
    ? parseFloat(Calc.MoldshotWeightACT).toFixed(2)
    : null;

  let Mold_Cycle_Time = parseInt(TempMoldDataArray[0].Cycle_Time);

  let Mold_Hot_Runner_Volume = TempMoldDataArray[0]
    ? parseFloat(TempMoldDataArray[0].Hot_Runner_Volume)
    : null;

  let MoldUnits = {
    Mold_Stack_Height: parseInt(
      TempData[0][0].Unit_Data.Mold_Stack_Height.unit_id
    ),
    Mold_Width: parseInt(TempData[0][0].Unit_Data.Mold_Width.unit_id),
    Mold_Vertical_Height: parseInt(
      TempData[0][0].Unit_Data.Mold_Vertical_Height.unit_id
    ),
    Max_Mold_Open_Daylight: parseInt(
      TempData[0][0].Unit_Data.Mold_Stack_Height.unit_id
    ),
  };

  // Calculating Shot Weight Actual
  const Calc_Shot_Wt_ACT = (Specific_Gravity, Shot_Wt) => {
    // console.log("Shot Weight Actual", Specific_Gravity, Shot_Wt);

    if (Specific_Gravity && Shot_Wt) {
      return (
        (parseFloat(Shot_Wt) / 1.06) *
        parseFloat(Specific_Gravity)
      ).toFixed(2);
    } else {
      return "*";
    }
  };

  // Calculating Shot Weight Percentage
  const Calc_Shot_Wt_PCT = (Mold_Shot_Wt, Machine_Shot_Wt) => {
    // console.log("Shot Weight Percentage", Mold_Shot_Wt, Machine_Shot_Wt);

    if (Mold_Shot_Wt && Machine_Shot_Wt) {
      return (
        (parseFloat(Mold_Shot_Wt) / parseFloat(Machine_Shot_Wt)) *
        100
      ).toFixed(2);
    } else {
      return "*";
    }
  };

  // Calculating Resistance Time of Machine
  const Calc_Res_Time_MACH = (Mold_Shot_Wt, Machine_Shot_Wt) => {
    // console.log("Resistance Time of Machine", Mold_Shot_Wt, Machine_Shot_Wt);

    if (Mold_Shot_Wt && Machine_Shot_Wt) {
      return (
        ((parseFloat(Machine_Shot_Wt) / parseFloat(Mold_Shot_Wt)) *
          Mold_Cycle_Time) /
        60
      ).toFixed(2);
    } else {
      return "*";
    }
  };

  // Calculating Resistance Time of Hot Runner
  const Calc_Res_Time_HR = (
    Mold_Hot_Runner_Volume,
    Specific_Gravity,
    Mold_Shot_Wt_ACT,
    Cycle_Time
  ) => {
    // console.log(
    //   "Resistance Time of Hot Runner",
    //   Mold_Hot_Runner_Volume,
    //   Specific_Gravity,
    //   Mold_Shot_Wt_ACT,
    //   Cycle_Time
    // );

    if (
      Mold_Hot_Runner_Volume &&
      Specific_Gravity &&
      Mold_Shot_Wt_ACT &&
      Cycle_Time
    ) {
      return (
        (((parseFloat(Mold_Hot_Runner_Volume) * parseFloat(Specific_Gravity)) /
          parseFloat(Mold_Shot_Wt_ACT)) *
          parseFloat(Cycle_Time)) /
        60
      ).toFixed(2);
    } else {
      return "*";
    }
  };

  for (let i = 0; i < TempData[1].length; i++) {
    MachineData.push({
      Max_Mold_Vertical_Height:
        parseInt(MoldUnits.Mold_Stack_Height) ===
        parseInt(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.unit_id)
          ? parseFloat(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value)
          : parseInt(MoldUnits.Mold_Stack_Height) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.unit_id
            ) === 10
          ? cm_To_mm(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value, 2)
          : parseInt(MoldUnits.Mold_Stack_Height) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.unit_id
            ) === 22
          ? mm_To_cm(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value, 2)
          : parseInt(MoldUnits.Mold_Stack_Height) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.unit_id
            ) === 11
          ? in_To_mm(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value, 2)
          : parseInt(MoldUnits.Mold_Stack_Height) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.unit_id
            ) === 10
          ? mm_To_in(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value, 2)
          : parseInt(MoldUnits.Mold_Stack_Height) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value
            ) === 22
          ? cm_To_in(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value, 2)
          : parseInt(MoldUnits.Mold_Stack_Height) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.unit_id
            ) === 11
          ? in_To_cm(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value, 2)
          : parseFloat(TempData[1][i].Unit_Data.Max_Mold_Vertical_Height.value),

      Max_Mold_Width:
        parseInt(MoldUnits.Mold_Vertical_Height) ===
        parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.unit_id)
          ? parseFloat(TempData[1][i].Unit_Data.Max_Mold_Width.value)
          : parseInt(MoldUnits.Mold_Width) === 22 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.unit_id) === 10
          ? mm_To_cm(TempData[1][i].Unit_Data.Max_Mold_Width.value, 2)
          : parseInt(MoldUnits.Mold_Width) === 10 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.unit_id) === 22
          ? cm_To_mm(TempData[1][i].Unit_Data.Max_Mold_Width.value, 2)
          : parseInt(MoldUnits.Mold_Width) === 10 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.unit_id) === 11
          ? in_To_mm(TempData[1][i].Unit_Data.Max_Mold_Width.value, 2)
          : parseInt(MoldUnits.Mold_Width) === 11 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.unit_id) === 10
          ? mm_To_in(TempData[1][i].Unit_Data.Max_Mold_Width.value, 2)
          : parseInt(MoldUnits.Mold_Width) === 11 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.value) === 22
          ? cm_To_in(TempData[1][i].Unit_Data.Max_Mold_Width.value, 2)
          : parseInt(MoldUnits.Mold_Width) === 22 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Width.unit_id) === 11
          ? in_To_cm(TempData[1][i].Unit_Data.Max_Mold_Width.value, 2)
          : parseFloat(TempData[1][i].Unit_Data.Max_Mold_Width.value),

      Max_allowable_Mold_Stack_Height:
        parseInt(MoldUnits.Mold_Vertical_Height) ===
        parseInt(
          TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.unit_id
        )
          ? parseFloat(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.unit_id
            ) === 10
          ? mm_To_cm(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.unit_id
            ) === 22
          ? cm_To_mm(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.unit_id
            ) === 11
          ? in_To_mm(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.unit_id
            ) === 10
          ? mm_To_in(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value
            ) === 22
          ? cm_To_in(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.unit_id
            ) === 11
          ? in_To_cm(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value,
              2
            )
          : parseFloat(
              TempData[1][i].Unit_Data.Max_allowable_Mold_Stack_Height.value
            ),

      Min_allowable_Mold_Stack_Height:
        parseInt(MoldUnits.Mold_Vertical_Height) ===
        parseInt(
          TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.unit_id
        )
          ? parseFloat(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.unit_id
            ) === 10
          ? mm_To_cm(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.unit_id
            ) === 22
          ? cm_To_mm(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.unit_id
            ) === 11
          ? in_To_mm(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.unit_id
            ) === 10
          ? mm_To_in(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value
            ) === 22
          ? cm_To_in(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value,
              2
            )
          : parseInt(MoldUnits.Mold_Vertical_Height) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.unit_id
            ) === 11
          ? in_To_cm(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value,
              2
            )
          : parseFloat(
              TempData[1][i].Unit_Data.Min_allowable_Mold_Stack_Height.value
            ),

      Max_Mold_Open_Daylight:
        parseInt(MoldUnits.Max_Mold_Open_Daylight) ===
        parseInt(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.unit_id)
          ? parseFloat(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value)
          : parseInt(MoldUnits.Max_Mold_Open_Daylight) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.unit_id
            ) === 10
          ? mm_To_cm(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value, 2)
          : parseInt(MoldUnits.Max_Mold_Open_Daylight) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.unit_id
            ) === 22
          ? cm_To_mm(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value, 2)
          : parseInt(MoldUnits.Max_Mold_Open_Daylight) === 10 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.unit_id
            ) === 11
          ? in_To_mm(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value, 2)
          : parseInt(MoldUnits.Max_Mold_Open_Daylight) === 11 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.unit_id
            ) === 10
          ? mm_To_in(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value, 2)
          : parseInt(MoldUnits.Max_Mold_Open_Daylight) === 11 &&
            parseInt(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value) ===
              22
          ? cm_To_in(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value, 2)
          : parseInt(MoldUnits.Max_Mold_Open_Daylight) === 22 &&
            parseInt(
              TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.unit_id
            ) === 11
          ? in_To_cm(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value, 2)
          : parseFloat(TempData[1][i].Unit_Data.Max_Mold_Open_Daylight.value),

      Tonnage:
        parseInt(TempData[1][i].Unit_Data.Tonnage.unit_id) === 18
          ? parseFloat(TempData[1][i].Unit_Data.Tonnage.value)
          : parseInt(TempData[1][i].Unit_Data.Tonnage.unit_id) === 19
          ? Metrictons_To_UStons(TempData[1][i].Unit_Data.Tonnage.value, 2)
          : parseInt(TempData[1][i].Unit_Data.Tonnage.unit_id) === 20
          ? kN_To_UStons(TempData[1][i].Unit_Data.Tonnage.value, 2)
          : parseFloat(TempData[1][i].Unit_Data.Tonnage.value),

      "Max_shot_Capacity(Wt)":
        parseInt(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id) ===
        8
          ? parseFloat(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value)
          : parseInt(
              TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id
            ) === 9
          ? oz_To_g(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value, 2)
          : parseFloat(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value),

      "Shot_Wt_(ACT)":
        parseInt(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id) ===
        8
          ? Calc_Shot_Wt_ACT(
              Specific_Gravity,
              parseFloat(
                TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value
              )
            )
          : parseInt(
              TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id
            ) === 9
          ? Calc_Shot_Wt_ACT(
              Specific_Gravity,
              oz_To_g(
                TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value,
                2
              )
            )
          : parseFloat(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value),

      "Shot_Wt_(PCT)":
        parseInt(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id) ===
        8
          ? Calc_Shot_Wt_PCT(
              Mold_Shot_Wt,
              parseFloat(
                TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value
              )
            )
          : parseInt(
              TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id
            ) === 9
          ? Calc_Shot_Wt_PCT(
              Mold_Shot_Wt,
              oz_To_g(
                TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value,
                2
              )
            )
          : parseFloat(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value),

      "Res_Time_(MACH)":
        parseInt(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id) ===
        8
          ? Calc_Res_Time_MACH(
              Mold_Shot_Wt,
              parseFloat(
                TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value
              )
            )
          : parseInt(
              TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].unit_id
            ) === 9
          ? Calc_Res_Time_MACH(
              Mold_Shot_Wt,
              oz_To_g(
                TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value,
                2
              )
            )
          : parseFloat(TempData[1][i].Unit_Data["Max_shot_Capacity(Wt)"].value),

      "Res_Time_(Hot_R)": Calc_Res_Time_HR(
        Mold_Hot_Runner_Volume,
        Specific_Gravity,
        Mold_Shot_Wt,
        Mold_Cycle_Time
      ),
    });

    TempData[1][i] = Object.assign(TempData[1][i], MachineData[i]);
  }

  return TempData[1];
};

export default ConvertInputFields;
