import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import ContourChart from "./ContourChart";
import DataService from "../../../services/ApiService";

const ContourStats = ({ session, mold }) => {
  const [fetchedVariables, setFetchedVariables] = useState({ variables: [] });

  const [responseEntries, setResponseEntries] = useState();

  const [limit, setLimit] = useState();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showContourPlot, setShowContourPlot] = useState(false); // State variable to toggle the visibility of the ContourPlot component

  const selectedVariable = [];

  const [noOfDimensions, setnoOfDimensions] = useState([]);

  const [selectedXAxisVariable, setSelectedXAxisVariable] = useState("");

  const [selectedYAxisVariable, setSelectedYAxisVariable] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [contourLines, setContourLines] = useState(10);

  const [extraFactorsValue, setExtraFactorsValue] = useState("low");

  const [fetchval, setFetchVal] = useState(true);

  const [data, setData] = useState({
    contour_data: [],
    contour_lines: null,
    axis_elements: [],
    axis_limits: { x_axis_limits: [], y_axis_limits: [] },
    num_subarrays: null,
    note_array: [],
  });

  // set index as per the selected cavity from dropdown
  const handleIndexChange = (event) => {
    setSelectedIndex(parseInt(event.target.value, 10));
    setFetchVal(true);
  };

  useEffect(() => {
    // get variables
    DataService.ExptData(session)
      .then((res) => {
        setFetchedVariables(res.data);
      })
      .catch((err) => {
        console.error("Error sending data to Django:", err);
      });

    //Dropdown data
    DataService.GetDefineResponse(session, mold)
      .then((res) => {
        const dimensionJSON = res.data["dimension"];

        // console.log(res)

        setResponseEntries(res.data.response_entries);

        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];

        setnoOfDimensions(dataArray);
      })
      .catch((err) => {
        console.error("Error sending data to Django:", err);
      });

    // console.log(noOfDimensions);

    if (!fetchedVariables.variables.length) {
    }
    if (fetchval) {
      setFetchVal(false);
    }
  }, [fetchedVariables.variables.length, fetchval, session]);

  //set number of lines
  const handleContourLinesChange = (e) => {
    setContourLines(parseInt(e.target.value, 10)); // Parse the input value as an integer
  };

  //set x-axis factor
  const handleXAxisVariableChange = (e) => {
    setSelectedXAxisVariable(e.target.value);
  };

  // set y axis factor
  const handleYAxisVariableChange = (e) => {
    setSelectedYAxisVariable(e.target.value);
  };

  //set the value(low,medium,high) for all other factors than x-axis and y-axis
  const handleExtraFactorsChange = (e) => {
    setExtraFactorsValue(e.target.value);
  };

  //validation on selection of the factors
  const ProcessData = () => {
    if (
      selectedVariable === selectedXAxisVariable ||
      selectedVariable === selectedYAxisVariable ||
      selectedXAxisVariable === selectedYAxisVariable
    ) {
      // Set the error message to be displayed to the user
      setErrorMessage("Selected variables cannot be the same.");
      return;
    }

    // Clear the error message if it was previously set
    setErrorMessage("");

    const filteredVariables = fetchedVariables.variables.filter(
      (variable) =>
        variable !== selectedXAxisVariable && variable !== selectedYAxisVariable
    );
    // console.log("filteredvariables", filteredVariables);

    // Assuming the server expects data in the following format
    const requestData = {
      session_id: session,
      other_variable: filteredVariables,
      x_axis: selectedXAxisVariable,
      y_axis: selectedYAxisVariable,
      contour_lines: contourLines,
      extra_factors_value: extraFactorsValue,
    };

    // console.log("req:", requestData);

    return requestData;
  };

  const handleSaveClick = () => {
    // API sending the selected cavity from dropdown
    SelectDropdown()
      .then(() => {
        DataService.Anova(session).then(() => {
          setShowContourPlot(false);

          var data = ProcessData();

          // console.log(data)

          DataService.PostContour(session, data).then(() => {
            handleNextClick();
          });
        });
      })
      .then(() => {
        //function to calculate lsl, usl and target.
        const limit = computeLimit(noOfDimensions[selectedIndex]);

        setLimit(limit);
      })
      .catch((err) => {
        console.error("Error sending data to Django:", err);
      });
  };

  const SelectDropdown = () => {
    return new Promise(async (resolve, reject) => {
      const selectedDimension = noOfDimensions[selectedIndex];

      try {
        // const decry = atob(sessionId);
        DataService.SelectDropdown(session, {
          selected: selectedDimension,
        })
          .then((res) => { })
          .catch((err) => {
            console.error("Error sending data to Django:", err);
          });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  //API to get the contour data
  const handleNextClick = async () => {
    DataService.GetContour(session).then((res) => {
      setData(res.data);

      // console.log("response from GetContour", res.data)
    });

    setShowContourPlot(true);
  };

  // console.log("responseEntries",responseEntries)

  //calculate usl,lsl and target
  const computeLimit = (cavity) => {
    const cavity_name = cavity.split("~")[1];
    const cavityEntry = responseEntries.find(
      (entry) => entry.response_name === cavity_name
    );

    const nom = cavityEntry.nom;
    const usl = nom + cavityEntry.plus_tol;
    const lsl = nom - cavityEntry.minus_tol;

    const limit = {
      usl: usl,
      lsl: lsl,
      target: nom,
    };

    // console.log("noOfDimensions",noOfDimensions)

    return limit;
  };

  const allLimits = noOfDimensions.map((cavity) => ({
    cavity,
    limit: computeLimit(cavity),
  }));

  // console.log(data.note_array);

  return (
    <div className="b-primary b-r-4 p-2">
      <div className="d-flex">
        <div className="col-md-8 b-primary">

          <div className="d-flex">

            <div className="col-md-4">
              <div className="mt-2 mr-1 mb-1">
                {/* field for number of contour lines */}
                <label htmlFor="contourLinesInput"><b>Contour Increment</b></label>

                <input
                  type="number"
                  id="contourLinesInput"
                  value={contourLines}
                  onChange={handleContourLinesChange}
                  min={1}
                />
              </div>

            </div>

            <div className="col-md-8">
              <label className="mt-2"> <b>Hold Extra Factors at Value</b></label>
              <div className="mr-1 mb-1">
                <FormGroup tag="fieldset" className="form-inline">

                  <FormGroup check className="mr-2">

                    <Label check>
                      <Input
                        type="radio"
                        name="extraFactorsRadio"
                        value="low"
                        checked={extraFactorsValue === "low"}
                        onChange={handleExtraFactorsChange}
                      />
                      Low
                    </Label>

                  </FormGroup>
                  <FormGroup check className="mr-2">
                    <Label check>
                      <Input
                        type="radio"
                        name="extraFactorsRadio"
                        value="medium"
                        checked={extraFactorsValue === "medium"}
                        onChange={handleExtraFactorsChange}
                      />
                      Medium
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="extraFactorsRadio"
                        value="high"
                        checked={extraFactorsValue === "high"}
                        onChange={handleExtraFactorsChange}
                      />
                      High
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>


            </div>


          </div>

          <div className="d-flex">

            <div className="col-md-3">

              <div className="mt-2 mr-1 mb-1">
                <label htmlFor="yAxisVariableDropdown" className="Mr-2"><b>Select Response</b></label>
                <br></br>
                <select value={selectedIndex} onChange={handleIndexChange}>
                  {noOfDimensions.map((dimension, index) => (
                    <option key={index} value={index}>
                      {dimension.charAt(0).toUpperCase() + dimension.slice(1)}
                    </option>
                  ))}
                </select>


              </div>

            </div>

            <div className="col-md-7">
              {errorMessage && (
                <div style={{ color: "red", margin: "10px 0" }} className="ml-5">
                  {errorMessage}
                </div>
              )}
              <div className="d-flex">

                <div className="col-md-6">

                  <div className="mt-2 mr-1 mb-1 ml-4">
                    <label htmlFor="xAxisVariableDropdown" className="mr-1"><b>Select X-Axis</b></label>

                    <select
                      id="xAxisVariableDropdown"
                      value={selectedXAxisVariable}
                      onChange={handleXAxisVariableChange}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      {fetchedVariables.variables.map((variable, index) => (
                        <option key={index} value={variable}>
                          {variable.replace(/_/g," ")}
                        </option>
                      ))}
                    </select>
                  </div>

                </div>

                <div className="col-md-6">

                  {/* select y-axis dropdown */}
                  <div className="mt-2 mr-1 mb-1 ml-4">
                    <label htmlFor="yAxisVariableDropdown"><b>Select Y-Axis</b></label>

                    <select
                      id="yAxisVariableDropdown"
                      value={selectedYAxisVariable}
                      onChange={handleYAxisVariableChange}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {fetchedVariables.variables.map((variable, index) => (
                        <option key={index} value={variable}>
                          {variable.replace(/_/g," ")}
                        </option>
                      ))}
                    </select>
                  </div>



                </div>


              </div>
            </div>


            <div className="col-md-2">
              {/* Plot chart */}
              <div className="mt-3 ml-2">
                <br></br>
                <Button id="next-button" onClick={handleSaveClick}>
                  Plot Chart
                </Button>
              </div>

            </div>

          </div>

        </div>


        {/* **************** */}
        <div className="col-md-4" style={{height: "30vh", overflow:"auto"}} >

          <div className="ml-2">
            <table>
              <thead>
                <tr>
                  <th>Responses</th>
                  <th>Nom Value</th>
                  <th>Specified USL</th>
                  <th>Specified LSL</th>
                </tr>
              </thead>
              <tbody>
                {allLimits.map(({ cavity, limit }, index) => (
                  <tr key={index}>
                    <td>{cavity.charAt(0).toUpperCase()+cavity.slice(1)}</td>
                    <td>{limit.target.toFixed(3)}</td>
                    <td>{limit.usl.toFixed(3)}</td>
                    <td>{limit.lsl.toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>

      <div className="m-1">
        <div>

          {/* Radio button for extra factor value input */}
          {showContourPlot ? ( // Conditionally render the ContourPlot component
            <div className="m-1">
              <ContourChart data={data} session={session} limit={limit} />
            </div>
          ) : (
            "Select Response"
          )}

          {/* <div>
            <table>
              <thead>
                <tr>
                  <th>Responses</th>
                  <th>Nom Value</th>
                  <th>Specified USL</th>
                  <th>Specified LSL</th>
                </tr>
              </thead>
              <tbody>
                {allLimits.map(({ cavity, limit }, index) => (
                  <tr key={index}>
                    <td>{cavity}</td>
                    <td>{limit.target.toFixed(3)}</td>
                    <td>{limit.usl.toFixed(3)}</td>
                    <td>{limit.lsl.toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}

          <div className="m-1">
            {showContourPlot ? (
              <div className="m-3">
                <div>Process Window </div>
                <div style={{ display: "inline" }}>
                  Note: Contours plotted at
                  <ul style={{ display: "inline", listStyleType: "disc", marginLeft: "20px" }}>
                    {data.note_array.map((note, index) => (
                      <li key={index}>
                        {note.value} {note.unit} {note.variable_name.replace(/_/g," ")}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContourStats;
