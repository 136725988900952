import React from "react";

const Input_Section = ({
  setInjection_Speed_Units,
  handleIntensification,
  IntensificationRatio,
  setPressure_Units,
  Pressure_Units, showAlert
}) => {

  const handlePressure_Units = (e) => {
    e.preventDefault();

    if (e.target.value) {
      setPressure_Units(e.target.value);
      showAlert.current = true;
    } else {
      setPressure_Units(1);
    }
  };

  return (

    <div className="d-flex">

      <div className="d-flex">
        <div className="mt-2 ml-2 mr-2">
          <label
            htmlFor="Injection_Speed_Units"
            className="lbl_design, mt-1"
            style={{fontSize:12}}
          >
            {" "}
            Injection Speed Units:{" "}
          </label>
        </div>
        <div>
          <select
            className="mt-2 text-left"
            onChange={(e) => setInjection_Speed_Units(e.target.value)}
            name="Injection_Speed_Units"
            style={{height:22}}
          >
            <option> in/sec </option>
            <option> mm/sec </option>
            <option> in^3/sec </option>
            <option> cm^3/sec </option>
            <option> cms/sec </option>
          </select>
        </div>
      </div>
      <div className="d-flex">
        <div className="mt-2 ml-2 mr-2 text-center">
          <label
            htmlFor="Intensification_Ratio"
            className="lbl_design, mt-1"
            style={{fontSize:12}}
          >
            Intensification Ratio:
          </label>
        </div>
        <div>
          <input
            style={{height:22}}
            className="viscosity-inputs mt-2 text-center"
            onChange={handleIntensification}
            name="Intensification_Ratio"
            defaultValue={IntensificationRatio}
            onKeyPress={(event) => {
              if (!/[-0.0-9.0]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            type="text"
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="mt-1 ml-1 mr-1">
          <label htmlFor="Pressure_Units" className="mt-1" style={{fontSize:12}}>
            {" "}
            Pressure Units:{" "}
          </label>
        </div>
        <div>
          <input
            className="viscosity-inputs mt-2 text-center"
            onChange={handlePressure_Units}
            name="Pressure_Units"
            defaultValue={Pressure_Units}
            type="text"
            style={{height:22}}
          />
        </div>
      </div>

    </div>
  );
};

export default Input_Section;
