import React, { useState, useEffect } from "react";
import { Button, Table } from "reactstrap";
import DataService from "../../../services/ApiService";
import { useParams } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const DefineVariables = () => {
  const history = useHistory();
  const [fetchedVariables, setFetchedVariables] = useState({ variables: [] });
  const [vararr, setVararr] = useState([]);
  const [data, setData] = useState([]);
  const { sessionId } = useParams();

  const location = useLocation();

  const mold = location.state.mold;
  

  useEffect(() => {
    if (sessionId) {
      let decry_SessionID = atob(sessionId);
      DataService.ExptData(decry_SessionID)
        .then((res) => {
          setFetchedVariables(res.data);
        })
        .catch((err) => {
          console.error("Error sending data to Django:", err);
        });
    }
  }, [sessionId]);

  const handleSubmit = async () => {
    var decry_SessionID = atob(sessionId);
    let tempdata = { session_id: decry_SessionID, variable_details: data };

    DataService.PostVariable(decry_SessionID, tempdata)
      .then((res) => {
        console.log("Response from PostVariable API", res);

        history.push({
          pathname:`/dashboard/${sessionId}/DOE/define-response`,
          state: {mold:  mold ,session: decry_SessionID,}
      }
        )

       
      })
      .catch((err) => {
        console.log("Response from PostVariable API=>Error!!", err);
      });
  };

  const handleSave = () => {
    const isValid = vararr.every((data) => +data.low <= +data.high);

    if (!isValid) {
      alert("Low value cannot be higher than high value in any row.");
      return;
    }

    const postData = isFalse();
    setData(postData);
  };
  // console.log(data)

  const isFalse = () => {
    const decry_SessionID = atob(sessionId);
    const postData = vararr.map((data, index) => ({
      session_id: decry_SessionID,
      variable_name: fetchedVariables.variables[index],
      unit: data.unit,
      high: data.high,
      low: data.low,
      precision: data.precision,
    }));
    if (postData.length === fetchedVariables.variables.length) {
      return postData;
    } else {
      alert("Please input data for all variables before submitting.");
      return null;
    }
  };

  const onChange = (event, index, property) => {
    const { value } = event.target;
    const updatedData = [...vararr];
    if (!updatedData[index]) {
      updatedData[index] = {};
    }
    updatedData[index][property] = value;
    setVararr(updatedData);
  };

  return (
    <div className="summary-table">
      Define Variables
      <Table bordered>
        <thead>
          <tr>
            <th>No.</th>
            <th>Variables</th>
            <th>Units</th>
            <th>High</th>
            <th>Low</th>
            <th>Precision</th>
          </tr>
        </thead>
        <tbody>
          {fetchedVariables.variables.map((variable, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="text"
                  name={`variable_${index}`}
                  value={fetchedVariables.variables[index]}
                  readOnly
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`units_${index}`}
                  onChange={(e) => onChange(e, index, "unit")}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`high_${index}`}
                  onChange={(e) => onChange(e, index, "high")}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`low_${index}`}
                  value={variable.low}
                  onChange={(e) => onChange(e, index, "low")}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`precision_${index}`}
                  value={variable.precision}
                  onChange={(e) => onChange(e, index, "precision")}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button id="next-button" onClick={handleSave}>
        Save
      </Button>
      <Button id="next-button" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default DefineVariables;
