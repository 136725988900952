// Temperature
export function celsiusToFahrenheit(celsius, Decimals) {
  let CalcValue = celsius ? (celsius * 9) / 5 + 32 : "";

  return isNaN(parseFloat(CalcValue).toFixed(Decimals))
    ? ""
    : parseFloat(CalcValue).toString().includes(".")
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}

export function fahrenheitToCelsius(fahrenheit, Decimals) {
  let CalcValue = fahrenheit ? ((fahrenheit - 32) * 5) / 9 : "";

  return isNaN(parseFloat(CalcValue).toFixed(Decimals))
    ? ""
    : parseFloat(CalcValue).toString().includes(".")
    ? parseFloat(CalcValue).toFixed(Decimals)
    : parseFloat(CalcValue);
}
