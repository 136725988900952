import React from "react";
import Table from "react-bootstrap/Table";
import "../../../assets/custom-stylesheet/grid_stylecss.css";

const SuggestedDimGrid = ({
  PredictedValues,
  ResponseName,
  Specifications,
}) => {
  const organizeData = () => {
    const cavData = {};
    const dimensions = new Set();

    ResponseName.forEach(([name], index) => {
      const parts = name.split("dimension~")[1].split("-");
      const cav = `${parts[0]}-${parts[1]}`;
      const dimension = parts.slice(2).join("~");

      if (!cavData[cav]) {
        cavData[cav] = {};
      }

      cavData[cav][dimension] = Number(PredictedValues[index]).toFixed(4);
      dimensions.add(dimension);
    });

    return { cavData, dimensions: Array.from(dimensions) };
  };

  const { cavData, dimensions } = organizeData();
  const cavities = Object.keys(cavData);

  return (
    <>
      <div
        className="spreadsheet"
        id="BackPress-Calc-Grid"
        style={{ width: "100%", height: "400", overflow: "auto"  }}
      >
        
                    <div className="b-primary p-2">
                      {Specifications && (
                        <Table
                          striped
                          bordered
                          hover
                          responsive
                          variant="light"
                        >
                          <thead>
                            <tr>
                              <th
                                rowSpan="2"
                                style={{
                                  whiteSpace: "nowrap",
                                  padding: "0 8px",
                                  maxWidth: "1px",
                                }}
                              >
                                Specification
                              </th>
                              {dimensions.map((dimension, key) => (
                                <th colSpan={cavities.length} key={key}>
                                  {dimension}
                                </th>
                              ))}
                            </tr>
                           
                          </thead>
                          <tbody>
                            {["usl", "plus_tol", "nom", "minus_tol", "lsl"].map(
                              (specKey, key) => (
                                <tr key={key}>
                                  <td>
                                    {specKey === "plus_tol"
                                      ? "+ TOL"
                                      : specKey === "minus_tol"
                                      ? "- TOL"
                                      : specKey === "nom"
                                      ? "Nom"
                                      : specKey.replace("_", " ").toUpperCase()}
                                  </td>
                                  {dimensions.map((dimension) => (
                                    <td
                                      colSpan={cavities.length}
                                      key={dimension}
                                    >
                                      {Specifications[dimension]?.[
                                        specKey
                                      ]?.toFixed(3) || "-"}
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                           
                            <tr>
                            <td></td>
                              {dimensions.map((dimension) =>
                                cavities.map((cav, key) => (
                                  <td key={key}>{cav.charAt(0).toUpperCase() + cav.slice(1)}</td>
                                ))
                              )}
                            </tr>
                            <tr>
                              <td>Predicted Values</td>
                              {dimensions.map((dimension) =>
                                cavities.map((cav, idx) => {
                                  const predicted = Number(
                                    cavData[cav][dimension]
                                  );
                                  const lsl = Specifications[dimension]?.lsl;
                                  const usl = Specifications[dimension]?.usl;
                                  return (
                                    <td
                                      key={idx}
                                      style={{
                                        backgroundColor:
                                          lsl !== undefined && usl !== undefined
                                            ? predicted >= lsl &&
                                              predicted <= usl
                                              ? "#d4edda"
                                              : "#f8d7da"
                                            : "",
                                        color:
                                          lsl !== undefined && usl !== undefined
                                            ? predicted >= lsl &&
                                              predicted <= usl
                                              ? "#155724"
                                              : "#721c24"
                                            : "",
                                      }}
                                    >
                                      {predicted.toFixed(3)}
                                    </td>
                                  );
                                })
                              )}
                            </tr>
                            {["nom", "usl", "lsl"].map((specKey, key) => (
                              <tr key={key}>
                                <td>Distance from {specKey.charAt(0).toUpperCase() + specKey.slice(1)}</td>
                                {dimensions.map((dimension) =>
                                  cavities.map((cav, idx) => {
                                    const predicted = Number(
                                      cavData[cav][dimension]
                                    );
                                    const specValue =
                                      Specifications[dimension]?.[specKey];
                                    const distance = specValue
                                      ? (predicted - specValue).toFixed(3)
                                      : "-";
                                    const lsl = Specifications[dimension]?.lsl;
                                    const usl = Specifications[dimension]?.usl;
                                    return (
                                      <td
                                        key={idx}
                                        // className={lsl !== undefined && usl !== undefined ? getCellStyle(predicted, lsl, usl) : ''}
                                        style={{
                                          backgroundColor:
                                            lsl !== undefined &&
                                            usl !== undefined
                                              ? predicted >= lsl &&
                                                predicted <= usl
                                                ? "#d4edda"
                                                : "#f8d7da"
                                              : "",
                                          color:
                                            lsl !== undefined &&
                                            usl !== undefined
                                              ? predicted >= lsl &&
                                                predicted <= usl
                                                ? "#155724"
                                                : "#721c24"
                                              : "",
                                        }}
                                      >
                                        {distance}
                                      </td>
                                    );
                                  })
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
           
    </>
  );
};

export default SuggestedDimGrid;
