import React, { useState, useEffect, useRef } from "react";
import "../App.css";

// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";

import { GridComponent, Inject, Toolbar } from "@syncfusion/ej2-react-grids";

import { ToastContainer } from "react-toastify";
import Header2 from "../common/header-component/header2";
import {
  get_user_units,
  get_units,
  get_materials,
  get_machines,
  get_molds,
  get_custom_fields,
  get_pps_data,
} from "../../actions/auth";
import { connect } from "react-redux";

import MoldMachine from "./MM_Compatibility_Analysis/MoldMachine";
import ProcessMachine from "./PM_Compatibility_Analysis/ProcessMachine";
import AcceptableMachines from "./List_Of_Acceptable_Machines/AcceptableMachines";

import FieldsConversion from "./MM_Compatibility_Analysis/FieldsConversion";
import CreateData from "./PM_Compatibility_Analysis/DataCreationFunctions";
import CreateRefData from "./PM_Compatibility_Analysis/RefDataCreationfunstion";

// Using a Function Component
const Database = ({
  user,
  get_user_units,
  get_units,
  get_materials,
  get_machines,
  get_molds,
  get_custom_fields,
  get_pps_data,
  ...props
}) => {
  var grid;

  const toolbar = ["Search"];

  const tabInstance = useRef(null);

  let { TabIdx } = props.location;

  let TabName = [
    "Mold-Machine Compatibility Analysis",
    "Process-Machine Compatibility Analysis",
    "List Of Acceptable Machines",
  ];

  const [ActiveTab, setActiveTab] = useState(TabName[0]);

  // Get the data from session storage
  let StoredMaterialData =
    JSON.parse(sessionStorage.getItem("MaterialData")) || [];
  let StoredMachineData =
    JSON.parse(sessionStorage.getItem("MachineData")) || [];
  let StoredUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];
  let StoredMoldData = JSON.parse(sessionStorage.getItem("MoldData")) || [];
  let FieldsData = JSON.parse(sessionStorage.getItem("FieldsData")) || [];

  // Mold and Machine Data
  const [SelectedMold, setSelectedMold] = useState([]);
  const [MachineDataList, setMachineDataList] = useState([]);
  const [PPS_DATA, setPPS_DATA] = useState([]);
  const [PSData, setPSData] = useState([]);
  let [TempMoldDataArray, setTempMoldDataArray] = useState([]);
  const TempMachineDataArray = [];

  // Campatibility Rules
  const [ConditonRow, setConditionRow] = useState([{}]);
  const [SelectedCustomFieldData, setSelectedCustomFieldData] = useState([]);
  const [CustomColumnData, setCustomColumnData] = useState([]);
  const [isDeleteId, setIsDeleteId] = useState(null);
  const [InputAlerts, setInputAlerts] = useState({
    isTonnage: false,
    isLimits: false,
    isCushion: false,
  });
  const [MMData, setMMData] = useState({
    Required_Tonnage: "",
    Specify_Min: "",
    Specify_Max: "",
    Input_Desired_Cushion_mm: {
      value: "5",
      unit_id: "10",
    },
    Input_Desired_Cushion_in: {
      value: "0.15",
      unit_id: "11",
    },
  });
  const [SelectedMachine, setSelectedMachine] = useState([]);

  const Operator = [
    { text: "", Symbol: "" },
    { text: "equal", Symbol: "===" },
    { text: "not equal to", Symbol: "!==" },
    { text: "greater than", Symbol: "<" },
    { text: "less than", Symbol: ">" },
    { text: "less than equal", Symbol: ">=" },
    { text: "greater than equal", Symbol: "<=" },
  ];

  const [ProcessRefMachineData, setProcessRefMachineData] = useState(
    CreateRefData.ProcessRefMachineData
  );
  const [ProcessTgtMachineData, setProcessTgtMachineData] = useState(
    CreateRefData.ProcessRefMachineData
  );

  const HandleInputChange = (event) => {
    const { name, value, dataset } = event.target;

    setMMData(
      name === "Input_Desired_Cushion_mm" || name === "Input_Desired_Cushion_in"
        ? {
            ...MMData,
            [name]: {
              value: value ? value : "",
              unit_id: dataset.unitid,
            },
          }
        : {
            ...MMData,
            [name]: value,
          }
    );

    setTempMoldDataArray(TempMoldDataArray);

    if (name === "Required_Tonnage")
      setInputAlerts({ ...InputAlerts, isTonnage: false });
    if (name === "Specify_Min" || name === "Specify_Max")
      setInputAlerts({ ...InputAlerts, isLimits: false });
    if (
      name === "Input_Desired_Cushion_mm" ||
      name === "Input_Desired_Cushion_in"
    )
      setInputAlerts({ ...InputAlerts, isCushion: false });
  };

  useEffect(() => {
    // console.log(MMData);
  }, [MMData]);

  const HandlePSDataChange = (event) => {
    const { value } = event.target;

    setPSData([PPS_DATA[value]]);

    setSelectedMachine(
      MachineDataList.find((item) => item.id === PPS_DATA[value].Machine_Id)
    );

    setTempMoldDataArray(TempMoldDataArray);
  };

  const handleChange = (event) => {
    const { name, value, dataset } = event.target;

    let ColumnArray = [];

    ColumnArray[dataset.rowid] = [
      name === "Mold_Custom_Fields"
        ? {
            // For machine custom field data
            field: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["field"]
              : "",

            // For 1st header - Which will be combination of Mold and Machine selected field
            headerText: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["headerText"]
              : "",

            Operator: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["Operator"]
              : "",

            MoldHeader: FieldsData[0][name][value].headerText,

            // For 2nd header - Which will be for display Mold selected field value
            MoldField: FieldsData[0][name][value].field,
          }
        : name === "Machine_Custom_Fields"
        ? {
            // For machine custom field data
            field: FieldsData[0][name][value].field,

            // For 1st header - Which will be combination of Mold and Machine selected field
            headerText: FieldsData[0][name][value].headerText,

            Operator: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["Operator"]
              : "",

            MoldHeader: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["MoldHeader"]
              : "",

            MoldField: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["MoldField"]
              : "",
          }
        : {
            // For machine custom field data
            field: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["field"]
              : "",

            // For 1st header - Which will be combination of Mold and Machine selected field
            headerText: SelectedCustomFieldData[dataset.rowid][0]["headerText"],

            Operator: value,

            MoldHeader: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["MoldHeader"]
              : "",

            MoldField: SelectedCustomFieldData[dataset.rowid]
              ? SelectedCustomFieldData[dataset.rowid][0]["MoldField"]
              : "",
          },
    ];

    setSelectedCustomFieldData({
      ...SelectedCustomFieldData,
      [dataset.rowid]: ColumnArray[dataset.rowid],
    });
  };

  const GenerateColumnArray = () => {
    let allUnits = [];

    for (const category in SelectedCustomFieldData) {
      // Add each unit object to the allUnits array
      allUnits = allUnits.concat(SelectedCustomFieldData[category]);
    }

    setCustomColumnData(allUnits);

    closeFieldsDialog();
  };

  const AddCondition = () => {
    const newConditionRow = {};

    setConditionRow([...ConditonRow, newConditionRow]);
  };

  const DeleteColumnId = (RowIdx) => {
    setIsDeleteId(RowIdx);
  };

  const DeleteCondition = () => {
    setSelectedCustomFieldData((prevArray) => {
      const newArray = prevArray;

      delete newArray[isDeleteId];

      return newArray;
    });

    setConditionRow((prevArray) => {
      const newArray = [...prevArray];
      newArray.splice(isDeleteId, 1);
      return newArray;
    });
  };

  // Declare the names of the tab's
  let headertext = [
    { text: "Mold-Machine Compatibility Analysis" },
    { text: "Process-Machine Compatibility Analysis" },
    { text: "List Of Acceptable Machines" },
  ];

  const OpenDialog = () => {
    const dialogBox = document.getElementById("dialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.remove("hidden");
    dialogBoxContainer.classList.add("backdrop");
  };

  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.add("hidden");
    dialogBoxContainer.classList.remove("backdrop");
  };

  const OpenFieldsDialog = () => {
    const dialogBox = document.getElementById("FieldsdialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.remove("hidden");
    dialogBoxContainer.classList.add("backdrop");
  };

  const closeFieldsDialog = () => {
    const dialogBox = document.getElementById("FieldsdialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.add("hidden");
    dialogBoxContainer.classList.remove("backdrop");
  };

  const selectionSettings = { type: "single", checkboxMode: "ResetOnRowClick" };

  const MoldColumns = [
    {
      headerText: "",
      width: 10,
      template: MoldSelectionTemplate.bind(this),
    },
    {
      field: "Mold_Id",
      headerText: "Mold Name",
      width: 50,
      textAlign: "center",
    },
  ];

  function MoldSelectionTemplate(props) {
    // console.log(props)
    return (
      <input
        type="radio"
        name="SpeedUnit"
        onClick={() => rowSelected(props)}
        defaultChecked={
          TempMoldDataArray[0]?.Mold_Id === props.Mold_Id ? true : false
        }
      />
    );
  }

  const rowSelected = (args) => {
    TempMoldDataArray = [
      {
        ...args.Mold_Data,
        id: args.id,
        Material_Name: StoredMaterialData.find(
          (unit) => unit.id === parseInt(args.Units.Material_Id.value)
        )
          ? StoredMaterialData.find(
              (unit) => unit.id === parseInt(args.Units.Material_Id.value)
            ).Material_Id
          : "",
        Material_Id: args.Mold_Data.Material_Id,
        Material_Data: StoredMaterialData.find(
          (unit) => unit.id === parseInt(args.Units.Material_Id.value)
        )
          ? StoredMaterialData.find(
              (unit) => unit.id === parseInt(args.Units.Material_Id.value)
            ).Material_Data
          : "",
        Platen_Orientation: args.Platen_Orientation,
        Mold_Id: args.Mold_Id,
        Number_Of_Bases: args.Number_Of_Bases,
        Number_Of_Parts: args.Number_Of_Parts,
        Unit_Data: args.Units,
        Part_Data: args.Part_Data,
        Part_Details: args.Part_Details,
      },
    ];

    get_pps_data(args.id);
  };

  const [ModalStates, setModalStates] = useState({
    SelectMold: {
      visibility: false,
      message: "Please Select a Mold",
    },
  });

  const ToggleModalStates = (ModalKey) => {
    setModalStates({
      ...ModalStates,
      [ModalKey]: {
        ...ModalStates[ModalKey],
        visibility: !ModalStates[ModalKey].visibility,
      },
    });
  };

  const DisplayCompatibility = () => {
    // Machine - Max_Mold_Vertical_Height, Max_Mold_Width, Max_allowable_Mold_Stack_Height, Min_allowable_Mold_Stack_Height
    // Mold - Mold_Stack_Height, Mold_Width, Mold_Vertical_Height

    if (MMData.Required_Tonnage === "") {
      setInputAlerts({
        ...InputAlerts,
        isTonnage: !InputAlerts.isTonnage,
      });
      setTempMoldDataArray(TempMoldDataArray);
      setMachineDataList([]);
    } else if (MMData.Specify_Min === "" || MMData.Specify_Max === "") {
      setInputAlerts({
        ...InputAlerts,
        isLimits: !InputAlerts.isLimits,
      });
      setTempMoldDataArray(TempMoldDataArray);
      setMachineDataList([]);
    } else if (
      MMData.Input_Desired_Cushion_in.value === "" ||
      MMData.Input_Desired_Cushion_mm.value === ""
    ) {
      setInputAlerts({
        ...InputAlerts,
        isCushion: !InputAlerts.isCushion,
      });
      setTempMoldDataArray(TempMoldDataArray);
      setMachineDataList([]);
    } else {
      CreateData.GetData({
        TempMachineDataArray,
        StoredMachineData,
        TempMoldDataArray,
        ToggleModalStates,
      }).then((TempData) => {
        CreateData.PerformCalculations({ MMData, TempMoldDataArray })
          .then((Calc) => {
            // console.log(Calc[2]);

            let MachineData = [],
              ConvertMachineData = [];

            ConvertMachineData = FieldsConversion({
              TempData,
              MachineData,
              TempMoldDataArray,
              Calc,
            });

            for (let i = 0; i < ConvertMachineData.length; i++) {
              // console.log(ConvertMachineData[i]["Res_Time_(Hot_R)"], ConvertMachineData[i]["Res_Time_(MACH)"])

              if (
                ConvertMachineData[i]["Res_Time_(Hot_R)"] &&
                ConvertMachineData[i]["Res_Time_(MACH)"] &&
                ConvertMachineData[i]["Res_Time_(Hot_R)"] !== "*" &&
                ConvertMachineData[i]["Res_Time_(MACH)"] !== "*"
              ) {
                ConvertMachineData[i]["Total_Res_Time"] = (
                  parseFloat(ConvertMachineData[i]["Res_Time_(Hot_R)"]) +
                  parseFloat(ConvertMachineData[i]["Res_Time_(MACH)"])
                ).toFixed(2);
              } else {
                ConvertMachineData[i]["Total_Res_Time"] = "*";
              }
            }

            setMachineDataList(
              ConvertMachineData.map((MachinData) => {
                if (isNaN(MachinData["Max_shot_Capacity(Wt)"])) {
                  return {
                    ...MachinData,
                    "Max_shot_Capacity(Wt)": "*",
                  };
                }
                return MachinData;
              })
            );

            setSelectedMold([
              {
                ...TempData[0][0],
                Tonnage: !isNaN(Calc.Moldtonnage)
                  ? parseFloat(Calc.Moldtonnage).toFixed(2)
                  : "*",
                Shot_Wt_GPPS: !isNaN(Calc.MoldshotWeightGPPS)
                  ? parseFloat(Calc.MoldshotWeightGPPS).toFixed(2)
                  : "*",
                Shot_Wt_ACT: !isNaN(Calc.MoldshotWeightACT)
                  ? parseFloat(Calc.MoldshotWeightACT).toFixed(2)
                  : "*",
              },
            ]);

            // console.log(ConvertMachineData)

            setPPS_DATA( JSON.parse(sessionStorage.getItem("PPS_DATA")) )
            
          })
          .then(() => {
            CreateRefData.CreateProcessMachineData({
              ProcessRefMachineData,
              PSData,
              StoredUnits,
              setProcessRefMachineData,
            }).then((ProcessRefMachineData) => {
              CreateData.CreateProcessTGTMachineData({
                MachineDataList,
                MMData,
                StoredUnits,
                SelectedMachine,
                setProcessTgtMachineData,
                ProcessRefMachineData,
              });
            });
          });
      });
    }
  };

  useEffect(() => {
    // console.log(InputAlerts);
  }, [InputAlerts]);

  useEffect(() => {
    CreateRefData.CreateProcessMachineData({
      ProcessRefMachineData,
      PSData,
      StoredUnits,
      setProcessRefMachineData,
    }).then((ProcessRefMachineData) => {
      CreateData.CreateProcessTGTMachineData({
        MachineDataList,
        MMData,
        StoredUnits,
        SelectedMachine,
        setProcessTgtMachineData,
        ProcessRefMachineData,
      });
    });
  }, [PSData]);

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return (
      <div>
        <MoldMachine
          SelectedMold={SelectedMold}
          MachineDataList={MachineDataList}
          CustomColumnData={CustomColumnData}
          MMData={MMData}
        />
      </div>
    );
  }

  function content1() {
    return (
      <div>
        <ProcessMachine
          MachineDataList={MachineDataList}
          SelectedMachine={SelectedMachine}
          ProcessRefMachineData={ProcessRefMachineData}
          ProcessTgtMachineData={ProcessTgtMachineData}
        />
      </div>
    );
  }

  function content2() {
    return (
      <div>
        <AcceptableMachines
          MachineDataList={MachineDataList}
          SelectedMachine={SelectedMachine}
        />
      </div>
    );
  }

  const tabSelected = (args) => {
    if (args.selectedIndex === 0) {
      setActiveTab(TabName[0]);
    } else if (args.selectedIndex === 1) {
      setActiveTab(TabName[1]);
    } else if (args.selectedIndex === 2) {
      setActiveTab(TabName[2]);
    }
  };

  useEffect(() => {
    if (TabIdx) {
      tabInstance.current.select(TabIdx);
      setActiveTab(TabName[TabIdx]);
    }

    get_units();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      get_user_units(user.id);

      get_materials(user.id);
      get_machines(user.id);
      get_molds(user.id);
      get_custom_fields(user.id);
    }

    sessionStorage.removeItem("PPS_DATA");

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    // console.log(StoredMoldData);
    // console.log(StoredMaterialData);
    // console.log(StoredMachineData);
  }, [user]);

  return (
    <>
      <Header2 Title="Compatibility" />

      <Modal
        isOpen={ModalStates.SelectMold.visibility}
        centered={true}
        style={{ width: "500" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.SelectMold.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("SelectMold")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div id="dialogBoxContainer" className="">
        <div id="FieldsdialogBox" className="dialog_Box hidden">
          <h5> Mold Machine Compatibility </h5>
          <hr></hr>
          <div className="dialog-content">
            <div className="m-2">
              <span style={{ color: "red" }}>
                How this works: Assume that the selected operator is 'less
                than', then if Mold Custom Field is less than the Machine Custom
                Field, this is considered as an acceptable situation and the
                result in the Mold Machine Compatibility matrix will be green.
              </span>
            </div>

            <div id="MM_Fields_Table">
              <Table
                id="Custom_Fields_Table"
                className="table-responsive"
                style={{ border: "1px solid black" }}
                height={200}
                cellPadding={0}
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th align="center" style={{ width: "210px" }}>
                      Mold Custom Field
                    </th>

                    <th align="center" style={{ width: "175px" }}>
                      Operator
                    </th>

                    <th align="center" style={{ width: "220px" }}>
                      Machine Custom Field
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {ConditonRow.map((_, key) => (
                    <tr key={key}>
                      <td align="left" style={{ background: "#fff" }}>
                        <select
                          className="form-control"
                          width="60"
                          name="Mold_Custom_Fields"
                          data-rowid={key}
                          onChange={handleChange}
                          onClick={() => DeleteColumnId(key)}
                        >
                          {FieldsData[0]?.Mold_Custom_Fields.map(
                            (Data, key) => (
                              <React.Fragment key={key}>
                                {key === 0 ? (
                                  <option> {"Select a field"} </option>
                                ) : null}
                                <option value={key}> {Data.headerText} </option>
                              </React.Fragment>
                            )
                          )}
                        </select>
                      </td>

                      <td
                        align="left"
                        style={{ background: "#fff" }}
                        onChange={handleChange}
                      >
                        <select
                          className="form-control"
                          width="60"
                          name="Operator"
                          data-rowid={key}
                          onClick={() => DeleteColumnId(key)}
                        >
                          {Operator.map((Operator, key) => (
                            <>
                              {key === 0 ? (
                                <option key={key}>
                                  {" "}
                                  {"Select an Operator"}{" "}
                                </option>
                              ) : (
                                <option key={key} value={Operator.Symbol}>
                                  {Operator.text}
                                </option>
                              )}
                            </>
                          ))}
                        </select>
                      </td>

                      <td align="left" style={{ background: "#fff" }}>
                        <select
                          className="form-control"
                          name="Machine_Custom_Fields"
                          width="60"
                          data-rowid={key}
                          onChange={handleChange}
                          onClick={() => DeleteColumnId(key)}
                        >
                          {FieldsData[0]?.Machine_Custom_Fields.map(
                            (Data, key) => (
                              <React.Fragment key={key}>
                                {key === 0 ? (
                                  <option> {"Select a field"} </option>
                                ) : null}
                                <option value={key}> {Data.headerText} </option>
                              </React.Fragment>
                            )
                          )}
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-primary btn-air-secondary mr-2"
                  onClick={AddCondition}
                >
                  Add Conditon
                </button>
              </div>

              <div className="pt-2 pb-2">
                <button
                  className="btn btn-danger btn-air-secondary mr-2"
                  onClick={DeleteCondition}
                >
                  Delete Conditon
                </button>
              </div>

              <div className="pt-2 pb-2">
                <button
                  className="btn btn-secondary btn-air-secondary mr-2"
                  onClick={GenerateColumnArray}
                >
                  Save&Close
                </button>
              </div>

              <div className="pt-2 pb-2">
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  onClick={closeFieldsDialog}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="dialogBoxContainer" className="">
        <div id="dialogBox" className="hidden" style={{ width: "400px" }}>
          <h5> Select Mold </h5>
          <hr></hr>
          <div className="dialog-content">
            <div className="m-2" style={{ border: "1px solid black" }}>
              <GridComponent
                ref={(g) => (grid = g)}
                id="grid2"
                toolbar={toolbar}
                dataSource={StoredMoldData}
                columns={MoldColumns}
                selectionSettings={selectionSettings}
                height={300}
              >
                <Inject services={[Toolbar]} />
              </GridComponent>
            </div>
            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-secondary btn-air-secondary mr-2"
                  onClick={closeDialog}
                >
                  Select
                </button>
              </div>

              <div className="pt-2 pb-2 text-left">
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  onClick={closeDialog}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Compatibility{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "14px" }}
              >
                {" "}
                {ActiveTab}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="card ml-4 mr-4 mt-2 mb-4" style={{ height: "1000px" }}>

          <div className="b-primary b-r-4 p-2 m-3">
          <div className="d-flex col-md-16">
            {/* 1st section */}
            <div style={{ borderRight: "1px grey solid" }}>
              {/*  */}
              <div className="d-flex text-left">
                <div className="p-2">
                  <span> Select Mold Number </span>
                </div>
                <div className="p-2">
                  <span> {SelectedMold[0]?.Mold_Id || "NA"} </span>
                </div>
                <div className="pl-2">
                  <button
                    className="btn btn-primary btn-air-primary mr-2"
                    onClick={OpenDialog}
                  >
                    ...
                  </button>
                </div>
              </div>
              {/*  */}

              <div className="d-flex text-left">
                <div className="p-2">
                  <span> Select Process Sheet </span>
                </div>
                <div className="p-2">
                  <select
                    className="form-control"
                    name="Machine_Custom_Fields"
                    width="60"
                    onChange={HandlePSDataChange}
                  >
                    {PPS_DATA?.map((Data, key) => (
                      <React.Fragment key={key}>
                        {key === 0 ? (
                          <option> {"Select Process Sheet"} </option>
                        ) : null}
                        <option value={key}> {Data.Session_Name} </option>
                      </React.Fragment>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* 2nd section */}
            <div className="d-flex" style={{ borderRight: "1px grey solid" }}>
              {/*  */}
              <div>
                <div
                  className="text-left"
                  style={{
                    border: InputAlerts.isTonnage ? "2px solid red" : null,
                  }}
                >
                  <div className="d-flex p-2">
                    <div>
                      <span> Specify required tonnage for material: </span>
                    </div>{" "}
                    <div className="pl-2" style={{ width: "100px" }}>
                      <input
                        type="text"
                        className="form-control"
                        name="Required_Tonnage"
                        onChange={HandleInputChange}
                        onKeyPress={(event) => {
                          if (!/[0.0-9.0]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="pl-2">
                      <span> tons/sq inch </span>
                    </div>
                  </div>

                  <div>
                    {InputAlerts.isTonnage ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        *Required Tonnage cannot be left blank!{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}

                <div
                  style={
                    InputAlerts.isLimits
                      ? { border: "2px solid red", marginTop: "5px" }
                      : {}
                  }
                >
                  <div className="d-flex p-2">
                    <div>
                      <span> Specify shot size usage limits: </span>
                    </div>
                    <div className="d-flex pl-2" style={{ width: "120px" }}>
                      <div className="mr-1">{"Min:"}</div>
                      <div className="ml-1">
                        <input
                          type="text"
                          className="form-control"
                          name="Specify_Min"
                          onChange={HandleInputChange}
                          onKeyPress={(event) => {
                            if (!/[0.0-9.0]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div className="ml-1">{"%"}</div>
                    </div>

                    <div className="d-flex pl-2" style={{ width: "120px" }}>
                      <div className="mr-1">{"Max:"}</div>
                      <div className="ml-1">
                        <input
                          type="text"
                          className="form-control"
                          name="Specify_Max"
                          onChange={HandleInputChange}
                          onKeyPress={(event) => {
                            if (!/[0.0-9.0]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div className="ml-1">{"%"}</div>
                    </div>
                  </div>

                  <div>
                    {InputAlerts.isLimits ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        *Limits cannot be left blank!{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                style={
                  InputAlerts.isCushion
                    ? { border: "2px solid red", marginTop: "5px" }
                    : {}
                }
              >
                <div className="d-flex p-2">
                  <div>
                    <span> Input Desired cushion value : </span>
                  </div>{" "}
                  <div className="pl-2" style={{ width: "100px" }}>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        name="Input_Desired_Cushion_mm"
                        data-unitid={10}
                        onChange={HandleInputChange}
                        value={MMData.Input_Desired_Cushion_mm.value}
                        style={{ marginRight: "5px" }}
                      />
                      <span>mm</span>
                    </div>
                    <span>OR</span>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control"
                        name="Input_Desired_Cushion_in"
                        data-unitid={11}
                        onChange={HandleInputChange}
                        value={MMData.Input_Desired_Cushion_in.value}
                        style={{ marginRight: "5px" }}
                      />
                      <span>in</span>
                    </div>
                  </div>
                </div>

                <div>
                  {InputAlerts.isCushion ? (
                    <span className="pl-1" style={{ color: "red" }}>
                      {" "}
                      *Cushion value cannot be left blank!{" "}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {/* 3rd section */}
            <div>
              <div className="pt-2 pb-2 text-left">
                <div>
                  <button
                    className="btn btn-primary btn-air-primary m-2"
                    onClick={OpenFieldsDialog}
                  >
                    Define Compatibility Rules
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary btn-air-primary m-2"
                    onClick={DisplayCompatibility}
                  >
                    Display Compatibility
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          /*className="study-container"*/ id="Container"
          className="container-fluid"
        >
          <div className="d-flex justify-content-start">
            <div style={{ width: "100%" }} className="d-flex">
              {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
              <TabComponent
                ref={tabInstance}
                heightAdjustMode="Auto"
                id="defaultTab"
                selected={tabSelected}
              >
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                  <TabItemDirective header={headertext[2]} content={content2} />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>
        </div>
      </div>
      </div>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_units,
  get_user_units,
  get_materials,
  get_machines,
  get_molds,
  get_custom_fields,
  get_pps_data,
})(Database);
