import React, { useState, useRef, useEffect } from "react";
import ZoneGrid from "./ZoneGrid";
import EditHeader from "./EditHeader";
import ZoneAddColumn from "./ZoneAddColumn";
import {
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { nanoid } from "nanoid";
import { Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useParams } from "react-router-dom";
import ScrewMainGrid from "./ScrewMainGrid";
// Hook to get the parameter's from the URL
// Syncfusion chart control
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  StripLine,
  SplineSeries,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import AddRow from "./AddRow";
import { getCell } from "@syncfusion/ej2-react-spreadsheet";
import DataService from "../../../services/ApiService";
import { getRangeIndexes } from "@syncfusion/ej2-spreadsheet";

const ScrewRotation = ({
  setSession_Id,
  setSession_Name,
  setMoldId,
  setMoldName,
  ScrewData,
  showAlert,
  ToSaveScrewData,
}) => {
  
  let ZoneSpreadsheet = useRef();

  let ScrewRotationSpreadsheet = useRef();

  // To store the session Id getting from URL
  const [SessionId, setSessionId] = useState();

  // Getting session Id from URL
  var { sessionId, sessionName, moldId, moldName } = useParams();

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();
  const [modal2, setModal2] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  const [Acceptable_diff_Alert, setAcceptable_diff_Alert] = useState(true);

  const ToggleDiffAlert = () => {
    setAcceptable_diff_Alert(!Acceptable_diff_Alert);
  };

  const [DuplicateAlert, setDuplicateAlert] = useState(false);

  const ToggleDuplicateAlert = () => {
    setDuplicateAlert(!DuplicateAlert);
  };

  const [MeltTempAlert, setMeltTempAlert] = useState(true);

  const ToggleMeltAlert = () => {
    setMeltTempAlert(!MeltTempAlert);
  };

  // State and Event for the comment modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  const [Temp_Units, setTemp_Units] = useState("deg F");

  // To store column header data
  const [column, setColumn] = useState([
    {
      id: 0,
      header: "Nozzle",
    },
    {
      id: 1,
      header: "Zone 1",
    },
    {
      id: 2,
      header: "Zone 2",
    },
    {
      id: 3,
      header: "Zone 3",
    },
  ]);

  const [header, setHeader] = useState();

  const [ZoneGridData, setZoneGridData] = useState([
    {
      value0: "",
      value1: "",
      value2: "",
      value3: "",
    },
  ]);

  const [toggleEdit, setToggleEdit] = useState(true);

  const [isColumnId, setIsColumnId] = useState(null);

  const [Alert, setAlert] = useState(false);

  const [ColToBeDeleted, setColToBeDeleted] = useState();

  let [colCount, setColCount] = useState(column.length);

  const [ColHeader, seColHeader] = useState("");

  const [args, setArgs] = useState("");

  const PopulateZoneSheet = (data) => {
    const alphabetArray = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    for (let j = 1; j <= column.length; j++) {
      for (let i = 0; i < data.length; i++) {
        ZoneSpreadsheet.current.updateCell(
          { value: data[i][`value${j - 1}`] },
          `${alphabetArray[j - 1]}${i + 1}`
        );

        // console.log(data[i][`value${j - 1}`], `${alphabetArray[j - 1]}${i}`)
      }
    }
  };

  const RenderHeaders = () => {
    // Condition to check whether the rendered element is header cell.
    for (let i = 0; i < column.length; i++) {
      if (
        args.colIndex === i &&
        args.element.classList.contains("e-header-cell")
      ) {
        const text = column[i].header;
        args.element.innerText = text;
      }
    }

    ZoneSpreadsheet.current.refresh();
  };

  const addHeader = (e) => {
    e.preventDefault();

    setHeader(e.target.value);
  };

  const addColumn = () => {
    if (!header) {
    } else {
      const newColumn = { id: nanoid(), header: header };

      setColumn([...column, newColumn]);

      setColCount(colCount + 1);

      setHeader("");

      showAlert.current = true;

      setAlert(true);
    }
  };

  const editColumnHeader = () => {
    // console.log('Edited', header)

    if (header && !toggleEdit) {
      setColumn(
        column.map((element) => {
          if (element.id === isColumnId) {
            return { ...element, header: header };
          }
          return element;
        })
      );

      ZoneSpreadsheet.current.refresh();

      // RenderHeaders();

      setHeader("");

      showAlert.current = true;

      setAlert(true);
    } else {
    }
  };

  const editColumn = (id) => {
    setIsColumnId(id);
    setToggleEdit(false);
  };

  const editCancel = () => {
    setIsColumnId(null);
    setToggleEdit(true);
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    if (!ColToBeDeleted || column.length <= 1) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
      seColHeader(column[ColToBeDeleted].header);
    }
  };

  const getDatafromsheet = () => {
    let dataArray = [],
      dataObj = {};

    for (let i = 0; i < ZoneGridData.length; i++) {
      for (let j = 0; j < column.length; j++) {
        dataObj[`value${j}`] = !getCell(
          i,
          j,
          ZoneSpreadsheet.current.getActiveSheet()
        ).value
          ? ""
          : getCell(i, j, ZoneSpreadsheet.current.getActiveSheet()).value;
      }

      dataArray.push(dataObj);

      dataObj = {};
    }

    setZoneGridData(dataArray);

    // console.log(dataArray)
  };

  const deleteColumn = () => {
    ZoneSpreadsheet.current.delete(ColToBeDeleted, ColToBeDeleted, "Column");

    ZoneSpreadsheet.current.getActiveSheet().colCount = parseInt(colCount) - 1;

    setColCount(colCount - 1);

    setColumn((prevArray) => {
      const newArray = [...prevArray];

      newArray.splice(ColToBeDeleted, 1);

      return newArray;
    });

    for (let j = 1; j < column.length; j++) {
      for (let i = 0; i < ZoneGridData.length; i++) {
        delete ZoneGridData[i][`value${ColToBeDeleted}`];
        // console.log(ZoneGridData[i][`value${ColToBeDeleted}`])
      }
    }

    setTimeout(() => {

      getDatafromsheet();

      ZoneSpreadsheet.current.refresh();

      showAlert.current = true;

      setAlert(true);

    }, 500);

    setDeleteConfirmToggle(!DeleteConfirmToggle);

  };

  // ***************************************** Section 2 ****************************************
  // There is an input field which holds the value and is used for calculations wherever needed.
  const [MeltTemp, setMeltTemp] = useState(null);
  const [Acceptable_diff, setAcceptable_diff] = useState(0);
  const [ScrewRotationUnits, setScrewRotationUnits] = useState(null);

  const [ScrewRotationGridData, setScrewRotationGridData] = useState([]);

  const [rowCount, setRowCount] = useState(5);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  // As the user enter's the number of row's it get's set in this variable.
  const [row, setRow] = useState(5);

  // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Grid.
  const [ScrewAddRowModal, setScrewAddRowModal] = useState();

  const ToggleAddRowModal = () => {
    setScrewAddRowModal(!ScrewAddRowModal);
  };

  const PopulateScrewSheet = (CalcData) => {
    for (let i = 0; i < CalcData.length; i++) {
      ScrewRotationSpreadsheet.current.updateCell(
        { value: CalcData[i]["Screw Rotation Speed"] },
        `A${i + 1}`
      );
      ScrewRotationSpreadsheet.current.updateCell(
        { value: CalcData[i]["Actual Melt Temp"] },
        `B${i + 1}`
      );
      ScrewRotationSpreadsheet.current.updateCell(
        { value: CalcData[i]["Target"] },
        `C${i + 1}`
      );
      ScrewRotationSpreadsheet.current.updateCell(
        { value: CalcData[i]["Delta Temp"] },
        `D${i + 1}`
      );
    }
  };

  const CheckForScrewSpeed = (data) => {
    return new Promise((resolve, reject) => {
      const RotationSpeedSet = new Set();

      let hasDuplicate = false;

      for (const item of data) {
        const RotationSpeed = item["Screw Rotation Speed"];

        if (RotationSpeedSet.has(RotationSpeed) && RotationSpeed != null) {
          hasDuplicate = true;
          break;
        }

        RotationSpeedSet.add(RotationSpeed);
      }

      if (hasDuplicate) {
        reject();
      } else {
        resolve();
      }
    });
  };

  // This is the event to do the above said thing.
  const addRow = (e) => {
    e.preventDefault();

    // Storing the number entered
    setRow(e.target.value);
    // console.log(e.target.value);
  };

  // This is the event which gets called as the user click's ok in the add row modal.
  // what it does is it run's a loop as many times the row variable is and along with that it pushes an object containing all the key's based on the grid with an id generated using nanoid library and then set's the row1 in the main array i.e ScrewRotationGridData.
  const increaseRow = () => {
    // Updating the total rows variable
    setRowCount(parseInt(rowCount) + parseInt(row));

    showAlert.current = true;

    setAlert(true);

    setRow(null);
  };

  // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
  const deleteRow2 = (id) => {
    if (rowCount > 1) {
      ScrewRotationSpreadsheet.current.delete(
        rowToBeDeleted,
        rowToBeDeleted,
        "Row",
        0
      );

      setRowCount(parseInt(rowCount) - 1);

      ScrewRotationGridData.splice(rowToBeDeleted, 1);

      // console.log(ScrewRotationGridData)
      showAlert.current = true;

      setAlert(true);
    }
  };

  const handleMeltTemp = (e) => {
    e.preventDefault();

    if (e.target.value) {
      setMeltTemp(e.target.value);
      showAlert.current = true;
    } else {
      setMeltTemp(null);
    }
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
      showAlert.current = true;
    } else {
      setComment(e.value); // Get the RTE value
      showAlert.current = true;
      setAlert(true);
    }
  };

  const GetValues = () => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      // JavaScript
      const tdElements = document.querySelectorAll("#Screw_Sheet .e-cell");

      tdElements.forEach((td) => {
        const cellValue = td.textContent;

        if (cellValue) {
          TempArray.push(cellValue);
        } else {
          TempArray.push("");
        }
      });

      resolve(TempArray);

      // console.log(TempArray)
    });
  };

  const ConvertToJson = (DataArray) => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      let NumberOfRow = 0;

      for (let i = 0; i < Math.ceil(DataArray.length / 4); i++) {
        TempArray.push({
          "Screw Rotation Speed": DataArray[0 + NumberOfRow],
          "Actual Melt Temp": DataArray[1 + NumberOfRow],
          Target: MeltTemp ? MeltTemp : 0,
          "Delta Temp": "",
        });

        NumberOfRow = NumberOfRow + 4;
      }

      resolve(TempArray);

      // console.log(TempArray)
    });
  };

  const PerformCalcs = (TabelObjData) => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      for (let i = 0; i < TabelObjData.length; i++) {
        if (i === 0) {
          TempArray.push({
            "Screw Rotation Speed": TabelObjData[0]["Screw Rotation Speed"]
              ? TabelObjData[0]["Screw Rotation Speed"]
              : null,

            "Actual Melt Temp": TabelObjData[0]["Actual Melt Temp"]
              ? TabelObjData[0]["Actual Melt Temp"]
              : null,

            Target: TabelObjData[0]["Target"]
              ? TabelObjData[0]["Target"]
              : null,

            "Delta Temp": TabelObjData[i]["Screw Rotation Speed"]
              ? parseInt(TabelObjData[i]["Target"]) >
                parseInt(TabelObjData[i]["Actual Melt Temp"])
                ? TabelObjData[i]["Target"] -
                TabelObjData[i]["Actual Melt Temp"]
                : TabelObjData[i]["Actual Melt Temp"] -
                TabelObjData[i]["Target"]
              : "",
          });
        } else {
          TempArray.push({
            "Screw Rotation Speed": TabelObjData[i]["Screw Rotation Speed"]
              ? TabelObjData[i]["Screw Rotation Speed"]
              : null,

            "Actual Melt Temp": TabelObjData[i]["Actual Melt Temp"]
              ? TabelObjData[i]["Actual Melt Temp"]
              : null,

            Target: TabelObjData[i]["Screw Rotation Speed"]
              ? TabelObjData[i]["Target"]
              : "",

            "Delta Temp": TabelObjData[i]["Screw Rotation Speed"]
              ? parseInt(TabelObjData[i]["Target"]) >
                parseInt(TabelObjData[i]["Actual Melt Temp"])
                ? TabelObjData[i]["Target"] -
                TabelObjData[i]["Actual Melt Temp"]
                : TabelObjData[i]["Actual Melt Temp"] -
                TabelObjData[i]["Target"]
              : "",
          });
        }
      }

      resolve(TempArray);

      // console.log(TempArray)
    });
  };

  const getData = () => {
    // Getting the index of the selected row and storing in a variable
    let cell = ScrewRotationSpreadsheet.current.getActiveSheet().activeCell;

    let cellIdx = getRangeIndexes(cell);

    setRowToBeDeleted(cellIdx[0]);

    GetValues().then((DataArray) => {
      ConvertToJson(DataArray).then((TabelObjData) => {
        // console.log(TabelObjData)

        PerformCalcs(TabelObjData).then((CalcData) => {
          PopulateScrewSheet(CalcData);

          setScrewRotationGridData(CalcData);

          // console.log(CalcData)
        });
      });
    });
  };

  const [ScrewRotationArray, setScrewRotationArray] = useState([]);

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [ScrewniceNumbers, setScrewniceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [MeltNiceNumbers, setMeltNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const CheckForDuplicate = () => {
    CheckForScrewSpeed(ScrewRotationGridData)
      .then(() => {
        // console.log(ScrewRotationGridData)
        scaleGraph();
      })
      .catch(() => {
        ToggleDuplicateAlert();
        // console.log(ScrewRotationGridData)
      });
  };

  // Event to set the Min, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    if (ScrewRotationGridData.length > 0) {
      // console.log(ScrewRotationGridData)

      // To store the sorted Array
      let ScrewArray = [],
        MeltArray = [];

      // This is the event to sort the data based on Injection Speed
      const CompareInjectionSpeed = (a, b) => {
        return a["Screw Rotation Speed"] - b["Screw Rotation Speed"];
      };

      // This is the event to sort the data based on Viscosity
      const CompareViscosity = (a, b) => {
        return a["Actual Melt Temp"] - b["Actual Melt Temp"];
      };

      for (let i = 0; i < ScrewRotationGridData.length; i++) {
        if (ScrewRotationGridData[i]["Screw Rotation Speed"]) {
          ScrewArray.push(ScrewRotationGridData[i]);
          MeltArray.push(ScrewRotationGridData[i]);
        } else {
        }
      }

      // ScrewRotationGridData.sort(CompareInjectionSpeed);
      ScrewArray.sort(CompareInjectionSpeed);
      MeltArray.sort(CompareViscosity);

      setScrewRotationArray(ScrewArray);

      // console.log(MeltTemp)

      if (!MeltTemp) {
        ToggleMeltAlert();
      } else {
        if (!Acceptable_diff) {
          ToggleDiffAlert();
        }

        let Ymargin;

        if (
          parseFloat(MeltTemp) >
          parseFloat(MeltArray[ScrewArray.length - 1]["Actual Melt Temp"])
        ) {
          Ymargin =
            ((parseFloat(MeltTemp) -
              parseFloat(MeltArray[0]["Actual Melt Temp"])) /
              100) *
            30;
        } else {
          Ymargin =
            ((parseFloat(MeltArray[ScrewArray.length - 1]["Actual Melt Temp"]) -
              parseFloat(MeltArray[0]["Actual Melt Temp"])) /
              100) *
            30;
        }

        if (
          parseFloat(MeltTemp) >
          parseFloat(MeltArray[ScrewArray.length - 1]["Actual Melt Temp"])
        ) {
          setMeltNiceNumbers(
            calculateNiceNumbers(
              parseFloat(MeltArray[0]["Actual Melt Temp"]) -
              parseFloat(Ymargin),
              parseFloat(MeltTemp) +
              parseFloat(Ymargin) +
              parseFloat(Acceptable_diff),
              5
            )
          );
        } else {
          setMeltNiceNumbers(
            calculateNiceNumbers(
              parseFloat(MeltArray[0]["Actual Melt Temp"]) -
              parseFloat(Ymargin),
              parseFloat(MeltArray[ScrewArray.length - 1]["Actual Melt Temp"]) +
              parseFloat(Ymargin) +
              parseFloat(Acceptable_diff),
              5
            )
          );
        }

        let Xmargin =
          ((parseFloat(
            ScrewArray[ScrewArray.length - 1]["Screw Rotation Speed"]
          ) -
            parseFloat(ScrewArray[0]["Screw Rotation Speed"])) /
            100) *
          10;

        setScrewniceNumbers(
          calculateNiceNumbers(
            parseFloat(ScrewArray[0]["Screw Rotation Speed"]) -
            parseFloat(Xmargin),
            parseFloat(
              ScrewArray[ScrewArray.length - 1]["Screw Rotation Speed"]
            ) + parseFloat(Xmargin),
            5
          )
        );

        // console.log(ScrewRotationArray)
      }
    } else {
    }
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) scaleGraph();

    // console.log(ScrewRotationGridData);

    // eslint-disable-next-line
  }, [ScrewRotationGridData]);

  useEffect(() => {
    const data = {
      session: SessionId,
      Temp_Units: Temp_Units,
      MeltTemp: MeltTemp,
      Acceptable_diff: Acceptable_diff,
      ScrewRotationUnits: ScrewRotationUnits,
      ScrewRotationGridData: ScrewRotationGridData,
      ZoneGridData: ZoneGridData,
      column: column,
      Comment: Comment ? Comment : "N/A",
    };

    // console.log(data)

    showAlert.current = true;

    ScrewData.current = data;

    // eslint-disable-next-line
  }, [
    ScrewRotationGridData,
    ZoneGridData,
    Temp_Units,
    MeltTemp,
    Acceptable_diff,
    ScrewRotationUnits,
    Comment,
    column,
  ]);

  const FetchData = (SessionId) => {
    DataService.GetScrewRotationData(SessionId)
      .then((res) => {
        if (res.data) {
          ToSaveScrewData.current = false;

          setAcceptable_diff(res.data.Acceptable_diff);

          setComment(res.data.Comment);

          setMeltTemp(res.data.MeltTemp);

          setScrewRotationGridData(res.data.ScrewRotationGridData);

          setScrewRotationUnits(res.data.ScrewRotationUnits);

          // console.log(res.data.ScrewRotationGridData)

          setTemp_Units(res.data.Temp_Units);

          setZoneGridData(res.data.ZoneGridData);

          setColumn(res.data.column);

          // console.log(column);

          setColCount(res.data.column.length);
        } else {
          setScrewRotationGridData([]);
        }
      })
      .catch((err) => { });
  };

  // Event that will be called as soon as the Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));
    setSession_Name(atob(sessionName));
    setMoldId(moldId);
    setMoldName(moldName);

    setSession_Id(atob(sessionId));

    // console.log(Session_Name)

    if (SessionId) {
      FetchData(SessionId);
    } else {
    }

    // console.log(SessionId)

    // eslint-disable-next-line
  }, [sessionId, SessionId]);

  return (
    <div>
      <div>
        <Modal isOpen={!MeltTempAlert} centered={true}>
          <ModalHeader toggle={ToggleMeltAlert}>Target Melt Temp</ModalHeader>
          <ModalBody>
            <span> Please enter Target Melt Temperature. </span>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={ToggleMeltAlert}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={DuplicateAlert} centered={true}>
          <ModalHeader toggle={ToggleDuplicateAlert}>
            {" "}
            Screw Rotation Speed{" "}
          </ModalHeader>
          <ModalBody>
            <span> Screw Rotation Speed values cannot be duplicate. </span>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={ToggleDuplicateAlert}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={!Acceptable_diff_Alert} centered={true}>
          <ModalHeader toggle={ToggleDiffAlert}>Target Melt Temp</ModalHeader>
          <ModalBody>
            <span>
              {" "}
              The input value for "Acceptable diff in Temp" is missing. The
              graph will be plotted however the zone of the Acceptable band will
              not be plotted.{" "}
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={ToggleDiffAlert}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={DeleteAlertToggle} centered={true}>
          <ModalHeader toggle={DeleteModalAlertToggle}>
            Delete Alert.
          </ModalHeader>

          <ModalBody>
            {column.length > 2
              ? "Select a column to delete"
              : "Atleast one column is mandatory."}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={DeleteModalAlertToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={DeleteConfirmToggle} centered={true}>
          <ModalHeader toggle={DeleteModalConfirmToggle}>
            Delete Confirmation.
          </ModalHeader>

          <ModalBody>
            {ColHeader
              ? `Are you sure you want to delete ${ColHeader} column ?`
              : ""}
          </ModalBody>

          <ModalFooter>
            <Button color="dark" onClick={deleteColumn}>
              {" "}
              Delete{" "}
            </Button>
            <Button color="dark" onClick={DeleteModalConfirmToggle}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {/*************************** Section 1 which contains a drop down and a grid *****************************/}
      <div className="card meltPreparation p-3 ml-2">
        <div className="b-primary b-r-4 mb-2">
          <div className="pt-2 pb-2 pr-2 pl-1">

            <div className="screw-rotation-inputs b-primary b-r-4">
              <div className="m-2 d-flex col-md-3">
                <div>
                  <label htmlFor="Temp_Units" className="lbl_design">
                    {" "}
                    Temp Units:{" "}
                  </label>
                </div>

                <div>
                  <select
                    className="ml-2 form-control"
                    style={{ width: "150px" }}
                    onChange={(e) => setTemp_Units(e.target.value)}
                    name="Temp_Units"
                  >
                    <option> deg F </option>
                    <option> deg C </option>
                  </select>
                </div>
              </div>

              <div className="d-flex col-md-3">
                <div className="m-2">
                  <label className="lbl_design">Target Melt Temp:</label>
                </div>
                <div>
                  <input
                    className="form-control mt-2"
                    style={{ width: "150px" }}
                    onChange={handleMeltTemp}
                    onBlur={getData}
                    onMouseOver={() => setToPlotChart(false)}
                    name="Intensification_Ratio"
                    defaultValue={MeltTemp}
                    onKeyPress={(event) => {
                      if (!/[-0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="text"
                  />
                </div>
              </div>

              <div className="d-flex col-md-3">
                <div className="m-2">
                  <label className="lbl_design">
                    Acceptable diffin Temp:
                  </label>
                </div>
                <div>
                  <input
                    className="form-control mt-2"
                    style={{ width: "150px" }}
                    onChange={(e) =>
                      setAcceptable_diff(e.target.value ? e.target.value : 0)
                    }
                    name="Pressure_Units"
                    defaultValue={Acceptable_diff === 0 ? null : Acceptable_diff}
                    onKeyPress={(event) => {
                      if (!/[-0.0-9.0]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    type="text"
                  />
                </div>
              </div>

              <div className="d-flex col-md-3">
                <div className="m-2">
                  <label className="lbl_design">
                    Units of screw rotation speed:
                  </label>
                </div>
                <div>
                  <input
                    className="form-control mt-2"
                    style={{ width: "150px" }}
                    onChange={(e) => setScrewRotationUnits(e.target.value)}
                    name="Pressure_Units"
                    defaultValue={ScrewRotationUnits}
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex mt-2">
              <div className="col-md-8">


                <div className="d-flex mb-3">
                  <div>
                    <ZoneAddColumn
                      toggle2={toggle2}
                      modal2={modal2}
                      addHeader={addHeader}
                      addColumn={addColumn}
                    />
                  </div>
                  <div>
                    <EditHeader
                      modal={modal}
                      toggle={toggle}
                      column={column}
                      addHeader={addHeader}
                      editColumnHeader={editColumnHeader}
                      editCancel={editCancel}
                      editColumn={editColumn}
                      ZoneSpreadsheet={ZoneSpreadsheet}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-warning btn-air-warning mr-2"
                      type="button"
                      onClick={DeleteModalConfirmToggle}
                    >
                      {" "}
                      Delete Zone{" "}
                    </button>
                  </div>
                </div>

                <div>
                  <ZoneGrid
                    ZoneSpreadsheet={ZoneSpreadsheet}
                    column={column}
                    setColToBeDeleted={setColToBeDeleted}
                    ZoneGridData={ZoneGridData}
                    setZoneGridData={setZoneGridData}
                    colCount={colCount}
                    setColCount={setColCount}
                    setArgs={setArgs}
                    PopulateZoneSheet={PopulateZoneSheet}
                    Alert={Alert}
                    RenderHeaders={RenderHeaders}
                  />
                </div>



              </div>

              <div className="d-flex">

                <div className="col-md-4">



                </div>

              </div>



            </div>






          </div>

        </div>

        {/**************************** Section 2 contains input fields and main grid ***************************************/}
        <div className="b-primary b-r-4 mb-2 mt-2">

          <div className="pt-2 pb-2 pr-2 pl-1">

            <div className="d-flex col-md-12 mb-2">


              <div>
                <button
                  className="btn btn-info btn-air-info mr-2"
                  type="button"
                  onClick={ToggleAddRowModal}
                >
                  {" "}
                  Add Row{" "}
                </button>
              </div>

              <div>
                <button
                  className="btn btn-warning btn-air-warning mr-2"
                  type="button"
                  onClick={deleteRow2}
                >
                  {" "}
                  Delete Row{" "}
                </button>
              </div>

              <div>
                <AddRow
                  ToggleAddRowModal={ToggleAddRowModal}
                  ScrewAddRowModal={ScrewAddRowModal}
                  addRow={addRow}
                  increaseRow={increaseRow}
                />
              </div>

              <div>
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  type="button"
                  onClick={handleShow}
                >
                  Comment
                </button>
              </div>

              <div>
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  onClick={CheckForDuplicate}
                >
                  {" "}
                  Show Graph{" "}
                </button>

                <Modal isOpen={show} centered>
                  <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                  <ModalBody>
                    <RichTextEditorComponent
                      change={getComment}
                      value={Comment}
                      saveInterval="1"
                      toolbarSettings={toolbarSettings}
                      height={250}
                    >
                      <Inject services={[Toolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="dark" onClick={handleClose}>
                      {" "}
                      Save & Close{" "}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>

              <div>
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  type="button"
                  onClick={handleShow}
                >
                  Save As
                </button>
              </div>


            </div>


          </div>


          <div>

            <div>

              <div className="d-flex">
                <div className="mt-2 mb-2 ml-2 mr-0 col-md-6" onClick={() => setToPlotChart(false)} style={{ width: "50%" }}>
                  <ScrewMainGrid
                    ScrewRotationSpreadsheet={ScrewRotationSpreadsheet}
                    MeltTemp={MeltTemp}
                    ScrewRotationGridData={ScrewRotationGridData}
                    setScrewRotationGridData={setScrewRotationGridData}
                    rowCount={rowCount}
                    setRowCount={setRowCount}
                    setRowToBeDeleted={setRowToBeDeleted}
                    PopulateScrewSheet={PopulateScrewSheet}
                    Acceptable_diff={Acceptable_diff}
                    getData={getData}
                  />
                </div>

                <div className="mt-2 mb-2 col-md-6" style={{ width: "50%" }}>
                  <ChartComponent
                    className="screwChart"
                    width="100%"
                    height="350"
                    border={{ width: 1, color: "darkblue" }}
                    tooltip={{ enable: true }}
                    title="Melt Temperature vs Screw Rotation Speed"
                    primaryXAxis={{
                      title: `Screw Rotation Speed (${ScrewRotationUnits})`,
                      minimum: ScrewniceNumbers.min,
                      maximum: ScrewniceNumbers.max,
                      interval: ScrewniceNumbers.step,
                    }}
                    primaryYAxis={{
                      title: `Melt Temperature [${Temp_Units}]`,
                      minimum: MeltNiceNumbers.min,
                      maximum: MeltNiceNumbers.max,
                      interval: MeltNiceNumbers.step,
                      stripLines:
                        MeltTemp && Acceptable_diff
                          ? [
                            {
                              start: parseInt(MeltTemp) - parseInt(Acceptable_diff),
                              end: parseInt(MeltTemp) + parseInt(Acceptable_diff),
                              color: "#99ff99",
                              opacity: 0.5,
                              visible: true,
                            },
                          ]
                          : null,
                    }}
                  >
                    <Inject
                      services={[
                        LineSeries,
                        SplineSeries,
                        Category,
                        DataLabel,
                        StripLine,
                        Tooltip,
                      ]}
                    />

                    <SeriesCollectionDirective>
                      <SeriesDirective
                        dataSource={ScrewRotationArray}
                        type="Line"
                        xName="Screw Rotation Speed"
                        yName="Target"
                        fill="rgb(34,139,34)"
                        width={2.5}
                      ></SeriesDirective>
                      <SeriesDirective
                        dataSource={ScrewRotationArray}
                        type="Line"
                        xName="Screw Rotation Speed"
                        yName="Actual Melt Temp"
                        // name="Spline Series"
                        marker={{ visible: true }}
                        fill="rgb(255,0,0)"
                        width={2}
                      ></SeriesDirective>
                    </SeriesCollectionDirective>
                  </ChartComponent>
                </div>
              </div>



            </div>


          </div>

        </div>

        {/**************************** Section 2 contains input fields and main grid ***************************************/}
        {/* <div className="card" style={{ border: "1px solid #573DAC" }}>
        <div className="d-flex justify-content-between m-1">
          <div>
            <button
              className="btn btn-secondary btn-air-secondary mr-4"
              type="button"
              onClick={ToggleAddRowModal}
            >
              {" "}
              Add Row{" "}
            </button>
            <button
              className="btn btn-secondary btn-air-secondary mr-4"
              type="button"
              onClick={deleteRow2}
            >
              {" "}
              Delete Row{" "}
            </button>
            <AddRow
              ToggleAddRowModal={ToggleAddRowModal}
              ScrewAddRowModal={ScrewAddRowModal}
              addRow={addRow}
              increaseRow={increaseRow}
            />
          </div>

          <div>
            <button
              className="btn btn-primary btn-air-primary mr-2"
              type="button"
              onClick={handleShow}
            >
              Comment
            </button>

            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={CheckForDuplicate}
            >
              {" "}
              Show Graph{" "}
            </button>

            <Modal isOpen={show} centered>
              <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
              <ModalBody>
                <RichTextEditorComponent
                  change={getComment}
                  value={Comment}
                  saveInterval="1"
                  toolbarSettings={toolbarSettings}
                  height={250}
                >
                  <Inject services={[Toolbar, HtmlEditor]} />
                </RichTextEditorComponent>
              </ModalBody>
              <ModalFooter>
                <Button color="dark" onClick={handleClose}>
                  {" "}
                  Save & Close{" "}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        <div className="d-flex">
          <div className="m-2" onClick={() => setToPlotChart(false)}>
            <ScrewMainGrid
              ScrewRotationSpreadsheet={ScrewRotationSpreadsheet}
              MeltTemp={MeltTemp}
              ScrewRotationGridData={ScrewRotationGridData}
              setScrewRotationGridData={setScrewRotationGridData}
              rowCount={rowCount}
              setRowCount={setRowCount}
              setRowToBeDeleted={setRowToBeDeleted}
              PopulateScrewSheet={PopulateScrewSheet}
              Acceptable_diff={Acceptable_diff}
              getData={getData}
            />
          </div>

          <div className="m-2">
            <ChartComponent
              // width="700"
              height="350"
              border={{ width: 1, color: "darkblue" }}
              tooltip={{ enable: true }}
              title="Melt Temperature vs Screw Rotation Speed"
              primaryXAxis={{
                title: `Screw Rotation Speed (${ScrewRotationUnits})`,
                minimum: ScrewniceNumbers.min,
                maximum: ScrewniceNumbers.max,
                interval: ScrewniceNumbers.step,
              }}
              primaryYAxis={{
                title: `Melt Temperature [${Temp_Units}]`,
                minimum: MeltNiceNumbers.min,
                maximum: MeltNiceNumbers.max,
                interval: MeltNiceNumbers.step,
                stripLines:
                  MeltTemp && Acceptable_diff
                    ? [
                      {
                        start: parseInt(MeltTemp) - parseInt(Acceptable_diff),
                        end: parseInt(MeltTemp) + parseInt(Acceptable_diff),
                        color: "#99ff99",
                        opacity: 0.5,
                        visible: true,
                      },
                    ]
                    : null,
              }}
            >
              <Inject
                services={[
                  LineSeries,
                  SplineSeries,
                  Category,
                  DataLabel,
                  StripLine,
                  Tooltip,
                ]}
              />

              <SeriesCollectionDirective>
                <SeriesDirective
                  dataSource={ScrewRotationArray}
                  type="Line"
                  xName="Screw Rotation Speed"
                  yName="Target"
                  fill="rgb(34,139,34)"
                  width={2.5}
                ></SeriesDirective>
                <SeriesDirective
                  dataSource={ScrewRotationArray}
                  type="Line"
                  xName="Screw Rotation Speed"
                  yName="Actual Melt Temp"
                  // name="Spline Series"
                  marker={{ visible: true }}
                  fill="rgb(255,0,0)"
                  width={2}
                ></SeriesDirective>
              </SeriesCollectionDirective>
            </ChartComponent>
          </div>
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default ScrewRotation;
