let ReadOnlyDataObj = [
  { value: "Mold Temp Notes", cell: "A1", backgroundColor: "white" },
  { value: "Sections", cell: "B1", backgroundColor: "white" },
  { value: "Mold Temp Inputs", cell: "C1", backgroundColor: "white" },
  { value: "", cell: "A2" },
  { value: "", cell: "A3" },
  { value: "", cell: "A4" },
  { value: "3", cell: "B2", backgroundColor: "white" },
  { value: "2", cell: "B3", backgroundColor: "white" },
  { value: "1", cell: "B4" },
  { value: "", cell: "C2" },
  { value: "", cell: "C3" },
  { value: "", cell: "C4" },
  { value: "", cell: "D2" },
  { value: "", cell: "D3" },
  { value: "", cell: "D4" },
  { value: "Nozzle", cell: "F2" },
  { value: "H1", cell: "G2" },
  { value: "H2", cell: "H2" },
  { value: "H3", cell: "I2" },
  { value: "H4", cell: "J2" },
  { value: "H5", cell: "K2" },
  { value: "H6", cell: "L2" },
  { value: "H7", cell: "M2" },
  { value: "H8", cell: "N2" },
  { value: "H9", cell: "O2" },
  { value: "H10", cell: "P2" },
  { value: "", cell: "F3" },
  { value: "", cell: "G3" },
  { value: "", cell: "H3" },
  { value: "", cell: "I3" },
  { value: "", cell: "J3" },
  { value: "", cell: "K3" },
  { value: "", cell: "L3" },
  { value: "", cell: "M3" },
  { value: "", cell: "N3" },
  { value: "", cell: "O3" },
  { value: "", cell: "P3" },
  { value: "B-Side", cell: "C5" },
  { value: "A-Side", cell: "D5" },
  { value: "", cell: "C10" },
  { value: "", cell: "F11" },
  { value: "", cell: "F3" },
  { value: "", cell: "G3" },
  { value: "", cell: "H3" },
  { value: "", cell: "I3" },
  { value: "", cell: "J3" },
  { value: "", cell: "K3" },
  { value: "", cell: "L3" },
  { value: "", cell: "M3" },
  { value: "", cell: "N3" },
  { value: "", cell: "O3" },
  { value: "", cell: "P3" },
  { value: "B-Side", cell: "C5" },
  { value: "A-Side", cell: "D5" },
  { value: "", cell: "C10" },
  { value: "", cell: "F11" },
  { value: "", cell: "H11" },
  { value: "", cell: "J11" },
  { value: "", cell: "L11" },
  { value: "", cell: "N11" },
  { value: "", cell: "P11" },
  { value: "", cell: "R11" },
  { value: "", cell: "S2" },
  { value: "", cell: "S3" },
  { value: "Cooling Time", cell: "B10" },
  { value: "Sec", cell: "D10" },
  { value: "Cushion*", cell: "F10" },
  { value: "Transfer*", cell: "H10" },
  { value: "Posn 4", cell: "J10" },
  { value: "Posn 3", cell: "L10" },
  { value: "Posn 2", cell: "N10" },
  { value: "Posn 1", cell: "P10" },
  { value: "Shot Size*", cell: "R10" },
  { value: "Decompression", cell: "R1" },
  { value: "Distance:", cell: "R2" },
  { value: "Speed:", cell: "R3" },
  { value: "Pack & Hold Phase", cell: "A14", backgroundColor: "white" },
  { value: "Pack & Hold Phase", cell: "B14", backgroundColor: "white" },
  { value: "Pack & Hold Phase", cell: "C14", backgroundColor: "white" },
  { value: "Pack & Hold Phase", cell: "D14", backgroundColor: "white" },
  { value: "Pack & Hold Phase", cell: "E14", backgroundColor: "white" },
  { value: "Pack & Hold Phase", cell: "F14", backgroundColor: "white" },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "H14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "I14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "J14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "K14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "L14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "M14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "N14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "O14",
    backgroundColor: "white",
  },
  {
    value: "Injection Phase (Max. Inj. Speed)",
    cell: "P14",
    backgroundColor: "white",
  },
  {
    value: "Barrel Settings Temperatures (image not to scale)",
    cell: "F1",
    backgroundColor: "white",
  },
  { value: "", cell: "A15" },
  { value: "", cell: "B15" },
  { value: "", cell: "C15" },
  { value: "", cell: "D15" },
  { value: "", cell: "E15" },
  { value: "", cell: "F15" },
  { value: "", cell: "A17" },
  { value: "", cell: "B17" },
  { value: "", cell: "C17" },
  { value: "", cell: "D17" },
  { value: "", cell: "E17" },
  { value: "", cell: "F17" },
  { value: "", cell: "A19" },
  { value: "", cell: "B19" },
  { value: "", cell: "C19" },
  { value: "", cell: "D19" },
  { value: "", cell: "E19" },
  { value: "", cell: "F19" },
  { value: "", cell: "H15" },
  { value: "", cell: "J15" },
  { value: "", cell: "L15" },
  { value: "", cell: "N15" },
  { value: "", cell: "P15" },
  { value: "", cell: "H17" },
  { value: "", cell: "J17" },
  { value: "", cell: "L17" },
  { value: "", cell: "N17" },
  { value: "", cell: "P17" },
  { value: "", cell: "R17" },
  { value: "", cell: "R15" },
  { value: "", cell: "S15" },
  { value: "", cell: "R15" },
  { value: "P/H Vel 6", cell: "A16", backgroundColor: "white" },
  { value: "P/H Vel 5", cell: "B16", backgroundColor: "white" },
  { value: "P/H Vel 4", cell: "C16", backgroundColor: "white" },
  { value: "P/H Vel 3", cell: "D16", backgroundColor: "white" },
  { value: "P/H Vel 2", cell: "E16", backgroundColor: "white" },
  { value: "P/H Vel 1", cell: "F16", backgroundColor: "white" },
  { value: "P/H Time 6", cell: "A20", backgroundColor: "white" },
  { value: "P/H Time 5", cell: "B20", backgroundColor: "white" },
  { value: "P/H Time 4", cell: "C20", backgroundColor: "white" },
  { value: "P/H Time 3", cell: "D20", backgroundColor: "white" },
  { value: "P/H Time 2", cell: "E20", backgroundColor: "white" },
  { value: "P/H Time 1", cell: "F20", backgroundColor: "white" },
  { value: "Inj Vel 5", cell: "H16", backgroundColor: "white" },
  { value: "Inj Vel 4", cell: "J16", backgroundColor: "white" },
  { value: "Inj Vel 3", cell: "L16", backgroundColor: "white" },
  { value: "Inj Vel 2", cell: "N16", backgroundColor: "white" },
  { value: "Inj Vel 1", cell: "P16", backgroundColor: "white" },
  { value: "Screw Rot Speed*", cell: "R16" },
  { value: "Inj Press 5", cell: "H18", backgroundColor: "white" },
  { value: "Inj Press 4", cell: "J18", backgroundColor: "white" },
  { value: "Inj Press 3", cell: "L18", backgroundColor: "white" },
  { value: "Inj Press 2", cell: "N18", backgroundColor: "white" },
  { value: "Inj Press 1", cell: "P18", backgroundColor: "white" },
  { value: "Back Pressure*", cell: "R18" },
  { value: "P/H Press 6", cell: "A18", backgroundColor: "white" },
  { value: "P/H Press 5", cell: "B18", backgroundColor: "white" },
  { value: "P/H Press 4", cell: "C18", backgroundColor: "white" },
  { value: "P/H Press 3", cell: "D18", backgroundColor: "white" },
  { value: "P/H Press 2", cell: "E18", backgroundColor: "white" },
  { value: "P/H Press 1", cell: "F18", backgroundColor: "white" },
  { value: "", cell: "G10", backgroundColor: "white" },
  { value: "", cell: "I10", backgroundColor: "white" },
  { value: "", cell: "K10", backgroundColor: "white" },
  { value: "", cell: "M10", backgroundColor: "white" },
  { value: "", cell: "O10", backgroundColor: "white" },
  { value: "", cell: "Q10", backgroundColor: "white" },
  { value: "", cell: "I18", backgroundColor: "white" },
  { value: "", cell: "K18", backgroundColor: "white" },
  { value: "", cell: "M18", backgroundColor: "white" },
  { value: "", cell: "O18", backgroundColor: "white" },
  { value: "Process Inputs", cell: "A6", backgroundColor: "white" },
  {
    value: "The Fields marked with * are mandatory",
    cell: "A7",
    backgroundColor: "white",
  },
];

const CellsWithWhiteBg = [
  { cell: "A5" },
  { cell: "B5" },
  { cell: "A8" },
  { cell: "B8" },
  { cell: "A9" },
  { cell: "A11" },
  { cell: "A10" },
  { cell: "A12" },
  { cell: "B11" },
  { cell: "C11" },
  { cell: "D11" },
  { cell: "E11" },
  { cell: "E10" },
  { cell: "E9" },
  { cell: "E4" },
  { cell: "F4" },
  { cell: "F9" },
  { cell: "G9" },
  { cell: "H9" },
  { cell: "I9" },
  { cell: "J9" },
  { cell: "K9" },
  { cell: "L9" },
  { cell: "M9" },
  { cell: "N9" },
  { cell: "O9" },
  { cell: "P9" },
  { cell: "E1" },
  { cell: "E2" },
  { cell: "E3" },
  { cell: "Q1" },
  { cell: "Q2" },
  { cell: "Q3" },
  { cell: "S5" },
  { cell: "S6" },
  { cell: "S7" },
  { cell: "S8" },
  { cell: "S9" },
  { cell: "B12" },
  { cell: "C12" },
  { cell: "D12" },
  { cell: "E12" },
  { cell: "F12" },
  { cell: "A13" },
  { cell: "B13" },
  { cell: "C13" },
  { cell: "D13" },
  { cell: "E13" },
  { cell: "F13" },
  { cell: "G13" },
  { cell: "H13" },
  { cell: "I13" },
  { cell: "J13" },
  { cell: "K13" },
  { cell: "L13" },
  { cell: "M13" },
  { cell: "N13" },
  { cell: "O13" },
  { cell: "P13" },
  { cell: "Q13" },
  { cell: "R13" },
  { cell: "S13" },
  { cell: "G4" },
  { cell: "S4" },
  { cell: "I5" },
  { cell: "Q9" },
  { cell: "I6" },
  { cell: "I7" },
  { cell: "I8" },
  { cell: "R9" },
  { cell: "A22" },
  { cell: "S10" },
  { cell: "S11" },
  { cell: "S12" },
  { cell: "R12" },
  { cell: "S14" },
  { cell: "Q14" },
  { cell: "Q16" },
  { cell: "Q17" },
  { cell: "Q18" },
  { cell: "Q15" },
  { cell: "I15" },
  { cell: "I16" },
  { cell: "I17" },
  { cell: "I18" },
  { cell: "k15" },
  { cell: "k16" },
  { cell: "k17" },
  { cell: "k18" },
  { cell: "M15" },
  { cell: "M16" },
  { cell: "M17" },
  { cell: "M18" },
  { cell: "O15" },
  { cell: "O16" },
  { cell: "O17" },
  { cell: "O18" },
  { cell: "G14" },
  { cell: "G15" },
  { cell: "G16" },
  { cell: "G17" },
  { cell: "G18" },
  { cell: "G21" },
  { cell: "G19" },
  { cell: "A21" },
];

export default {
  ReadOnlyDataObj, CellsWithWhiteBg }