import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../../../assets/custom-stylesheet/grid_stylecss.css";

const QuantitativeGrid = ({
  Low,
  SpinnerData,
  NumericalPrediction,
  setPredictedValues,
  GoldSpotSetting,
 
}) => {
  const [SpinnerValues, setSpinnerValues] = useState([]);
  const [DataValues, setDataValues] = useState([]);

  const Headerkeys = Array.from(
    new Set(SpinnerData?.flatMap((item) => Object.keys(item)))
  );

  const lowValues = Low?.flatMap((item) => Object.values(item));

  useEffect(() => {
    let initialDataValues = SpinnerData?.flatMap((item) => Object.values(item));
    if (GoldSpotSetting) {
      const updatedDataValue = initialDataValues.map((innerArray, index) => {
        const updatedArray = [...innerArray];
        updatedArray[0] = GoldSpotSetting[index];
        return updatedArray;
      });
      initialDataValues = updatedDataValue;
    }

   
    setDataValues(initialDataValues);
  }, [SpinnerData, GoldSpotSetting]);

  const handleIncrement = (valueArray, index) => {
   

    let TempArray = [],
      PredictedValuesArray = [];

    if (valueArray[0] < valueArray[1]) {
      const newDataValues = [...DataValues];
      newDataValues[index][0] = newDataValues[index][0] + newDataValues[index][2];
      setDataValues(newDataValues);

      for (let i = 0; i < newDataValues.length; i++) {
        TempArray.push(newDataValues[i][0]);
      }

      setSpinnerValues(TempArray);

      for (let i = 0; i < NumericalPrediction.dimension.length; i++) {
        let index = NumericalPrediction.dimension[i].combination.findIndex(
          (arr) => {
            return arr.every((val, i) => val === TempArray[i]);
          }
        );

        PredictedValuesArray.push(
          NumericalPrediction.dimension[i].dimension[index]
        );
      }

      setPredictedValues(PredictedValuesArray);
    }
  };

  const handleDecrement = (valueArray, index) => {
    // setChangeValue(false);

    let TempArray = [],
      PredictedValuesArray = [];

    if (lowValues[index][0] < valueArray[0]) {
      const newDataValues = [...DataValues];
      newDataValues[index][0] = newDataValues[index][0] - newDataValues[index][2];
      setDataValues(newDataValues);

      for (let i = 0; i < newDataValues.length; i++) {
        TempArray.push(newDataValues[i][0]);
      }

      setSpinnerValues(TempArray);

      for (let i = 0; i < NumericalPrediction.dimension.length; i++) {
        let index = NumericalPrediction.dimension[i].combination.findIndex(
          (arr) => {
            return arr.every((val, i) => val === TempArray[i]);
          }
        );

        PredictedValuesArray.push(
          NumericalPrediction.dimension[i].dimension[index]
        );
      }

      setPredictedValues(PredictedValuesArray);

      // console.log(PredictedValuesArray)
    }
  };


  return (
    <div>
      <div
        className="spreadsheet"
        id="BackPress-Calc-Grid"
        style={{  width: "100%", height: "100px" }}
      >
       
                    <div className="b-primary">
                      <Table striped bordered hover responsive variant="light">
                        <thead>
                          <tr>
                            {Headerkeys?.map((key, index) => (
                              <th key={index}>{key.replace(/_/g, ' ')}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="grid_style">
                          <tr>
                            {DataValues?.map((key, index) => (
                              <td key={index}>
                                <div className="d-flex">
                                  <div>
                                    <input
                                      value={key[0]}
                                      style={{
                                        height: "25px"
                                      }}
                                      readOnly
                                    />
                                  </div>

                                  <div>
                                    <div style={{ fontSize: "8px" }}>
                                      <button
                                        onClick={() =>
                                          handleIncrement(key, index)
                                        }style={{ height: "11px" }}
                                      >
                                        {" "}
                                        &#9650;{" "}
                                      </button>
                                    </div>
                                    <div style={{ fontSize: "8px" }}>
                                      <button
                                        onClick={() =>
                                          handleDecrement(key, index)
                                        }style={{ height: "11px" }}
                                      >
                                        {" "}
                                        &#9660;{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
           
  );
};

export default QuantitativeGrid;
