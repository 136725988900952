import React from "react";

import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Category,
  StackingBarSeries,
  Tooltip,
  DataLabel,
  Highlight,
  StripLine,
  AxesDirective,
  AxisDirective,
  ColumnSeries,
  LineSeries,
} from "@syncfusion/ej2-react-charts";

const TornadoCalculation = ({ plus_values, minus_values, response }) => {
  const variableArray = [];

  const findInteractions = (plusValues, minusValues) => {
    variableArray.length = 0;

    const interactions = {};

    const effect = {};

    const abs = {};

    let sum_abs = 0;

    for (const plusEntry of plusValues) {
      const plusKey = plusEntry[0];

      const minusKey = plusKey.replace("_plus", "_minus");

      const correspondingEntry = minusValues.find(
        (entry) => entry[0] === minusKey
      );

      if (correspondingEntry) {
        const key = plusKey.split("_plus")[0]; // Extracting the variable names
        // console.log(key)
        variableArray.push(key);

        const value = [plusEntry[1], correspondingEntry[1]]; // Pairing the values
        // console.log(value)

        const effect_value = plusEntry[1] - correspondingEntry[1];

        //computing absolute factor
        const abs_factor = Math.abs(effect_value);

        sum_abs += abs_factor;

        interactions[key] = value;

        effect[key] = effect_value;

        abs[key] = abs_factor;
      }
    }

    return { interactions, effect, abs, sum_abs };
  };

  // Function to find percentages
  const interactions = findInteractions(plus_values, minus_values);

  const findPercentage = (effect, sumAbs) => {
    // console.log("effect",effect)
    const percentages = {};
    const abs_percentages = {};

    for (const key in effect) {
      if (effect.hasOwnProperty(key)) {
        const effectValue = effect[key];
        const percentage = (effectValue / sumAbs) * 100;
        const abs_percent = Math.abs(percentage);
        percentages[key] = percentage;
        abs_percentages[key] = abs_percent;
      }
    }

    return { percentages, abs_percentages };
  };

  const percentages = findPercentage(interactions.effect, interactions.sum_abs);

  // Sorting percentages by absolute value
  const absPercentagesArray = Object.entries(percentages.abs_percentages);
  absPercentagesArray.sort((a, b) => b[1] - a[1]);
  const sortedAbsPercentages = Object.fromEntries(absPercentagesArray);

  // Reordering percentages
  const reorderedPercentages = {};
  Object.keys(sortedAbsPercentages).forEach((key) => {
    reorderedPercentages[key] = percentages.percentages[key];
  });

  // Array to store data for plotting
  const data_plot = [];

  // #87CEFA=>blue
  // #FF6F6F => pink/red/whatever

  // Mapping factor names to letters
  const factorMapping = {};
  const factors = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  // Identify unique factor names and assign them letters
  const uniqueFactors = new Set();
  Object.keys(reorderedPercentages).forEach((key) => {
    const parts = key.split("*");
    parts.forEach((part) => uniqueFactors.add(part));
  });

  // Create object mapping factor names to letters
  let factorIndex = 0;
  uniqueFactors.forEach((factor) => {
    factorMapping[factor] = factors[factorIndex++];
  });

  // console.log("reorderedPercentages", reorderedPercentages);
  // Map keys to their corresponding letters
  const newKeys = {};
  Object.keys(reorderedPercentages).forEach((key) => {
    const parts = key.split("*");
    const newKey = parts.map((part) => factorMapping[part]).join("*");
    newKeys[newKey] = reorderedPercentages[key];
  });

  // Construct data for plotting
  for (const key in reorderedPercentages) {
    if (reorderedPercentages.hasOwnProperty(key)) {
      const percentage = reorderedPercentages[key];
      const color = percentage < 0 ? "#FF6F6F" : "#87CEFA"; // Determine color based on value
      let factorText;
      if (key in factorMapping) {
        factorText = factorMapping[key];
      } else {
        factorText = key
          .split("*")
          .map((part) => factorMapping[part])
          .join("*");
      }
      // console.log("Factor Text:", factorText);

      const item = {
        x: factorText,
        y: percentage,
        text: Math.abs(percentage).toFixed(2).toString() + "%",
        color,
      };
      data_plot.push(item);
    }
  }

  data_plot.reverse();

  // Get the highest absolute percentage factor
  const highestAbsPercentageFactor = Object.keys(sortedAbsPercentages)[0];

  const [plusValue, minusValue] = interactions?.interactions?.[
    highestAbsPercentageFactor
  ] ?? [0, 0];
  const totalChange = Math.abs(plusValue - minusValue);

  // Generate messages
  const message1 = `Total dimension change that can be made in this dimension is ${totalChange.toFixed(
    5
  )}. \n\n The dimension can be changed from ${minusValue.toFixed(
    5
  )} to ${plusValue.toFixed(
    5
  )} units by changing the factors considered in DOE.`;

  const note = ` Note:To check the effect of changes in the value of factors at high and low values, please use the Visual Predictions tab.`;

  // console.log(variableArray);

  function textRender(args) {
    args.text =
      args.text.indexOf("-") > 0 ? args.text.replace("-", "") : args.text;
  }

  function labelRender(args) {
    if (args.value < 0) {
      args.text = (-args.value).toString();
    }
  }

  // Function to generate statements
  const statement = () => {
    const message2 = [];
    for (const factorKey in factorMapping) {
      if (factorMapping.hasOwnProperty(factorKey)) {
        // const factorValue = factorMapping[factorKey];
        const percentage = Math.abs(reorderedPercentages[factorKey]);
        const effectValue = Math.abs(interactions.effect[factorKey]);
        if (percentage > 5) {
          const messsage = `When all the factors are held at center value, a change in ${factorKey.replace(/_/g," ")} will increase/decrease the dimension by ${percentage.toFixed(
            2
          )}% or ${effectValue.toFixed(5)} units.`;
          message2.push(messsage);
        }
      }
    }
    return message2;
  };

  const blueNote = statement();

  // Function to render the chart
  const NegativeStack = () => {
    const onChartLoad = (args) => {
      let chart = document.getElementById("charts");
      chart.setAttribute("title", "");
    };

    return (
      <>
        <ChartComponent
          id="charts"
          title={`Tornado Chart for Response: ${
            response.charAt(0).toUpperCase() + response.slice(1)
          }`}
          style={{ textAlign: "center" }}
          border={{ width: 1, color: "darkblue" }}
          width="100%"
          primaryXAxis={{
            valueType: "Category",
            title: "Factors",
            majorTickLines: { width: 0 },
            interval: 1,
            lineStyle: { color: "black" },
          }}
          chartArea={{ border: { width: 1 } }}
          primaryYAxis={{
            lineStyle: { color: "black" },
            majorTickLines: { width: 0 },
            minimum: -100,
            maximum: 100,
            interval: 50,
            edgeLabelPlacement: "Shift",
            stripLines: [
              { start: 0, end: 0.25, zIndex: "Over", color: "black" },
            ],
          }}
          loaded={onChartLoad.bind(this)}
          tooltipRender={textRender.bind(this)}
          axisLabelRender={labelRender.bind(this)}
          tooltip={{ enable: true }}
        >
          <Inject
            services={[
              StackingBarSeries,
              DataLabel,
              Category,
              Tooltip,
              Highlight,
              StripLine,
              ColumnSeries,
              LineSeries,
            ]}
          />

          <AxesDirective>
            <AxisDirective
              rowIndex={1}
              name="yAxis1"
              opposedPosition={true}
              majorGridLines={{ width: 0 }}
              lineStyle={{ width: 0 }}
            />
            <AxisDirective
              rowIndex={1}
              name="yAxis2"
              opposedPosition={true}
              majorGridLines={{ width: 0 }}
              lineStyle={{ width: 0 }}
              rangePadding="None"
              minimum={0}
              maximum={100}
            />
          </AxesDirective>

          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={data_plot}
              width={2}
              animation={{ enable: false }}
              pointColorMapping="color"
              xName="x"
              yName="y"
              columnWidth={0.5}
              type="StackingBar"
              border={{ width: 1, color: "black" }}
              marker={{
                dataLabel: {
                  name: "text",
                  visible: true,
                  position: "Outer",
                  font: { fontWeight: "600" },
                },
              }}
              // yAxisName="yAxis1"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </>
    );
  };

  return (
    <>
      <div className="d-flex">
        <div className="m-1 col-md-8">
          <h6>Tornado Chart</h6>
          <>{NegativeStack()}</>
        </div>

        <div className="col-md-4">
          <div className="m-1">
            {Object.entries(factorMapping).map(([key, value]) => (
              <div key={key}>
                {value} = {key.replace(/_/g, " ")}
              </div>
            ))}

            <div
              style={{
                fontSize: "1.2rem",
                border: "1px solid grey",
                backgroundColor: "#ffbb99",
              }}
            >
              <div className="m-2">
                {message1}
                <br />
                <br />
                {note}
              </div>
            </div>

            <div>
            
              {blueNote.map((note, index) => (
                <div
                  key={index}
                  style={{
                    fontSize: "1.2rem",
                    border: "1px solid grey",
                    backgroundColor: "#87CEFA",
                    margin: "10px 0", // Added some margin between divs for better spacing
                  }}
                >
                  <div className="m-2">{note}</div>
                </div>
              ))}
            </div>

            {/* {blueNote.map((statement, index) => (
              <div key={index}>{statement}</div>
            ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TornadoCalculation;
