import {
    Home,
    FileText,
    Percent,
    DivideSquare,
    Database,
    Inbox, Package, FilePlus, Award, Settings
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/database/Database', title: 'Database', icon: Database, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/compatibility/Compatible', title: 'Compatibility', icon: Inbox, type: 'link', badgeType: 'primary', active: false
    },
    {
         title: 'Mold', icon: Package, type: 'sub', badgeType: 'primary', active: false, children: [

            
            {
                path: '/dashboard/mold', title: 'MoldList', icon: FilePlus, type: 'link'
            },

            {
                path: '/dashboard/meltpreparation/sessions', title: 'Melt Preparation', icon: FilePlus, type: 'link'
            },
            {
                path: '/dashboard/session/', title: 'Six Step Study', icon: FilePlus, type: 'link'
            },
            {
                path: '/dashboard/DOE/doe-session', title: 'DOE', icon: FilePlus, type: 'link'
            },
            {
                path: '/dashboard/PPS/sessions', title: '11+2 Plasic Process Sheet', icon: FilePlus, type: 'link'
            },
            {
                path: '/dashboard/qualitydata/sessions', title: 'Quality Data', icon: FilePlus, type: 'link'
            },
            {
                path: '/dashboard/qualitydata/sessions', title: 'Water Auxillary hook up', icon: FilePlus, type: 'link'
            },
            {
                path: '/dashboard/qualitydata/sessions', title: 'Model Trouble Shooting', icon: FilePlus, type: 'link'
            },
          
            {
                path: '/dashboard/qualitydata/sessions', title: 'Documents', icon: FilePlus, type: 'link'
            },
        ]
    },
    
       
    {
        path: '/dashboard/equipmentqualification/sessions', title: 'Equipment Qualification', icon: Award, type: 'link', badgeType: 'primary', active: false
    },

    {
        path: '/dashboard/calculators/', title: 'Calculators', icon: Percent, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/optionnsettings/OptionSetting', title: 'Option & Settings', icon: Settings, type: 'link', badgeType: 'primary', active: false
    },
    {
        path: '/dashboard/conversions/', title: 'Conversions', icon: DivideSquare, type: 'link', badgeType: 'primary', active: false
    }
]
