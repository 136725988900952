import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const HopperSizeCalculator = ({ calculateResult }) => {

    const [hopperSizeData, setHopperSizeData] = useState({
        minDryingTime: '',
        maxDryingTime: '',
        shotWeight: '',
        cycleTime: '',
    });

    useEffect(() => {

        let flag = true;

        for (let key in hopperSizeData) {
            if (hopperSizeData[key] <= 0)
                flag = false;
        }

        if (flag) {
            calculateResult(hopperSizeData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hopperSizeData]);

    const handleChange = (event) => {

        setHopperSizeData(prevState => ({ ...prevState, [event.target.name]: event.target.value }));

    };

    return (
        <div>

            <div style={{ fontWeight: "bold", fontSize: '25px' }}>
                <span> Input </span>
            </div>

            <hr></hr>

            <Form>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Drying Time Min (Hours)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control name="minDryingTime" type="text" value={hopperSizeData.minDryingTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Drying Time Max (Hours)</Form.Label> </Col>
                        <Col sm={4}>
                            <Form.Control name="maxDryingTime" type="text" value={hopperSizeData.maxDryingTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Cycle Time (sec)</Form.Label> </Col>

                        <Col sm={4}>
                            <Form.Control name="cycleTime" type="text" value={hopperSizeData.cycleTime} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Shot Weight (gms) </Form.Label> </Col>

                        <Col sm={4}>
                            <Form.Control name="shotWeight" type="text" value={hopperSizeData.shotWeight} onChange={handleChange} min="0" onKeyPress={(event) => {
                                if (!/[0.0-9.0]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </div>
    )
}

export default HopperSizeCalculator