// Pressure
export function psi_To_MPa(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.00689476;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function MPa_To_psi(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 145.038;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function psi_To_bar(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.0689476;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function bar_To_psi(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 14.5038;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function MPa_To_bar(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 10;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function bar_To_MPa(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.1;
  return parseFloat(CalcValue).toFixed(Decimals);
}
