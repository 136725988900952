import React, { useState, useEffect } from "react";
import {
  Category,
  ChartComponent,
  ColumnSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Tooltip,
  ScrollBar,
  LineSeries,
  StripLine,
  AxesDirective,
  AxisDirective,
  StripLinesDirective,
  StripLineDirective,
  AnnotationsDirective,
  AnnotationDirective,
  DateTime,
  ChartAnnotation,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import "../Chart.css";

//Calculate nicenumbers for min,max,step,number of ticks
function calculateNiceNumbers(min, max, numTicks) {
  const range = max - min;
  const roughStep = range / numTicks;
  const magnitude = Math.floor(Math.log10(roughStep));
  const magnitudePow = Math.pow(10, magnitude);
  const mantissa = roughStep / magnitudePow;
  let niceStep;

  if (mantissa <= 1.0) {
    niceStep = 1 * magnitudePow;
  } else if (mantissa <= 2.0) {
    niceStep = 2.5 * magnitudePow;
  } else if (mantissa <= 5.0) {
    niceStep = 5 * magnitudePow;
  } else {
    niceStep = 6 * magnitudePow;
  }

  const niceMin = Math.floor(min / niceStep) * niceStep;
  const niceMax = Math.ceil(max / niceStep) * niceStep;

  const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
  const niceTicks = Array.from(
    { length: numNiceTicks },
    (_, i) => niceMin + i * niceStep
  );

  return {
    min: niceMin,
    max: niceMax,
    step: niceStep,
    ticks: niceTicks,
  };
}

function Chart({
  individualData,
  chartData,
  upperLimit,
  lowerLimit,
  average,
  responseName,
}) {
  const [isChartVisible, setChartVisible] = useState(true);

  const maxValue = chartData.length;

  const lines = { width: 0 };

  const nicenumData = [];

  //populate chartdata

  // console.log("chartData",chartData)

  chartData.forEach((data) => {
    nicenumData.push(data.y);
  });

  // Push upperlimit and lowerlimit
  nicenumData.push(upperLimit);
  nicenumData.push(lowerLimit);

  const primaryxAxis = {
    valueType: "Double",
    title: "Experiment Number",
    minimum: 1,
    maximum: maxValue,
    interval: 1,
    majorGridLines: {
      width: 0,
    },
    lineStyle: { color: "black" },
    labelPlacement: "onticks",
    stripLines: [
      {
        color: "Blue",
        visible: true,
        opacity: 0.5,
        zIndex: "Behind",
      },
    ],
  };

  const minChartDataValue = Math.min(...nicenumData);

  const maxChartDataValue = Math.max(...nicenumData);

  const noOfTicks = 5;

  const calNiceNum = calculateNiceNumbers(
    parseFloat(minChartDataValue),
    parseFloat(maxChartDataValue),
    noOfTicks
  );

  const primaryyaxis = {
    minimum: parseFloat(calNiceNum.min),
    maximum: parseFloat(calNiceNum.max),
    interval: parseFloat(calNiceNum.step),
    majorGridLines: {
      color: "tranparent",
    },
    lineStyle: { color: "black" },
  };

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      setChartVisible(true);
    } else {
      setChartVisible(false);
    }
  }, [chartData]);

  const xValues = chartData.map((_, index) => index + 1);

  // Create data points for upper limit, average and lower limit
  const data1 = xValues.map((x) => ({ x, y: upperLimit }));
  const data2 = xValues.map((x) => ({ x, y: average }));
  const data3 = xValues.map((x) => ({ x, y: lowerLimit }));

  const axisLabelRender = (args) => {
    if (args.axis.name === "yAxis1") {
      args.text = "";
    }
  };

  //find the placement of usl,lsl,avg on y axis in terms of % for plotting of the lines on secondary y axis
  const findpercentages = () => {
    const usl =
      ((upperLimit - calNiceNum.min) / (calNiceNum.max - calNiceNum.min)) * 100;
    const lsl =
      ((lowerLimit - calNiceNum.min) / (calNiceNum.max - calNiceNum.min)) * 100;
    const avg =
      ((average - calNiceNum.min) / (calNiceNum.max - calNiceNum.min)) * 100;

    const percentage = {
      usl: 100 - usl + "%",
      lsl: 100 - lsl + "%",
      avg: 100 - avg + "%",
    };
    // console.log(percentage)

    return percentage;
  };

  return (
    <>
      <div>
        {isChartVisible && (
          <ChartComponent
            id="charts"
            className="SensitivityChart"
            primaryXAxis={primaryxAxis}
            tooltip={{ enable: true }}
            primaryYAxis={primaryyaxis}
            title={`Process Sensitivity Graph for Response: ${responseName}`}
            axisLabelRender={axisLabelRender.bind(this)}
            height="400vh"
            width="95%"
          >
            {/* yAxisName='yAxis1' */}
            <Inject
              services={[
                ColumnSeries,
                Tooltip,
                Category,
                ScrollBar,
                LineSeries,
                StripLine,
                ChartAnnotation,
                DateTime,
                Highlight,
              ]}
            />
            <AnnotationsDirective>
              <AnnotationDirective
                content="UCL"
                region="Chart"
                coordinateUnits="Pixel"
                x="102%"
                y={findpercentages().usl}
              ></AnnotationDirective>
              <AnnotationDirective
                content="AVG"
                region="Chart"
                coordinateUnits="Pixel"
                x="102%"
                y={findpercentages().avg}
              ></AnnotationDirective>
              <AnnotationDirective
                content="LCL"
                region="Chart"
                coordinateUnits="Pixel"
                x="102%"
                y={findpercentages().lsl}
              ></AnnotationDirective>
            </AnnotationsDirective>

            <AxesDirective>
              <AxisDirective
                rowIndex={0}
                name="yAxis1"
                opposedPosition={true}
                majorGridLines={lines}
                lineStyle={lines}
                axisLabelRender={axisLabelRender.bind(this)}
                minimum={parseFloat(calNiceNum.min)}
                maximum={parseFloat(calNiceNum.max)}
                interval={parseFloat(calNiceNum.step)}
                majorTickLines={{ width: "0" }}
              >
                <StripLinesDirective>
                  <StripLineDirective
                    start={upperLimit}
                    end={upperLimit + 1}
                    // text="UCL"
                    color="yellow"
                    visible={true}
                    opacity={0.03}
                    zIndex="Over"
                    textStyle={{ fontWeight: "bold" }}
                    horizontalAlignment="End"
                  ></StripLineDirective>

                  <StripLineDirective
                    start={average}
                    end={average + 1}
                    // text="Avg"
                    color="transparent"
                    visible={true}
                    opacity={0.03}
                    zIndex="Over"
                    textStyle={{ fontWeight: "bold" }}
                    horizontalAlignment="End"
                  ></StripLineDirective>

                  <StripLineDirective
                    start={lowerLimit}
                    end={lowerLimit + 1}
                    // text="LCL"
                    color="transparent"
                    visible={true}
                    opacity={0.03}
                    zIndex="Over"
                    textStyle={{ fontWeight: "bold" }}
                    horizontalAlignment="End"
                  ></StripLineDirective>
                </StripLinesDirective>
              </AxisDirective>
            </AxesDirective>

            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={chartData}
                xName="x"
                yName="y"
                // name="Data"
                marker={{
                  visible: true,
                  width: 8,
                  height: 8,
                  fill: "Orange",
                  shape: "Diamond",
                }}
                fill="Blue"
                width={2}
                animation={{enable:false}}
              />

              <SeriesDirective
                dataSource={individualData}
                xName="x"
                yName="y"
                // name="Individual Points"
                type="Scatter"
                // fill="orange"
                marker={{
                  visible: true,
                  width: 3,
                  height: 3,
                  fill: "Black",
                  shape: "Square",
                }}
                animation={{enable:false}}
              />

              {/* Line for upper limit */}
              <SeriesDirective
                dataSource={data1}
                xName="x"
                yName="y"
                // name="Upper Limit"
                type="Line"
                fill="red"
                yAxisName="yAxis1"
                visible={true}
                width={3}
                animation={{enable:false}}
              />

              {/* Line for average */}
              <SeriesDirective
                dataSource={data2}
                xName="x"
                yName="y"
                // name="Average"
                type="Line"
                fill="green"
                yAxisName="yAxis1"
                visible={true}
                width={3}
                animation={{enable:false}}
              />

              {/* Line for lower limit */}
              <SeriesDirective
                dataSource={data3}
                xName="x"
                yName="y"
                // name="Lower Limit"
                type="Line"
                fill="red"
                yAxisName="yAxis1"
                visible={true}
                width={3}
                animation={{enable:false}}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
      </div>
    </>
  );
}

export default Chart;
