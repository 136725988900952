import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/esm/Row';

const MoldingCalculatorFormOutput = ({ result }) => {
    return (
        <div>

            <div style={{ fontWeight: "bold", fontSize: '25px' }}>
                <span> Results </span>
            </div>

            <hr></hr>

            <Form>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}> <Form.Label>Part Weight</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="partWeight" type="number" value={result.partWeight} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>gms=</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.partWeight * 0.035274).toFixed(2)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>oz</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}> <Form.Label>Runner Weight</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="runnerWeight" type="number" value={result.runnerWeight} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>gms=</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.runnerWeight * 0.035274).toFixed(2)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>oz</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}> <Form.Label>Shot Weight</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="shotWeight" type="number" value={result.shotWeight} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>gms=</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.shotWeight * 0.035274).toFixed(2)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>oz</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}> <Form.Label>Approximate Residence Time</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="approximateResidenceTime" type="number" value={Number(result.approximateResidenceTime).toFixed(1)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>min</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.approximateResidenceTime).toFixed(1) * 60} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>sec</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}>  <Form.Label>% Usage of Barrel</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="percentUsageOfBarrel" type="number" value={Number(result.percentUsageOfBarrel).toFixed(1)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>%</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}> <Form.Label>Required Tonnage</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="requiredTonnage" type="number" value={result.requiredTonnage} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label> tons </Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}> <Form.Label>Parts per hour</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="partsPerHour" type="number" value={result.partsPerHour} readOnly />
                        </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}>
                            <Form.Label>Parts per 8 hours</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="partsPerEightHour" type="number" value={result.partsPerEightHour} readOnly />
                        </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}>
                            <Form.Label>Parts per 24 hours</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="partsPerTwentyFourHour" type="number" value={result.partsPerTwentyFourHour} readOnly />
                        </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}>
                            <Form.Label>Time to mold 100 parts</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="moldTime" type="number" value={Number(result.moldTime * 0.0166667).toFixed(1)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>hours</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.moldTime).toFixed(1)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>mins</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">

                    <Row>

                        <Col sm={3}>
                            <Form.Label>Material required per hour</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="materialPerHour" type="number" value={Number(((result.materialPerHour * 2.20462) / 1000)).toFixed(3)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>lbs</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.materialPerHour).toFixed(3) / 1000} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>kg</Form.Label> </Col>

                    </Row>

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={3}>

                            <Form.Label>Material required per 8 hours</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="materialPerEightHour" type="number" value={Number(((result.materialPerEightHour * 2.20462) / 1000)).toFixed(3)} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>lbs</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.materialPerEightHour).toFixed(3) / 1000} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>kg</Form.Label> </Col>

                    </Row>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={3}>
                            <Form.Label>Material required per 24 hours</Form.Label> </Col>
                        <Col sm={3}>
                            <Form.Control name="materialPerTwentyFourHour" type="number" value={Number((result.materialPerTwentyFourHour * 2.20462) / 1000).toFixed(3)} readOnly />
                        </Col>
                        <Col sm={1}> <Form.Label>lbs</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control type="number" value={Number(result.materialPerTwentyFourHour).toFixed(3) / 1000} readOnly />
                        </Col>

                        <Col sm={1}> <Form.Label>kg</Form.Label> </Col>
                    </Row>
                </Form.Group>

            </Form>
        </div>
    )
}

export default MoldingCalculatorFormOutput
