import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Col, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../../App.css";
import DataService from "../../../services/ApiService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CreateSession = () => {
  const history = useHistory();

  const { sessionName, sessionId } = useParams();

  const location = useLocation();

  const mold = location.state.mold;

  const [session_name, setSession_name] = useState();

  const [sessionid, setSessionid] = useState();

  const [sessionData, setSessionData] = useState({
    experiment_desc: "null",
  });

  const [submitClicked, setsubmitClicked] = useState(false);

  const [data, setData] = useState({
    experiment_desc: "null",
    variables: [],
  });

  const varArr = [];

  const variableList = [
    "Back Pressure",
    "Injection Speed",
    "Holding Pressure",
    "Cooling Time",
    "Melt Temperature",
    "Injection Pressure",
    "Cycle Time",
    "Mold Temp A Side",
    "Mold Temp B Side",
    "Mold Temperature",
    "Pack Pressure",
    "Pact Time",
    "Screw Speed",
    "Tonnage",
  ];

  const handleSubmit = () => {
    var Session_Id = btoa(sessionid);

    history.push({
      pathname:`/dashboard/${Session_Id}/DOE/define-variables`,
      state: {mold:  mold ,session:sessionId}
  }
  
  )
  };

  const handleSave = () => {
    setsubmitClicked(true);
    for (let i = 1; i <= 5; i++) {
      const variableName = `v${i}`;
      const checkboxName = `v${i}c`;

      if (sessionData[variableName] && sessionData[checkboxName]) {
        const variableWithUnderscore = sessionData[variableName].replace(
          /\s+/g,
          "_"
        );
        varArr.push(variableWithUnderscore);
      }
    }

    let tempData = {
      experiment_desc: sessionData.experiment_desc,
      variables: varArr,
    };

    setData(() => {
      const newData = tempData;
      // console.log(newData);
      return newData;
    });
  };

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      if (checked) {
        // If the checkbox is checked, add the variableSelect key to sessionData
        setSessionData((prevSessionData) => ({
          ...prevSessionData,
        }));
      } else {
        // If the checkbox is unchecked, remove the associated key from sessionData
        setSessionData((prevSessionData) => {
          const updatedData = { ...prevSessionData };
          // Remove the associated key (e.g., v1) when unchecked
          delete updatedData[name.replace("c", "")];
          return updatedData;
        });
      }
    } else {
      // For other input elements, update the sessionData object
      setSessionData((prevSessionData) => ({
        ...prevSessionData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setSessionData((prevSessionData) => ({
      ...prevSessionData,
      [name]: checked,
    }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    // Update the sessionData object with the selected variable
    setSessionData((prevSessionData) => ({
      ...prevSessionData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setSession_name(atob(sessionName));
    setSessionid(atob(sessionId));

    if (submitClicked) {
      DataService.CreateSession(sessionid, data)
        .then((res) => {})
        .catch((err) => {
          console.error("Error sending data to Django:", err);
        });
    }
  }, [submitClicked, data, session_name]);

  return (
    <div className="p-4">
      <div>
        <div className="ml-4">
          <span className="Mold-Session"> Session Name : {session_name}</span>
        </div>
      </div>

      <Form>
        {/* Session Description */}
        <FormGroup row>
          <Label for="expDesc" sm={2}>
            Experiment Description
          </Label>

          <Col sm={2}>
            <Input
              id="expDesc"
              name="experiment_desc"
              placeholder="Description"
              type="text"
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <legend>Select Variables</legend>
          <div className="viscosity_table" style={{ margin: "30px" }}>
            <table>
              <tbody className="grid_style">
                {[1, 2, 3, 4, 5].map((variable, index) => (
                  <tr key={index}>
                    <td>
                      <FormGroup row>
                        <Col sm={4}>
                          <FormGroup check>
                            <Input
                              id={`checkbox${index + 1}`}
                              checked={sessionData[`v${index + 1}c`]}
                              name={`v${index + 1}c`}
                              type="checkbox"
                              onChange={handleCheckboxChange}
                            />
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup row>
                        <Col sm={12}>
                          <Input
                            id={`variableSelect${index + 1}`}
                            checked={sessionData[`v${index + 1}c`] || false}
                            name={`v${index + 1}`}
                            type="select"
                            onChange={handleSelectChange}
                          >
                            <option value="">Select a variable</option>
                            {variableList.map((variable, variableIndex) => (
                              <option key={variableIndex}>{variable}</option>
                            ))}
                          </Input>
                        </Col>
                      </FormGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </FormGroup>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleSubmit}>Next</Button>
      </Form>
    </div>
  );
};

export default CreateSession;
