import React from 'react';

const TableComponent = ({ data }) => {

  // Generic Table Component
  
  if (!data || data.length === 0) {
    return <p>No data to display.</p>;
  }

  return (
    <div className='summary-table m-1'>
      <table>
        <thead>
          <tr>
            {Object.keys(data[0]).map((header, index) => (
              <th key={index}>{header.replace(/_/g," ")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(item).map((value, colIndex) => (
                <td key={colIndex}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  
};

export default TableComponent;
