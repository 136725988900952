// Pressure
// export function psi_To_MPa(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.00689476;
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function MPa_To_psi(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 145.038;
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function psi_To_bar(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.0689476;
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function bar_To_psi(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 14.5038;
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function MPa_To_bar(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 10;
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function bar_To_MPa(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.1;
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// Distance
// export function cm_To_mm(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 10;
//   // console.log( value )
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function mm_To_cm(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 10;
//   // console.log( value )
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function mm_To_in(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 25.4;
//   // console.log( value )
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function in_To_mm(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 25.4;
//   // console.log( value )
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function cm_To_in(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 2.54;
//   // console.log( value )
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// export function in_To_cm(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 2.54;
//   // console.log( value )
//   return parseFloat(CalcValue).toFixed(Decimals);
// }

// // Temperature
// export function celsiusToFahrenheit(celsius, Decimals) {
//   let CalcValue = celsius ? (celsius * 9) / 5 + 32 : "";

//   return isNaN(parseFloat(CalcValue).toFixed(Decimals))
//     ? ""
//     : parseFloat(CalcValue).toString().includes(".")
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// export function fahrenheitToCelsius(fahrenheit, Decimals) {
//   let CalcValue = fahrenheit ? ((fahrenheit - 32) * 5) / 9 : "";

//   return isNaN(parseFloat(CalcValue).toFixed(Decimals))
//     ? ""
//     : parseFloat(CalcValue).toString().includes(".")
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// // Tonnage
// export function UStons_To_Metrictons(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.907185;
//   return Decimals
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// export function Metrictons_To_UStons(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 1.10231;
//   return Decimals
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// export function UStons_To_kN(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 8.89644;
//   return Decimals
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// export function kN_To_UStons(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.112404;
//   return Decimals
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// export function Metrictons_To_kN(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 9.80665;
//   return Decimals
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// export function kN_To_Metrictons(value, Decimals) {
//   let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.101972;
//   return Decimals
//     ? parseFloat(CalcValue).toFixed(Decimals)
//     : parseFloat(CalcValue);
// }

// Speed
export function insec_To_mmsec(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 25.4;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function mmsec_To_insec(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 25.4;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function g_To_oz(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.035274;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function oz_To_g(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 28.3495;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function sqin_To_sqcm(value, Decimals) {
  let CalcValue = 0;
  CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 6.4516;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function sqcm_To_sqin(value, Decimals) {
  let CalcValue = 0;
  CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.155;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export const CalculateAverage = (min, max, Decimals) => {
  let avg = 0;

  let values = {
    min: isNaN(parseFloat(min)) ? 0 : parseFloat(min),
    max: isNaN(parseFloat(max)) ? 0 : parseFloat(max),
  };

  avg = (values.min + values.max) / 2;

  return isNaN(parseFloat(avg).toFixed(Decimals))
    ? ""
    : parseFloat(avg).toString().includes(".")
    ? parseFloat(avg).toFixed(Decimals)
    : parseFloat(avg);
};
