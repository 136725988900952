import React, { useEffect, useState } from "react";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { useHistory, useParams } from "react-router-dom";
import Header2 from "../common/header-component/header2";
import MachineInformation from "./MachineInformation/MachInfo";
import Inputs from "./Inputs/Inputs";
import Outputs from "./Outputs/Outputs";
import HR from "./HR/HR";
import Notes from "./Notes/Notes";

const PPS = () => {
  const history = useHistory();

  let HeaderData = [
    { text: "Machine Information" },
    { text: "Inputs" },
    { text: "HR" },
    { text: "Notes" },
    { text: "Outputs" },
  ];

  const [ProjectData, setProjectData] = useState({
    MoldId: "",
    MoldName: "",
    sessionId: "",
    sessionName: "",
  });

  var { sessionId, sessionName, moldId, moldName } = useParams();

  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const BackToSession = () => {
    history.push(`/dashboard/${moldId}/${moldName}/PPS/sessions`);
  };

  const BackToMold = () => {
    history.push("/dashboard/mold");
  };

  const content0 = () => {
    return (
      <div className="card p-2 mb-2">
        <MachineInformation />
      </div>
    );
  };

  const content1 = () => {
    return (
      <div className="card p-2 mb-2">
        <Inputs />
      </div>
    );
  };

  const content2 = () => {
    return (
      <div className="card p-2 mb-2">
        <HR />
      </div>
    );
  };

  const content3 = () => {
    return (
      <div className="card p-2 mb-2">
        <Notes />
      </div>
    );
  };

  const content4 = () => {
    return (
      <div className="card p-2 mb-2">
        <Outputs />
      </div>
    );
  };

  useEffect(() => {
    setProjectData({
      MoldId: atob(moldId),
      MoldName: atob(moldName),
      sessionId: atob(sessionId),
      sessionName: atob(sessionName),
    });
  }, [moldId, moldName, sessionId, sessionName]);

  return (
    <>
      <Header2 Title="Plastic Process Sheet" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "14px" }}
                onClick={BackToMold}
              >
                {" "}
                Molds{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "14px" }}
                onClick={BackToSession}
              >
                {" "}
                Sessions{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "14px" }}>
                {" "}
                Plastic Process Sheet{" "}
              </span>
            </div>
          </div>

          {/* <div className="d-flex b-primary b-r-4 pt-1">
            <div className="ml-2">
              <span className="Mold-Session"> Mold Id :  </span>
            </div>

            <div className="ml-4 mr-2">
              <span className="Mold-Session">
                {" "}
                Session Name : {" "}
              </span>
            </div>
          </div> */}
        </div>
      </div>

      <div>
        <TabComponent heightAdjustMode="Auto" widthAdjustMode="Auto" id="defaultTab">
          <TabItemsDirective>
            <TabItemDirective header={HeaderData[0]} content={content0} />
            <TabItemDirective header={HeaderData[1]} content={content1} />
            <TabItemDirective header={HeaderData[2]} content={content2} />
            <TabItemDirective header={HeaderData[3]} content={content3} />
            <TabItemDirective header={HeaderData[4]} content={content4} />
          </TabItemsDirective>
        </TabComponent>
      </div>
    </>
  );
};

export default PPS;
