import React from "react";
import Table from "react-bootstrap/Table";
import "../../../assets/custom-stylesheet/grid_stylecss.css";

const NumericalPredictionGrid = ({
  PredictedValues,
  ResponseName,
  Specifications,
}) => {
  // console.log(Specifications)
  const organizeData = () => {
    const cavData = {};
    const dimensions = new Set();

    ResponseName.forEach(([name], index) => {
      const parts = name.split("dimension~")[1].split("-");
      const cav = `${parts[0]}-${parts[1]}`;
      const dimension = parts.slice(2).join("~");

      if (!cavData[cav]) {
        cavData[cav] = {};
      }

      cavData[cav][dimension] = Number(PredictedValues[index]).toFixed(4);
      dimensions.add(dimension);
    });

    return { cavData, dimensions: Array.from(dimensions) };
  };

  const { cavData, dimensions } = organizeData();

  return (
    <>
      <div
        className="spreadsheet"
        id="BackPress-Calc-Grid"
        style={{ width: "105wh", height: "400px", overflow: "auto" }}
      >
       
                <div className="b-primary p-2">
                <div style={{ width: "100%" }}>
                      <b>Quantitative</b>
                      
                      {Specifications && (<>
                      <Table className="mt-1" striped bordered hover responsive variant="light">
                        <thead>
                          <tr>
                            <th> Response Name </th>
                            {dimensions.map((dimension, key) => (
                              <th key={key}>{dimension}</th>
                            ))}
                          </tr>
                          <tr>
                            <td>Predicted Values</td>
                            <td colSpan={dimensions.length}></td>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(cavData).map(([cav, data], key) => (
                            <tr key={key}>
                              <td>
                                {cav.charAt(0).toUpperCase() + cav.slice(1)}
                              </td>
                              {dimensions.map((dimension, i) => (
                                <td key={i}>{data[dimension] || "-"}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                        {/* ////////////////////////////////////////////////////////////////////////////// */}
                        <thead>
                          <tr>
                            <td>Specifications</td>
                            <td colSpan={dimensions.length}></td>
                          </tr>
                        </thead>

                        <tbody>
                            {["usl", "plus_tol", "nom", "minus_tol", "lsl"].map(
                              (specKey, key) => (
                                <tr key={key}>
                                  <td>
                                    {specKey === "plus_tol"
                                      ? "+ TOL"
                                      : specKey === "minus_tol"
                                      ? "- TOL"
                                      : specKey === "nom"
                                      ? "Nom"
                                      : specKey.replace("_", " ").toUpperCase()}
                                  </td>
                                  {dimensions.map((dimension) => (
                                    <td
                                      // colSpan={cavities.length}
                                      key={dimension}
                                    >
                                      {Specifications[dimension]?.[
                                        specKey
                                      ]?.toFixed(3) || "-"}
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                            </tbody>

                      </Table>
                      </>)}


                    </div>
                  </div>
                </div>
              
          
    </>
  );
};

export default NumericalPredictionGrid;
