import React, { useState, useEffect } from "react";
import DataService from "../../../services/ApiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NoteTable from "./NoteTable";
import ConfidenceRatingGrid from "./ConfidenceRatingGrid";
import { color } from "d3";

const ConfidenceRating = () => {
  const { sessionId } = useParams();

  const decry = atob(sessionId);

  const [ResponseName, setResponseName] = useState([]);

  const [rangeColumnData, setRangeColumnData] = useState([]);

  const [Specifications, setSpecifications] = useState();

  useEffect(() => {
    const NumericalAPI = () => {
      return new Promise(async (resolve, reject) => {
        try {
          DataService.NumericalPrediction(decry).then((res) => {
            const responseData = res.data;

            setRangeColumnData(responseData.range_column_data);

            setResponseName(responseData.range_column_data);

            setSpecifications(responseData.specifications);

            let PredictedValuesArray = [];

            for (let i = 0; i < responseData.dimension.length; i++) {
              PredictedValuesArray.push(responseData.dimension[i].dimension[0]);
            }

          });

          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    NumericalAPI();
  }, [decry]);

  // Step 1: Group data by the dimension name
  const grouped_data = {};

  rangeColumnData.forEach((item) => {
    const [dimension, high, low] = item;
    const parts = dimension.split("~");
    const title = dimension.split("-");
    const titleValue = title[2];
    // const key = parts.slice(2).join("~"); // Extract the part of the dimension name after "cav-x-"
    const cavity_info = parts[1]; // Extract the cavity info (e.g., "cav-4-ABC")

    if (!grouped_data[titleValue]) {
      grouped_data[titleValue] = [];
    }
    grouped_data[titleValue].push({ cavity_info, titleValue, high, low });
  });

  // Step 2: Format the grouped data into the desired structure
  const formatted_data = {};

  Object.keys(grouped_data).forEach((key) => {
    formatted_data[key] = [];
    grouped_data[key].forEach((item) => {
      const { cavity_info, titleValue, high, low } = item;
      try {
        // Extract the cavity number from the cavity info (e.g., "cav-4-ABC")
        const cavity_number = parseInt(cavity_info.split("-")[1], 10);
        formatted_data[key].push({
          title: titleValue,
          x: cavity_number,
          high: high,
          low: low,
        });
      } catch (error) {}
    });
  });

  // Step 3: Collect the formatted data into an array
  const range_col = [];
  Object.values(formatted_data).forEach((value) => {
    range_col.push(value);
  });

  return (
    <>
      <div className="p-2">
        <NoteTable />
      </div>

      <div className="p-2">
        <ConfidenceRatingGrid
        ResponseName={ResponseName}
        Specifications={Specifications}
        RangeColumnData = {range_col}
        />
      </div>

      <div style={{color:'red'}}>
      PLEASE NOTE THAT THE RESULTS ABOVE ARE BASED ON THE MEASUREMENTS ENTERED BY THE USER. THE SOFTWARE PREDICTS THE ABOVE BASED ON THOSE ENTERED MEASUREMENTS.  PLEASE ENSURE THAT THE DATA ENTERED IS RELIABLE. 
      </div>
    </>
  );
};

export default ConfidenceRating;
