import React, { useEffect } from 'react';
import { getCell } from '@syncfusion/ej2-spreadsheet';
import axios from "axios";

const DropDownMenu = ({ HeaderArray, SelectedColHeader, PCSheetRowCount, setIsLoading, PCSpreadsheet, setSelectedColData, CalculateBin, CreateChartData, setBin_Interval, setChartData, setSelectedColHeader, setComputeClicked, SessionId, SelectedCol }) => {

    const getIndex = (e) => {

        return new Promise((resolve, reject) => {

            if (e.target.value !== 0) {
                resolve(e.target.value)
            }

        })

    }

    const getSheetData = (idx) => {

        return new Promise((resolve, reject) => {

            let TempArray = [];

            for (let i = 5; i < PCSheetRowCount; i++) {

                if (getCell(i, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value) {
                    TempArray.push(getCell(i, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value)
                }
                else {

                }

            }

            resolve(TempArray)

        })

    }

    const GetValues = (TempArray, idx) => {

        return new Promise((resolve, reject) => {

            let TempData = {};

            const char_name = getCell(0, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value ? getCell(0, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value : null;

            const upper_limit = getCell(1, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value ? getCell(1, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value : null;

            const target = getCell(2, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value ? getCell(2, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value : null;

            const lower_limit = getCell(3, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value ? getCell(3, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value : null;

            const sub_group_size = getCell(4, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value ? getCell(4, idx ? idx : 1, PCSpreadsheet.current.getActiveSheet()).value : null;

            TempData = {
                values: TempArray,
                char_name: char_name,
                sub_group_size: sub_group_size,
                upper_limit: upper_limit,
                lower_limit: lower_limit,
                target: target
            }

            setSelectedColHeader(char_name)

            resolve(TempData)

            // console.log(TempData)

        })

    }

    const GetData = (e) => {

        setIsLoading(true);

        getIndex(e).then((idx) => {

            getIndex(e).then((idx) => {

                // Getting the values/data entered in the selected column.
                getSheetData(idx).then((TempArray) => {

                    GetValues(TempArray, idx).then((ColData) => {

                        // Storing all the data entered in the selected column.
                        setSelectedColData(ColData)

                        // console.log(ColData)

                        CalculateBin(ColData.values).then((binInterval) => {

                            setBin_Interval(binInterval)

                            // Creating chart data for histogram again in array of objects format.
                            CreateChartData(ColData).then((HistoData) => {

                                setChartData(HistoData)

                                setTimeout(() => {

                                    setIsLoading(false);

                                }, 1000);

                                const apiUrl = `${process.env.REACT_APP_API_URL}/quality/calculate/${SessionId}/${SelectedCol}`;

                                // Make a GET request to your Django API
                                axios.post(apiUrl, ColData).then((response) => {

                                    // console.log(ColData)

                                    // Handle the successful response
                                    // console.log("Response from Django:", response.data);

                                    setComputeClicked(true);

                                    // console.log(computeClicked)

                                }).catch((error) => {

                                    // Handle any errors

                                });

                                // console.log(HistoData)

                            })

                        })

                    })

                })

            })

        })

    }

    useEffect(() => {

        // setIsLoading(false);

        // console.log(SelectedColHeader)

        var selectElement = document.getElementById("HeaderDropDown");

        for (var i = 0; i < selectElement.options.length; i++) {

            if (selectElement.options[i].text === SelectedColHeader) {

                selectElement.selectedIndex = i;

                break;
            }

        }

    }, [SelectedColHeader, HeaderArray])

    return (
        <div className='d-flex'>

            <div className='m-2'>

                <select
                    className="ml-2 form-control"
                    style={{ width: '180px' }}
                    name="Headers"
                    id="HeaderDropDown"
                    onChange={GetData}
                >
                    <option value={0}> Select Characteristics: </option>

                    {HeaderArray.map((Header, key) => (

                        <option key={key} value={key + 1}> {Header} </option>

                    )
                    )}

                </select>

            </div>

            <div className='ml-2 mt-2'>
                <span> Characteristics:- {SelectedColHeader || ""} </span>
            </div>

        </div>
    )
}

export default DropDownMenu
