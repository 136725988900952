import React from "react";

// Syncfusion chart control
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

const Chart_Section = ({
  Injection_Speed,
  Injection_Speed_Units,
  InjectionSpeedArray,
  niceNumbers,
  InjectionniceNumbers,
  ShearRateNiceNumbers,
  YTitle,
}) => {
  return (
    <section>
      <div
        className={
          Injection_Speed ? "ViscosityChart" : "Viscosity_Showable_Chart"
        }
      >
        <ChartComponent
          border={{ width: 1, color: "darkblue" }}
          tooltip={{ enable: true }}
          id="InjectionSpeedChart"
          width="100%"
          height="400"
          title="Viscosity Curve"
          className="vchart"
          primaryXAxis={{
            title: `Injection Speed (${Injection_Speed_Units})`,
            minimum: InjectionniceNumbers.min,
            maximum: InjectionniceNumbers.max,
            interval: InjectionniceNumbers.step,
            lineStyle: { color: "black" },
          }}
          primaryYAxis={{
            title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
            minimum: niceNumbers.min,
            maximum: niceNumbers.max,
            interval: niceNumbers.step,
            lineStyle: { color: "black" },
          }}
        >
          <Inject services={[LineSeries, Category, DataLabel, Tooltip]} />

          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={InjectionSpeedArray}
              type="Line"
              xName="Injection Speed"
              yName="Viscosity"
              marker={{ visible: true }}
              fill="rgb(2,0,4)"
              width={2.5}
              // name='Injection Speed'
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      <div
        className={
          Injection_Speed ? "Viscosity_Showable_Chart" : "ViscosityChart"
        }
      >
        <ChartComponent
          border={{ width: 1, color: "darkblue" }}
          width="100%"
          height="400"
          tooltip={{ enable: true }}
          id="ShearRateChart"
          title="Viscosity Curve"
          primaryXAxis={{
            title: "Shear Rate (1 / sec)",
            valueType: "Double",
            minimum: ShearRateNiceNumbers.min,
            maximum: ShearRateNiceNumbers.max,
            interval: ShearRateNiceNumbers.step,
          }}
          primaryYAxis={{
            title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
            minimum: niceNumbers.min,
            maximum: niceNumbers.max,
            interval: niceNumbers.step,
          }}
        >
          <Inject services={[LineSeries, Category, DataLabel, Tooltip]} />

          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={InjectionSpeedArray}
              type="Line"
              // xName="Shear Rate"
              xName="Injection Speed"
              yName="Viscosity"
              marker={{ visible: true }}
              fill="rgb(2,0,4)"
              width={2.5}
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </section>
  );
};

export default Chart_Section;
