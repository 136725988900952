import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DataService from "../../../services/ApiService";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  getRangeAddress,
} from "@syncfusion/ej2-react-spreadsheet";

const DataInputWorksheets = ({ mold }) => {

  const { sessionId } = useParams();

  var decrypted_session = atob(sessionId);

  const [expRunsData, setExpRunsData] = useState();

  const [runValues, setRunValues] = useState();

  let response_json;

  const [loadData, setloadData] = useState();

  const [noOfDimensions, setNoOfDimensions] = useState([]);

  const [DimensionsData, setDimensionsData] = useState([]);

  const [colCount, setColCount] = useState(10);

  const [rowCount, setRowCount] = useState(10);

  const SampleRunCount = useRef([]);

  const SheetData = useRef([]);

  function getAlphabetCombination(index) {

    let result = "";

    while (index >= 0) {
      result = String.fromCharCode(65 + (index % 26)) + result;

      index = Math.floor(index / 26) - 1;
    }

    return result;

  }

  const FormatandPopulateSheetCols = (char, count) => {

    return new Promise((resolve, reject) => {

      if (DataInputWorksheet.current) {
        DataInputWorksheet.current.cellFormat(
          { fontWeight: "bold", textAlign: "center" },
          `A1:${char}2`
        );

        DataInputWorksheet.current.cellFormat(
          { fontWeight: "bold", textAlign: "center" },
          "B1:B100"
        );

        DataInputWorksheet.current.setColumnsWidth(150, ["A:A"]);
        DataInputWorksheet.current.setColumnsWidth(90, [`B:${char}`]);

        DataInputWorksheet.current.merge(`C1:${char}1`, "Horizontally");

        DataInputWorksheet.current.updateCell({ value: "Dimension" }, "A1");
        DataInputWorksheet.current.updateCell({ value: "Sample" }, "B1");
        DataInputWorksheet.current.updateCell({ value: "Expt Run No." }, "C1");
      }

      for (let i = 3; i <= count + 2; i++) {
        // console.log(i-2)

        const char = getAlphabetCombination(i - 1);

        if (DataInputWorksheet.current) {
          DataInputWorksheet.current.updateCell({ value: i - 2 }, `${char}2`);
        }
      }

      resolve();
    });
  };

  const GenerateSampleArray = (Data) => {
    return new Promise((resolve, reject) => {
      let TempArray = [],
        SampleArray = [],
        RowCountArray = [];

      for (let i = 0; i < Data.length; i++) {
        for (let j = 0; j < parseInt(Data[i][1]); j++) {
          for (let k = 1; k <= parseInt(Data[i][2]); k++) {
            RowCountArray.push(k);

            TempArray.push(k);

            // console.log(k)
          }

          SampleArray.push(TempArray);

          TempArray = [];

          setRowCount(RowCountArray.length + 2);
        }
      }

      resolve(SampleArray);
    });
  };

  const PopulateSampleColumn = (SampleRuns) => {
    return new Promise((resolve, reject) => {
      let index = 3;

      // console.log(SampleRuns)

      for (let i = 0; i < SampleRuns.length; i++) {
        for (let j = 0; j < SampleRuns[i].length; j++) {
          if (DataInputWorksheet.current) {
            DataInputWorksheet.current.updateCell(
              { value: SampleRuns[i][j] },
              `B${index}`
            );
          }

          // console.log(SampleRuns[i][j], `B${index}`)

          index++;
        }
      }

      resolve(SampleRuns);
    });
  };

  const PopulateDimensionsColumn = (DimensionsData, SampleRuns) => {
    return new Promise((resolve, reject) => {
      // console.log(DimensionsData.dimension, SampleRuns)

      let index = 0;

      // console.log( SampleRuns )

      for (let i = 0; i < SampleRuns.length; i++) {
        index =
          parseInt(index) + parseInt(SampleRuns[i === 0 ? i : i - 1].length);

        // console.log( DimensionsData.dimension[i], `A${index}` )

        if (DataInputWorksheet.current) {
          DataInputWorksheet.current.updateCell(
            { value: DimensionsData.dimension[i] },
            `A${index}`
          );
        }
      }

      resolve();
    });
  };

  const GetDimensionsCol = () => {
    return new Promise((resolve, reject) => {
      let TempArray = [],
        TempObj = {};

      for (let i = 0; i < DimensionsData.dimension.length; i++) {
        TempObj["factor_name"] = DimensionsData.dimension[i];

        TempArray.push(TempObj);

        TempObj = {};
      }

      // console.log(TempArray)

      resolve(TempArray);
    });
  };

  const GetAllColData = () => {
    return new Promise((resolve, reject) => {
      let TempArray = [];

      // var usedRowIdx = PCSpreadsheet.current.getActiveSheet().usedRange.rowIndex;
      // var usedColIdx = DataInputWorksheet.current.getActiveSheet().usedRange.colIndex;

      // console.log(colCount)

      DataInputWorksheet.current
        .getData(
          DataInputWorksheet.current.getActiveSheet().name +
          "!" +
          getRangeAddress([2, 2, rowCount - 1, colCount - 1])
        )
        .then((cells) => {
          cells.forEach((cell, key) => {
            if (cell.value) {
              TempArray.push(cell.value.toString());
            } else {
              TempArray.push(null);
            }

            // console.log(key + ' : ' + cell.value);
          });

          resolve(TempArray);

          // console.log(TempArray, SampleRunCount.current)
        });
    });
  };

  const GetRunsData = (DimColData, RunsData) => {

    return new Promise((resolve, reject) => {

      let TempObj = {},
        TempArray = [],
        TempArray2 = [],
        index = 0;

      for (let i = 0; i < DimColData.length; i++) {
        // console.log(i)

        for (
          let j = 0;
          j < SampleRunCount.current[i].length * (colCount - 2);
          j++
        ) {
          TempArray2.push(RunsData[index]);

          // console.log(index)

          index = index + 1;

          // console.log(SampleRunCount.current[i]?.length)
        }

        TempObj["observational_data"] = TempArray2;

        let merged_obj = Object.assign({}, DimColData[i], TempObj);

        TempArray.push(merged_obj);

        TempArray2 = [];

        TempObj = {};
      }

      SheetData.current = TempArray;

      resolve(TempArray)

    })

  }

  const GetSheetData = () => {

    GetDimensionsCol().then((DimColData) => {

      GetAllColData().then((RunsData) => {

        GetRunsData(DimColData, RunsData).then((TempArray) => {

          // console.log( TempArray )

          submitData()

        })

      });
    });

  };

  useEffect(() => {

    fetchData();

    DataService.GetObservationalData(decrypted_session).then((res) => {

      setloadData(res.data.spreadsheet_json)

      // console.log(res)
      // console.log("GetObservationalData",res.data.spreadsheet_json)
      // DataInputWorksheet.current.openFromJson({ file: loadData });
    })

    DataService.GetDefineResponse(decrypted_session, mold)
      .then((res) => {

        if (res.data) {

          // console.log(res);

          setDimensionsData(res.data);

          // console.log(res.data.selected_part_cav)

          const count = Math.pow(2, res.data.varList[0].length);

          setColCount(parseInt(count) + 2);

          const char = getAlphabetCombination(count + 2);

          FormatandPopulateSheetCols(char, count).then(() => {

            GenerateSampleArray(res.data.selected_part_cav).then(
              (SampleRuns) => {
                PopulateSampleColumn(SampleRuns).then(() => {
                  PopulateDimensionsColumn(res.data, SampleRuns).then(() => {
                    SampleRunCount.current = SampleRuns;

                    // console.log(SampleRuns)
                  });
                });
              }
            );
          });
        }
      })
      .catch((err) => {
        console.error("Error sending data to Django:", err);
      });
  }, [sessionId]); // Fetch data whenever sessionId changes

  const fetchData = async () => {

    try {

      const decry = atob(sessionId);

      DataService.FactorialTable(decry)
        .then((res) => {
          const runsJson = res.data["runsJson"];
          const dataArray = Array.isArray(runsJson) ? runsJson : [runsJson];
          setExpRunsData(dataArray);
        })


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const submitData = async () => {

    try {
      const decry = atob(sessionId);

      const observational_postdata = {
        session_id: parseInt(decry),
        observational_details: SheetData.current,
        spreadsheet_Json: response_json,
      };

      // console.log("observational_postdata", observational_postdata);

      const response = await DataService.ObservationalData(
        decry,
        observational_postdata
      );

      // console.log("ObservationalData API Response", response);

      // Fetch updated observational data after posting new data
      fetchData();

    } catch (error) {

      console.error("Error:", error);

    }

  };

  const DataInputWorksheet = useRef();

  const protectSettings = { selectCells: true };

  const scrollSettings = {
    isFinite: true,
    enableVirtualization: false,
  };

  let isPaste = false;

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  };

  function onCreated() {
    //Applies data validation to specified range in the active sheet.
    DataInputWorksheet.current.addDataValidation(
      {
        type: "Decimal",
        operator: "Between",
        value1: "-100000.000000",
        value2: "100000.000000",
      },
      `C3:AAA${DataInputWorksheet.current.getActiveSheet().rowCount}`
    );

    DataInputWorksheet.current.lockCells(
      `C3:AAA${DataInputWorksheet.current.getActiveSheet().rowCount || rowCount
      }`,
      false
    );
  }

  function UnlockCells() {
    DataInputWorksheet.current.lockCells(
      `C3:AAA${DataInputWorksheet.current.getActiveSheet().rowCount || rowCount
      }`,
      false
    );
  }

  function beforeCellUpdate(args) {
    // Skip the cell update for paste action that contains characters, If you need you can skip this for all type of actions performed in the spreadsheet.
    if (isPaste) {
      let pastedValue = args.cell.value;

      // Match alphabets and special characters
      var regex = /[a-zA-Z]/g;

      if (pastedValue && pastedValue.toString().match(regex)) {
        args.cancel = true;
      }

      isPaste = false;
    }
  }

  function actionBegin(args) {
    if (args.args.eventArgs && args.args.eventArgs.requestType === "paste") {
      isPaste = true;
    }
  }

  const SaveData = () => {

    // Load the JSON data to the spreadsheetRef.currrent?.
    DataInputWorksheet.current.saveAsJson().then((Json) => {

      response_json = Json;

      GetSheetData()

      // console.log(response_json);
    });

  };

  const openapi = () => {

    if (loadData) {

      DataInputWorksheet.current.openFromJson({ file: loadData.jsonObject });

    }
    // console.log(loadData)
  }

  useEffect(() => {

    openapi()

  }, [loadData])

  return (
    <div className="interaction-container">

      <div className="spreadsheet ml-2" onMouseEnter={UnlockCells} style={{ border: "1px solid #573DAC" }}>

        <SpreadsheetComponent
          className="m-1"
          height={300}
          width={1040}
          ref={DataInputWorksheet}
          showFormulaBar={false}
          showSheetTabs={false}
          showRibbon={false}
          dialogBeforeOpen={dialogBeforeOpen.bind(this)}
          scrollSettings={scrollSettings}
          allowAutoFill={false}
          created={onCreated}
          beforeCellUpdate={beforeCellUpdate.bind(this)}
          actionBegin={actionBegin.bind(this)}
        >
          <SheetsDirective>
            <SheetDirective
              rowCount={rowCount}
              frozenRows={2}
              frozenColumns={2}
              showHeaders={false}
              colCount={colCount}
              isProtected={true}
              protectSettings={protectSettings}
            ></SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>

      </div>

      <button className="btn btn-primary btn-air-primary m-1" type="button" onClick={SaveData}> Save </button>

      {/* <button className="btn btn-primary btn-air-primary m-1" type="button" id="LoadSheetButton" onClick={openapi}> Load </button> */}

    </div>
  );
};

export default DataInputWorksheets;
