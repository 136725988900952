// Speed
export function insec_To_mmsec(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 25.4;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function mmsec_To_insec(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 25.4;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function g_To_oz(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.035274;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function oz_To_g(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 28.3495;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function sqin_To_sqcm(value, Decimals) {
  let CalcValue = 0;
  CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 6.4516;
  return parseFloat(CalcValue).toFixed(Decimals);
}

export function sqcm_To_sqin(value, Decimals) {
  let CalcValue = 0;
  CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 0.155;
  return parseFloat(CalcValue).toFixed(Decimals);
}
