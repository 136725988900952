import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { logout } from "./actions/auth";

const RequireAuth = ({ logout, children }) => {
  let isAuthenticated = sessionStorage.getItem("isAuthenticated");

  if (!isAuthenticated) {
    logout();
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

export default connect(null, { logout })(RequireAuth);
