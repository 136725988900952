import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TornadoCalculation from "./TornadoCalculation";
import DataService from "../../../services/ApiService";
import { Button } from "react-bootstrap";

const Tornado = (mold) => {
  const [data, setData] = useState({
    message: null,
    plus_values: [],
    minus_values: [],
    variable_limits: null,
  });

  const { sessionId } = useParams();

  const [noOfDimensions, setnoOfDimensions] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [showPlot, setShowPlot] = useState(false);

  useEffect(() => {
    const decry = atob(sessionId);
    DataService.GetDefineResponse(decry, mold.mold)
      .then((res) => {
        const dimensionJSON = res.data["dimension"];

        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];

        setnoOfDimensions(dataArray);
      })

      .catch((err) => {
        console.error("Error sending data to Django:", err);
      });
  }, [sessionId, mold, selectedIndex]);

  const handleSummary = () => {
    const decry = atob(sessionId);

    DataService.MainEffectPlot(decry).then((res) => {
      setData(res.data);

      setShowPlot(true);
    });
  };

  const handleCompute = () => {
    return new Promise(async (resolve, reject) => {
      const selectedDimension = noOfDimensions[selectedIndex];

      try {
        const decry = atob(sessionId);
        DataService.SelectDropdown(decry, {
          selected: selectedDimension,
        })
          .then((res) => {})
          .then(() => {
            handleSummary();
          })
          .then(() => {
            setShowPlot(true);
          })

          .catch((err) => {
            console.error("Error sending data to Django:", err);
          });

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleIndexChange = (event) => {
    setSelectedIndex(parseInt(event.target.value, 10));
    setShowPlot(false)
  };

  return (
    <>
      <div>
        <div className="b-primary b-r-4 p-2">

        <div className="d-flex">
          <div className="col-md-2">

          <select className="mb-2 mt-2" value={selectedIndex} onChange={handleIndexChange}>
            {noOfDimensions.map((dimension, index) => (
              <option key={index} value={index}>
                {dimension.charAt(0).toUpperCase()+dimension.slice(1)}
              </option>
            ))}
          </select>
          </div>
          <div className="col-md-1">
          <Button className="ml-1 mb-2 mt-2 btn btn-secondary btn-air-secondary" onClick={handleCompute}>Plot Chart</Button>
        </div>
        </div>   


        <div className="m-2">
          {showPlot && (
            <div>
              <div>
                <TornadoCalculation
                  plus_values={data.plus_values}
                  minus_values={data.minus_values}
                  response={noOfDimensions[selectedIndex]}
                />
              </div>
            </div>
          ) }
        </div>
      </div>

      </div>
    </>
  );
};

export default Tornado;
