import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
// import {
//   GridComponent,
//   Inject,
//   Toolbar,
//   Page,
// } from "@syncfusion/ej2-react-grids";
import "../App.css";
// import "../../assets/custom-stylesheet/grid_stylecss.css";
import Header2 from "../common/header-component/header2";
import { useHistory } from "react-router-dom";
import {
  GridComponent,
  Inject,
  Toolbar,
  Page,
  CheckBoxFilter,
} from "@syncfusion/ej2-react-grids";
import { Check, CheckSquare } from "react-feather";

const DoenewSession = ({ user }) => {
  const history = useHistory();
  const pathD = () => {
    history.push({ pathname: `/dashboard/DOE/doe-new-session` });
  };

  function VarDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        // defaultValue={SpeedEnglishDecimals ? SpeedEnglishDecimals : 0.12}
        // onChange={StoreSpeedEnglishDecimals}
      >
        <option>{"--Pick from list or type in--"}</option>
        <option>{"Back Pressure"}</option>
        <option>{"Cooling Time"}</option>
        <option>{"Cycle Time"}</option>
        <option>{"Holding Pressure"}</option>
        <option>{"Holding Time"}</option>
        <option>{"Injection Pressure"}</option>
        <option>{"Injection Speed"}</option>
        <option>{"Mold Temp A Side"}</option>
        <option>{"Mold Temp B Side"}</option>
        <option>{"Mold Temperature"}</option>
        <option>{"Pack Pressure"}</option>
        <option>{"Pack time"}</option>
        <option>{"Screw Seed"}</option>
        <option>{"Tonnage"}</option>
      </select>
    );
  }
  function VarinputTemplate(props) {
    return <input className="w-100 p-1"></input>;
  }
  function ResponseinputTemplate(props) {
    return <input className="w-100 p-1"></input>;
  }
  function ResponsecheckTemplate(props) {
    return <CheckSquare />;
  }

  // Columns for Variables
  const VariableColumns = [
    {
      field: "Number",
      headerText: "No",
      width: 100,
    },
    {
      field: "Variable",
      headerText: "Variable",
      template: VarDropDownTemplate.bind(this),
      width: 100,
    },
    {
      field: "Unit",
      headerText: "Unit",
      template: VarinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "High",
      headerText: "High",
      template: VarinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "Low",
      headerText: "Low",
      template: VarinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "Precision",
      headerText: "Precision",
      template: VarinputTemplate.bind(this),
      width: 100,
    },
  ];

  // data for Variables
  const Variabledata = [{}, {}, {}, {}, {}];

  // Columns for Response
  const ResponseColumns = [
    {
      field: "Number",
      headerText: "No",
      width: 100,
    },
    {
      field: "ResponseName",
      headerText: "Response Name",
      template: ResponseinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "Nominal",
      headerText: "Nominal",
      template: ResponseinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "+Tol",
      headerText: "+Tol",
      template: ResponseinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "-Tol",
      headerText: "-Tol",
      template: ResponseinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "SampleSize",
      headerText: "Sample Size",
      template: ResponseinputTemplate.bind(this),
      width: 100,
    },
    {
      field: "ABC",
      headerText: "ABC",
      template: ResponsecheckTemplate.bind(this),
      width: 100,
    },
    {
      field: "XYZ",
      headerText: "XYZ",
      template: ResponsecheckTemplate.bind(this),
      width: 100,
    },
  ];

  // data for Response
  const Responsedata = [{}, {}, {}, {}, {}, {}, {}];


  return (
    <>
      <Header2 Title="Current Sessions" />

      <div className="container-fluid">
        <div className="d-flex pt-3 pb-3">
          <div className="d-flex col-md-12">
            <div className="col-md-10 d-flex">
              <div>
                <span
                  className="BreadCrum"
                  // onClick={() => history.push("/dashboard/mold")}
                  style={{ fontSize: "14px", color: "blue" }}
                >
                  {" "}
                  Molds{" "}
                </span>
              </div>
              <div>
                <span className="BreadCrum" style={{ fontSize: "16px" }}>
                  {" "}
                  {">"}{" "}
                </span>
              </div>
              <div>
                <span className="BreadCrum" style={{ fontSize: "14px" }}>
                  {" "}
                  DOE New Session{" "}
                </span>
              </div>
            </div>

            <div className="col-md-2">
              {/* <div className="mt-2 ml-2"> */}
              <button className="btn btn-secondary btn-air-secondary">
                Save
              </button>
              <button className="btn btn-warning btn-air-warning ml-2">
                Cancel
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      <div id="Container" className="container-fluid">
        <div className="card p-3 ml-4">
          <div className="b-primary b-r-4 m-1">
            <div className="d-flex justify-content-center">
              <div className="col-md-3 mt-1 mr-1 mb-1">
                <label className="lbl_design mt-1">Mold No : </label>
                <input className="mt-2 ml-2 p-1" id="doenSessioninput"></input>
              </div>

              <div className="col-md-3 mt-1 mr-1 mb-1">
                <label className="lbl_design mt-1">Session Name : </label>
                <input className="mt-2 ml-2 p-1" id="doenSessioninput"></input>
              </div>

              <div className="col-md-3 mt-1 mr-1 mb-1">
                <label className="lbl_design top mt-3">
                  Experiment Discription :{" "}
                </label>
                <textarea className="mt-2 ml-2 w-50 p-1"></textarea>
              </div>

              <div className="col-md-3 mt-1 mr-1 mb-1 text-right">
                <label className="lbl_design mt-1">Date : </label>
                <input className="mt-2 ml-2 p-1" id="doenSessioninput"></input>
              </div>

              {/* <div className="col-md-3 mt-1 mr-1 mb-1">
                <label className="lbl_design mt-1">User Name : </label>
                <input className="mt-2 ml-2"></input>
              </div> */}
            </div>

            {/* <div className="d-flex">
              <div className="col-md-6 mt-1 mr-1 mb-1">
                <label className="lbl_design ">Experiment Discription : </label>
                <textarea className="mt-2 ml-2 w-50"></textarea>
              </div>
            </div> */}
          </div>

          <div className="b-primary b-r-4 m-1">
            <div className="mt-3 mr-3 mb-3 col-md-12">
              <h6>Define Variable</h6>

              <div className="table-responsive" id="DBTable">
                <table className="table" cellPadding={"0"}>
                  <tr>
                    <td>
                      <GridComponent
                        id="Grid"
                        dataSource={Variabledata}
                        columns={VariableColumns}
                        height={210}
                      ></GridComponent>
                    </td>
                  </tr>
                </table>
              </div>

              {/* <Table
                className="table mb-2 o-auto"
                // style={{ border: "1px solid black" }}
                // height={100}
                cellPadding={0}
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th align="center" style={{ width: "6.6%" }}>
                      No
                    </th>

                    <th align="center" style={{ width: "18.6%" }}>
                      Variable
                    </th>

                    <th align="center" style={{ width: "18.6%" }}>
                      Unit
                    </th>

                    <th align="center" style={{ width: "18.6%" }}>
                      High
                    </th>

                    <th align="center" style={{ width: "18.6%" }}>
                      Low
                    </th>

                    <th align="center" style={{ width: "18.6%" }}>
                      Precision
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td align="left" style={{ background: "#fff" }}>1</td>

                    <td align="left" style={{ background: "#fff" }}>
                      <select className="form-control">
                        <option>--Pick from list or type in-- </option>
                        <option>Back Pressure </option>
                        <option>Cooling Time </option>
                        <option>Cycle Time </option>
                        <option>Holding Pressure </option>
                        <option>Holding Time </option>
                        <option>Injection Pressure </option>
                        <option>Injection Speed </option>
                        <option>Melt Temperature </option>
                        <option>Mold Temp A Side </option>
                        <option>Mold Temp B Side </option>
                        <option>Mold Temperature </option>
                        <option>Pack Pressure </option>
                        <option>Pack time </option>
                        <option>Screw Seed </option>
                        <option>Tonnage </option>
                      </select>
                    </td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td align="left" style={{ background: "#fff" }}>2</td>

                    <td align="left" style={{ background: "#fff" }}>
                      <select className="form-control">
                        <option>--Pick from list or type in-- </option>
                        <option>Back Pressure </option>
                        <option>Cooling Time </option>
                        <option>Cycle Time </option>
                        <option>Holding Pressure </option>
                        <option>Holding Time </option>
                        <option>Injection Pressure </option>
                        <option>Injection Speed </option>
                        <option>Melt Temperature </option>
                        <option>Mold Temp A Side </option>
                        <option>Mold Temp B Side </option>
                        <option>Mold Temperature </option>
                        <option>Pack Pressure </option>
                        <option>Pack time </option>
                        <option>Screw Seed </option>
                        <option>Tonnage </option>
                      </select>
                    </td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td align="left" style={{ background: "#fff" }}>3</td>

                    <td align="left" style={{ background: "#fff" }}>
                      <select className="form-control">
                        <option>--Pick from list or type in-- </option>
                        <option>Back Pressure </option>
                        <option>Cooling Time </option>
                        <option>Cycle Time </option>
                        <option>Holding Pressure </option>
                        <option>Holding Time </option>
                        <option>Injection Pressure </option>
                        <option>Injection Speed </option>
                        <option>Melt Temperature </option>
                        <option>Mold Temp A Side </option>
                        <option>Mold Temp B Side </option>
                        <option>Mold Temperature </option>
                        <option>Pack Pressure </option>
                        <option>Pack time </option>
                        <option>Screw Seed </option>
                        <option>Tonnage </option>
                      </select>
                    </td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td align="left" style={{ background: "#fff" }}>4</td>

                    <td align="left" style={{ background: "#fff" }}>
                      <select className="form-control">
                        <option>--Pick from list or type in-- </option>
                        <option>Back Pressure </option>
                        <option>Cooling Time </option>
                        <option>Cycle Time </option>
                        <option>Holding Pressure </option>
                        <option>Holding Time </option>
                        <option>Injection Pressure </option>
                        <option>Injection Speed </option>
                        <option>Melt Temperature </option>
                        <option>Mold Temp A Side </option>
                        <option>Mold Temp B Side </option>
                        <option>Mold Temperature </option>
                        <option>Pack Pressure </option>
                        <option>Pack time </option>
                        <option>Screw Seed </option>
                        <option>Tonnage </option>
                      </select>
                    </td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td align="left" style={{ background: "#fff" }}>5</td>

                    <td align="left" style={{ background: "#fff" }}>
                      <select className="form-control">
                        <option>--Pick from list or type in-- </option>
                        <option>Back Pressure </option>
                        <option>Cooling Time </option>
                        <option>Cycle Time </option>
                        <option>Holding Pressure </option>
                        <option>Holding Time </option>
                        <option>Injection Pressure </option>
                        <option>Injection Speed </option>
                        <option>Melt Temperature </option>
                        <option>Mold Temp A Side </option>
                        <option>Mold Temp B Side </option>
                        <option>Mold Temperature </option>
                        <option>Pack Pressure </option>
                        <option>Pack time </option>
                        <option>Screw Seed </option>
                        <option>Tonnage </option>
                      </select>
                    </td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>
                </tbody>
              </Table> */}
            </div>

            <div className="mt-3 mr-3 mb-3 col-md-12">
              <h6>Define Response</h6>

              <div className="table-responsive" id="DBTable">
                <table className="table" cellPadding={"0"}>
                  <tr>
                    <td>
                      <GridComponent
                        id="Grid"
                        dataSource={Responsedata}
                        columns={ResponseColumns}
                        height={40}
                      ></GridComponent>
                    </td>
                  </tr>
                </table>
              </div>

              {/* <Table
                className="table mb-2 table o-auto"
                // style={{ border: "1px solid black" }}
                // height={200}
                cellPadding={0}
                cellSpacing={0}
              >
                <thead>
                  <tr>
                    <th align="center" height="30px">
                      No
                    </th>

                    <th align="center">Response Name</th>

                    <th align="center">Nominal</th>

                    <th align="center">+Tol</th>

                    <th align="center">-Tol</th>

                    <th align="center">Sample Size</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}>
                      <input />
                    </td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td
                      align="left"
                      height="30px"
                      style={{ background: "#fff" }}
                    ></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>

                  <tr>
                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>

                    <td align="left" style={{ background: "#fff" }}></td>
                  </tr>
                </tbody>
              </Table> */}
            </div>

            <div>
              <button className="btn btn-info btn-air-info ml-3 mt-2 mb-2">
                {" "}
                Add Rows{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoenewSession;
