import React, { useEffect } from 'react';
import { SpreadsheetComponent, SheetsDirective, SheetDirective } from '@syncfusion/ej2-react-spreadsheet';
import { getRangeIndexes } from '@syncfusion/ej2-spreadsheet';
import '../App.css';

const CoolingGrid = ({ column, CoolingTimeGridData, setGrid2, CoolingTimeSpreadsheet, setColToBeDeleted, setRowToBeDeleted, rowCount, setColCount, colCount, PopulateSheet, GetDataFromSheet, setCoolingGridHeader, setArgs, RenderHeaders, showAlert }) => {

    const protectSettings = { selectCells: true };

    const scrollSettings = { isFinite: true };

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
            args.content = 'This cell is read only';
        }

        // Edit the dialog content using the dialogBeforeOpen event.
        if (args.dialogName === 'ValidationErrorDialog') {
            args.cancel = true
        }

    }

    useEffect(() => {

        // Adding custom function for calculating the percentage between two cells.
        CoolingTimeSpreadsheet.current.lockCells('A2:Z300', false)

        // eslint-disable-next-line
    }, [])

    const getIndex = () => {

        // Getting the index of the selected row and storing in a variable
        let cell = CoolingTimeSpreadsheet.current.getActiveSheet().activeCell;

        let cellIdx = getRangeIndexes(cell);

        setRowToBeDeleted(cellIdx[0] + 1)

        if (column.length <= 2 || cellIdx[1] === 0) {
            setColToBeDeleted(null);
        }
        else {
            setColToBeDeleted(cellIdx[1]);
        }

        GetDataFromSheet()

    }

    function onCreated() {

        //Applies data validation to specified range in the active sheet.
        CoolingTimeSpreadsheet.current.addDataValidation(
            {
                type: 'Decimal',
                operator: 'Between',
                value1: '-10000.0000',
                value2: '10000.0000',
            },
            `A1:Z${CoolingTimeGridData.length}`
        );

    }

    const UnlockCells = () => {

        PopulateSheet()

        if (CoolingTimeSpreadsheet) {
            CoolingTimeSpreadsheet.current.lockCells('A1:Z100', false);
        }

        CoolingTimeSpreadsheet.current.setColumnsWidth(120, ['A:Z']);

        CoolingTimeSpreadsheet.current.getActiveSheet().rowCount = rowCount || 5

    }

    const cellEditing = (args) => {

        if (args.value !== args.oldValue && !isNaN(args.value)) {

            CoolingTimeSpreadsheet.current.updateCell({ value: args.value }, args.address);

            showAlert.current = true

            getIndex()

        } else {

            showAlert.current = false

        }

    };

    const beforeCellRenderHandler = (args) => {

        PopulateSheet()

        setColCount(column.length)

        setArgs(args)

        RenderHeaders()

    }

    return (
        <>

            <div className="spreadsheet" onMouseEnter={UnlockCells}>

                <div>

                    <SpreadsheetComponent onBlur={getIndex} cellEdited={getIndex} cellEditing={cellEditing} ref={CoolingTimeSpreadsheet} width={"100%"} height={400} showFormulaBar={false} showSheetTabs={false} showRibbon={false} dialogBeforeOpen={dialogBeforeOpen.bind(this)} scrollSettings={scrollSettings} created={onCreated} beforeCellRender={beforeCellRenderHandler} allowAutoFill={false} enableContextMenu={false} allowImage={false}>

                        <SheetsDirective>

                            <SheetDirective colCount={colCount} isProtected={true} protectSettings={protectSettings}>

                            </SheetDirective>

                        </SheetsDirective>

                    </SpreadsheetComponent>

                </div>

            </div>

        </>
    )
}

export default CoolingGrid
