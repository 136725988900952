import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Header2 from "../../../common/header-component/header2";
// CSS
import "../../../App.css";
import { Table } from "reactstrap";

import { connect } from "react-redux";
import DataService from "../../../../services/ApiService";

import { useHistory, useParams } from "react-router-dom";

import moldDiagram from "../../../../assets/images/Mold_Diagram.jpg";

const MoldEdit = ({ user }) => {
  var { RowId } = useParams();

  const history = useHistory();

  const [column, setColumn] = useState([]);

  const [ImgModal, setImgModal] = useState(false);

  const ToggleImgModal = () => {
    setImgModal(!ImgModal);
  };

  const [SelectedMoldUnitData, setSelectedMoldUnitData] = useState({
    Mold_No: {
      value: "",
      unit_id: "",
    },
    Material_Id: {
      value: "",
      unit_id: "",
    },
    Platen_Orientation: {
      value: "",
      unit_id: "",
    },
    Number_of_Bases: {
      value: "",
      unit_id: "",
    },
    Hot_Runner_Volume: {
      value: "",
      unit_id: "",
    },
    Cycle_Time: {
      value: "",
      unit_id: "",
    },
    Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
    Mold_Vertical_Height: {
      value: "",
      unit_id: "",
    },
    Req_Mold_Open_Stroke: {
      value: "",
      unit_id: "",
    },
    Mold_Width: {
      value: "",
      unit_id: "",
    },
    Number_of_Core_Pulls: {
      value: "",
      unit_id: "",
    },
    Is_This_A_New_Mold: {
      value: "",
      unit_id: "",
    },
    Number_Of_Parts: {
      value: "",
      unit_id: "",
    },
    Part_Description: {
      value: "",
      unit_id: "",
    },
    Part_Number: {
      value: "",
      unit_id: "",
    },
    No_of_Cavities: {
      value: "",
      unit_id: "",
    },
    Starting_Cavity_Number: {
      value: "",
      unit_id: "",
    },
    Weight_of_one_Part: {
      value: "",
      unit_id: "",
    },
    Number_Of_Runners: {
      value: "",
      unit_id: "",
    },
    Runner_Weight: {
      value: "",
      unit_id: "",
    },
    Part_Projected_Area: {
      value: "",
      unit_id: "",
    },
    Runner_Projected_Area: {
      value: "",
      unit_id: "",
    },
  });

  const [partColumn, setpartColumn] = useState([
    {
      id: 0,
      Part: "",
      edit: false,
      delete: false,
    },
  ]);

  const [PartData, setPartData] = useState([
    {
      id: 0,
      edit: false,
      Cavity_Data: "Part Description *",
    },
    {
      id: 1,
      edit: true,
      Cavity_Data: "Part Number *",
    },
    {
      id: 2,
      edit: true,
      Cavity_Data: "Number of Cavities *",
    },
    {
      id: 3,
      edit: true,
      Cavity_Data: "Starting Cavity Number *",
    },
    {
      id: 4,
      edit: true,
      Cavity_Data: "Weight of one Part",
    },
    {
      id: 5,
      edit: true,
      Cavity_Data: "Number Of Runners",
    },
    {
      id: 6,
      edit: true,
      Cavity_Data: "Runner Weight",
    },
    {
      id: 7,
      edit: true,
      Cavity_Data: "Part Projected Area",
    },
    {
      id: 8,
      edit: true,
      Cavity_Data: "Runner Projected Area",
    },
  ]);

  const [SelectedRowId, setSelectedRowId] = useState(null);

  let BaseUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];
  let MaterialData = JSON.parse(sessionStorage.getItem("MaterialData")) || [];

  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);
  const [StoredUnits, setStoredUnits] = useState([]);

  const GoToEdit = () => {
    history.push({
      pathname: `/database/Options/${RowId}/MoldEdit`,
    });
  };

  const GetData = (id) => {
    if (SelectedRowId) {
      DataService.GetMold(SelectedRowId).then((res) => {
        // console.log(res.data);

        if (res.data) {
          setpartColumn(res.data.Part_Details);

          setPartData(res.data.Part_Data);

          setSelectedMoldUnitData(res.data.Units);

          // console.log(res.data.Units);
        } else {
          setSelectedMoldUnitData([]);
        }
      });
    }

    DataService.GetFieldsData(id).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].Mold_Custom_Fields.length > 0) {
          // console.log(res.data[0].Material_Custom_Fields);

          setColumn(res.data[0].Mold_Custom_Fields);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);

      let allUnits = [];

      // Iterate through each category in the data
      for (const category in BaseUnits.Unit_Data[0]) {
        // Add each unit object to the allUnits array
        allUnits = allUnits.concat(BaseUnits.Unit_Data[0][category]);
      }

      setBaseUnitsArray(BaseUnits.Unit_Data[0]);

      setStoredUnits(allUnits);
    }

    setSelectedRowId(atob(RowId));
  }, [user, RowId]);

  return (
    <>
      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "18px", color: "blue" }}
                onClick={() => {
                  history.push({
                    pathname: "/database/Database",
                    TabIdx: 0,
                  });
                }}
              >
                {" "}
                Database{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "20px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "18px", color: "blue" }}
                onClick={() => {
                  history.push({
                    pathname: "/database/Database",
                    TabIdx: 1,
                  });
                }}
              >
                {" "}
                Mold Database{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "20px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "18px" }}>
                {" "}
                View Mold{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card p-3  ml-2">
            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  onClick={GoToEdit}
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="d-flex col-md-12 mt-3">
              <div className="col-md-6">
                <div className="form-inline">
                  <Table
                    className="table-bordernone table-responsive"
                    style={{ background: "#fff" }}
                    cellPadding="0"
                    cellSpacing="0"
                    color="none"
                  >
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Mold No :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Mold_No"
                          value={SelectedMoldUnitData.Mold_No.value}
                          readOnly
                          style={{ width: 140 }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Material ID :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          className="form-control b-b-primary"
                          name="Material_Id"
                          value={
                            MaterialData.find(
                              (m) =>
                                m.id ===
                                parseInt(SelectedMoldUnitData.Material_Id.value)
                            )?.Material_Id
                          }
                          readOnly
                          style={{ width: 140 }}
                          data-fieldname={"Material_Id"}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Platen Orientation :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          className="form-control b-b-primary"
                          name="Platen_Orientation"
                          value={SelectedMoldUnitData.Platen_Orientation.value}
                          readOnly
                          style={{ width: 140 }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Number of Bases :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Number_of_Bases"
                          value={SelectedMoldUnitData.Number_of_Bases.value}
                          readOnly
                          style={{ width: 70 }}
                        />
                        <button
                          type="button"
                          className="form-control b-b-primary ml-1 text-center"
                          style={{ width: 40 }}
                          onClick={ToggleImgModal}
                        >
                          ?
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Hot Runner Volume :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          data-unit={
                            SelectedMoldUnitData.Hot_Runner_Volume.value
                          }
                          className="form-control b-b-primary"
                          name="Hot_Runner_Volume"
                          value={SelectedMoldUnitData.Hot_Runner_Volume.value}
                          readOnly
                          style={{ width: 70 }}
                        />
                        <span className="ml-2 pt-1">
                          {" "}
                          {BaseUnitsArray.Volume
                            ? BaseUnitsArray?.Volume[0].unit_name
                            : ""}{" "}
                        </span>
                      </td>
                    </tr>
                  </Table>
                </div>
              </div>

              <div className="ml-1 col-md-6">
                <div className="form-inline">
                  <Table
                    className="table-bordernone table-responsive"
                    style={{ background: "#fff" }}
                    cellPadding="0"
                    cellSpacing="0"
                    color="none"
                  >
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Cycle Time :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Cycle_Time"
                          data-unit={SelectedMoldUnitData.Cycle_Time.unit_id}
                          value={SelectedMoldUnitData.Cycle_Time.value}
                          readOnly
                          style={{ width: 70 }}
                        />
                        <input
                          className="form-control ml-1"
                          name="TimeUnit"
                          value={
                            StoredUnits?.find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  SelectedMoldUnitData?.Cycle_Time.unit_id
                                )
                            )?.unit_name
                          }
                          readOnly
                          style={{ width: 70 }}
                          data-fieldname={"Cycle_Time"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Mold Stack Height :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Mold_Stack_Height"
                          data-unit={
                            SelectedMoldUnitData.Mold_Stack_Height.unit_id
                          }
                          value={SelectedMoldUnitData.Mold_Stack_Height.value}
                          readOnly
                          style={{ width: 70 }}
                        />
                        <input
                          className="form-control ml-1"
                          name="DistanceUnit"
                          value={
                            StoredUnits?.find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  SelectedMoldUnitData?.Mold_Stack_Height
                                    .unit_id
                                )
                            )?.unit_name
                          }
                          readOnly
                          style={{ width: 70 }}
                          data-fieldname={"Mold_Stack_Height"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Mold Vertical Height :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Mold_Vertical_Height"
                          data-unit={
                            SelectedMoldUnitData.Mold_Vertical_Height.unit_id
                          }
                          value={
                            SelectedMoldUnitData.Mold_Vertical_Height.value
                          }
                          readOnly
                          style={{ width: 70 }}
                        />
                        <span className="ml-2 pt-1">
                          {
                            StoredUnits?.find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  SelectedMoldUnitData?.Mold_Stack_Height
                                    .unit_id
                                )
                            )?.unit_name
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Req Mold Open Stroke :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          data-unit={
                            SelectedMoldUnitData.Req_Mold_Open_Stroke.unit_id
                          }
                          name="Req_Mold_Open_Stroke"
                          value={
                            SelectedMoldUnitData.Req_Mold_Open_Stroke.value
                          }
                          readOnly
                          style={{ width: 70 }}
                        />
                        <span className="ml-2 pt-1">
                          {
                            StoredUnits?.find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  SelectedMoldUnitData?.Mold_Stack_Height
                                    .unit_id
                                )
                            )?.unit_name
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Mold Width :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          data-unit={SelectedMoldUnitData.Mold_Width.unit_id}
                          name="Mold_Width"
                          value={SelectedMoldUnitData.Mold_Width.value}
                          readOnly
                          style={{ width: 70 }}
                        />
                        <span className="ml-2 pt-1">
                          {
                            StoredUnits?.find(
                              (unit) =>
                                unit.unit_id ===
                                parseInt(
                                  SelectedMoldUnitData?.Mold_Stack_Height
                                    .unit_id
                                )
                            )?.unit_name
                          }
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td align="right" style={{ background: "#fff" }}>
                        Number of Core Pulls :
                      </td>
                      <td align="left" style={{ background: "#fff" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Number_of_Core_Pulls"
                          value={
                            SelectedMoldUnitData.Number_of_Core_Pulls.value
                          }
                          readOnly
                          style={{ width: 70 }}
                        />
                      </td>
                    </tr>
                  </Table>
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12 mt-3">
              <div
                className="ml-2 col-md-8 b-l-6 b-r-6"
                style={{ background: "#ebebeb" }}
              >
                <div className="form-inline">
                  <Table
                    className="table-bordernone table-responsive"
                    style={{ background: "#ebebeb" }}
                    cellPadding="0"
                    cellSpacing="0"
                    color="none"
                    width={100}
                    height={150}
                  >
                    <tr>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        Is This a Family Mold :
                      </td>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        <input
                          name="Is_This_A_New_Mold"
                          data-fieldname={"Is_This_A_New_Mold"}
                          className="form-control b-b-primary"
                          style={{ width: 70 }}
                          value={SelectedMoldUnitData.Is_This_A_New_Mold.value}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        Number of Parts :
                      </td>
                      <td align="left" style={{ background: "#ebebeb" }}>
                        <input
                          type="text"
                          className="form-control b-b-primary"
                          name="Number_Of_Parts"
                          defaultValue={
                            SelectedMoldUnitData.Number_Of_Parts.value
                          }
                          readOnly
                          style={{ width: 70 }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <button
                          type="text"
                          className="form-control b-b-primary ml-1 text-center"
                          readOnly
                          style={{ width: 40 }}
                        >
                          {" "}
                          set{" "}
                        </button>
                      </td>
                    </tr>

                    <tr colSpan="2">
                      <td align="left" style={{ background: "#ebebeb" }}>
                        Parts
                        <br />
                        <span className="text-danger">
                          *Fields can not be edited once saved
                        </span>
                      </td>
                    </tr>
                  </Table>

                  <Table className="table-responsive table-bordered">
                    <tr>
                      {partColumn.map((value, key1) => (
                        <React.Fragment key={key1}>
                          <th key={value.id}>
                            {key1 === 0 ? (
                              <span> {value.Part} </span>
                            ) : (
                              <span> {value.Part_No} </span>
                            )}
                          </th>
                        </React.Fragment>
                      ))}
                    </tr>

                    {PartData.map((value, key1) => (
                      <tr>
                        {partColumn.map((value2, key2) => (
                          <React.Fragment key={value2.id}>
                            {value2.edit === false ? (
                              <td style={{ backgroundColor: "#fff" }}>
                                {key1 === 4 ? (
                                  <input
                                    name="WeightUnit"
                                    data-fieldname={"Weight_of_one_Part"}
                                    className="form-control w-100"
                                    readOnly
                                    value={`Weight of one Part (${
                                      StoredUnits?.find(
                                        (unit) =>
                                          unit.unit_id ===
                                          parseInt(
                                            SelectedMoldUnitData
                                              ?.Weight_of_one_Part.unit_id
                                          )
                                      )?.unit_name
                                    })`}
                                  />
                                ) : key1 === 6 ? (
                                  <input
                                    name="WeightUnit"
                                    data-fieldname={"Weight_of_one_Part"}
                                    className="form-control w-100"
                                    readOnly
                                    value={`Runner Weight (${
                                      StoredUnits?.find(
                                        (unit) =>
                                          unit.unit_id ===
                                          parseInt(
                                            SelectedMoldUnitData?.Runner_Weight
                                              .unit_id
                                          )
                                      )?.unit_name
                                    })`}
                                  />
                                ) : key1 === 7 ? (
                                  <input
                                    className="form-control w-100"
                                    readOnly
                                    value={`Part Projected Area (${
                                      StoredUnits?.find(
                                        (unit) =>
                                          unit.unit_id ===
                                          parseInt(
                                            SelectedMoldUnitData
                                              ?.Part_Projected_Area.unit_id
                                          )
                                      )?.unit_name
                                    })`}
                                  />
                                ) : key1 === 8 ? (
                                  <input
                                    className="form-control"
                                    readOnly
                                    value={`Runner Projected Area (${
                                      StoredUnits?.find(
                                        (unit) =>
                                          unit.unit_id ===
                                          parseInt(
                                            SelectedMoldUnitData
                                              ?.Runner_Projected_Area.unit_id
                                          )
                                      )?.unit_name
                                    })`}
                                    style={{ width: 200 }}
                                  />
                                ) : (
                                  <>{value.Cavity_Data}</>
                                )}
                              </td>
                            ) : (
                              <React.Fragment key={value2.id}>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    data-index={key1}
                                    onKeyPress={(event) => {
                                      if (key1 === 2) {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      } else if (key1 === 3) {
                                        // Add any functionality here for key1 === 1 if needed
                                        if (/[^a-zA-Z0-9\s]/g.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    name={`Part${key2}`}
                                    readOnly
                                    defaultValue={
                                      PartData[key1][`Part${key2}`] || ""
                                    }
                                    style={{ width: 100 }}
                                    {...(key1 === 1 || key1 === 2 || key1 === 3
                                      ? { readOnly: true }
                                      : {})}
                                  />
                                </td>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </Table>
                </div>
              </div>

              <div className="col-md-4">
                <div className="ml-3 col-md-6">
                  <div className="row m-2">
                    <label> Custom Fields </label>
                  </div>

                  <div style={{ border: "1px solid black", width: "255px" }}>
                    <Table
                      id="Custom_Fields_Table"
                      className="table-responsive"
                      width={300}
                      height={200}
                      cellPadding={0}
                      cellSpacing={0}
                    >
                      <thead>
                        <tr>
                          <th align="center" style={{ width: "160px" }}>
                            Name
                          </th>

                          <th align="center" style={{ width: "160px" }}>
                            Value
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {column.map((HeaderName, HeaderKey) => (
                          <tr key={HeaderKey}>
                            <td align="left" style={{ background: "#fff" }}>
                              <input
                                type="text"
                                className="form-control"
                                style={{ width: "125px" }}
                                value={HeaderName.headerText}
                                readOnly
                              />{" "}
                            </td>

                            <td align="left" style={{ background: "#fff" }}>
                              <input
                                type="text"
                                className="form-control"
                                readOnly
                                name={`value${HeaderKey + 1}`}
                                style={{ width: "125px" }}
                              />{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal isOpen={ImgModal} centered={true} style={{ width: "500" }}>
          <ModalHeader> Mold Diagram </ModalHeader>
          <ModalBody>
            <div className="ml-4">
              <img src={moldDiagram} alt="Mold Diagram" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={ToggleImgModal}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MoldEdit);
