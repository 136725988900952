export let column_data = [
  {
    id: 0,
    Part: "",
    edit: false,
    delete: false,
  },
];

export let row_data = [
  {
    id: 0,
    edit: false,
    Cavity_Data: "Part Description *",
  },
  {
    id: 1,
    edit: true,
    Cavity_Data: "Part Number *",
  },
  {
    id: 2,
    edit: true,
    Cavity_Data: "Number of Cavities *",
  },
  {
    id: 3,
    edit: true,
    Cavity_Data: "Starting Cavity Number *",
  },
  {
    id: 4,
    edit: true,
    Cavity_Data: "Weight of one Part",
  },
  {
    id: 5,
    edit: true,
    Cavity_Data: "Number Of Runners",
  },
  {
    id: 6,
    edit: true,
    Cavity_Data: "Runner Weight",
  },
  {
    id: 7,
    edit: true,
    Cavity_Data: "Part Projected Area",
  },
  {
    id: 8,
    edit: true,
    Cavity_Data: "Runner Projected Area",
  },
];
