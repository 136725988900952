import { GET_PPS_DATA, NO_PPS_DATA } from "../actions/types";

const initialState = {
  PPS_DATA: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PPS_DATA:
      sessionStorage.setItem("PPS_DATA", JSON.stringify(payload));
      return {
        ...state,
        PPS_DATA: payload,
      };
    case NO_PPS_DATA:
      return {
        ...state,
        PPS_DATA: [],
      };
    default:
      return state;
  }
}
