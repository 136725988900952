import React, { useState } from "react";
import "./style.css";

const Distance = () => {
  const [inputValue, SetInputValue] = useState("");
  const [outputValue, SetOutputValue] = useState("");
  const [baseUnit, SetBaseUnit] = useState("mm");
  const [resultUnit, SetResultUnit] = useState("mm");
  const distances = ["mm", "cm", "m", "in", "feet"];

  let calcDistance = (event) => {
    // function to calculate area conversions
    event.preventDefault(); //prevent submitting

    if (inputValue.isNaN) {
      alert("Please enter a value");
    } else if (baseUnit === resultUnit) {
      SetOutputValue(inputValue);
    } else {
      if (baseUnit === "mm" && resultUnit === "cm") {
        let results = Number(inputValue * 0.1).toFixed(2);
        SetOutputValue(results);
      } else if (baseUnit === "mm" && resultUnit === "m") {
        let results = inputValue * 0.001;
        SetOutputValue(results);
      } else if (baseUnit === "mm" && resultUnit === "in") {
        let results = inputValue / 25.4;
        SetOutputValue(results);
      } else if (baseUnit === "mm" && resultUnit === "feet") {
        let results = inputValue / 304.8;
        SetOutputValue(results);
      } else if (baseUnit === "cm" && resultUnit === "mm") {
        let results = inputValue * 10;
        SetOutputValue(results);
      } else if (baseUnit === "cm" && resultUnit === "m") {
        let results = inputValue * 0.01;
        SetOutputValue(results);
      } else if (baseUnit === "cm" && resultUnit === "in") {
        let results = inputValue / 2.54;
        SetOutputValue(results);
      } else if (baseUnit === "cm" && resultUnit === "feet") {
        let results = inputValue / 30.48;
        SetOutputValue(results);
      } else if (baseUnit === "m" && resultUnit === "mm") {
        let results = inputValue * 1000;
        SetOutputValue(results);
      } else if (baseUnit === "m" && resultUnit === "cm") {
        let results = inputValue * 100;
        SetOutputValue(results);
      } else if (baseUnit === "m" && resultUnit === "in") {
        let results = inputValue * 39.37;
        SetOutputValue(results);
      } else if (baseUnit === "m" && resultUnit === "feet") {
        let results = inputValue * 3.281;
        SetOutputValue(results);
      } else if (baseUnit === "in" && resultUnit === "mm") {
        let results = inputValue * 25.4;
        SetOutputValue(results);
      } else if (baseUnit === "in" && resultUnit === "cm") {
        let results = inputValue * 2.54;
        SetOutputValue(results);
      } else if (baseUnit === "in" && resultUnit === "m") {
        let results = inputValue * 0.0254;
        SetOutputValue(results);
      } else if (baseUnit === "in" && resultUnit === "feet") {
        let results = inputValue / 12;
        SetOutputValue(results);
      } else if (baseUnit === "feet" && resultUnit === "mm") {
        let results = inputValue * 304.8;
        SetOutputValue(results);
      } else if (baseUnit === "feet" && resultUnit === "cm") {
        let results = inputValue * 30.48;
        SetOutputValue(results);
      } else if (baseUnit === "feet" && resultUnit === "m") {
        let results = inputValue * 0.3048;
        SetOutputValue(results);
      } else if (baseUnit === "feet" && resultUnit === "in") {
        let results = inputValue * 12;
        SetOutputValue(results);
      }
    }
  };

  let reload = () => {
    SetInputValue('')
    SetOutputValue('')
  };

  const onDropDownChange = (e) => {

    SetResultUnit(e.target.value)
    SetOutputValue('')

  }

  return (
    <div className="d-flex justify-content-center">
      <div className="b-primary b-r-4 mb-2 col-md-7">
      <h6 className="mt-3 ml-3">Distance</h6>

        <form onSubmit={calcDistance}>
          <div>

            <input
              className="input_style"
              name="inputValue"
              type="text"
              onKeyPress={(event) => {
                if (!/[0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={inputValue}
              onChange={(e) => SetInputValue(e.target.value)}
            />

            <select
              name="baseUnit"
              value={baseUnit}
              onChange={(e) => SetBaseUnit(e.target.value)}
            >
              {distances.map((tmp) => (
                <option key={tmp} value={tmp}>
                  {tmp}
                </option>
              ))}
            </select>

          </div>

          <div>

            <input
              className="input_style"
              name="outputValue"
              type="text"
              onKeyPress={(event) => {
                if (!/[0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={outputValue}
              disabled={true}
              onChange={(e) => SetOutputValue(e.target.value)}
            />


            <select
              name="resultUnit"
              value={resultUnit}
              onChange={onDropDownChange}
            >
              {distances.map((tmp) => (
                <option key={tmp} value={tmp}>
                  {tmp}
                </option>
              ))}
            </select>

          </div>

          <button type="submit" className='btn btn-info ml-3'>Convert</button>
          <button className='btn btn-dark ml-3' onClick={reload}>Reset</button>
        </form>

        <div>
          <p>
            The Equivalent value of {inputValue} {baseUnit} is {outputValue}{" "}
            {resultUnit}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Distance;
