import React, { useState } from "react";
import "./MachineInformation.css";

const MachineInformation = () => {
  const [pressure, setPressure] = useState("");
  const [temperature, setTemperature] = useState("");
  const [distance, setDistance] = useState("");
  const [time, setTime] = useState("");
  const [velocity, setVelocity] = useState("");
  const [weight, setWeight] = useState("");

  const handlePrint = () => {
    window.print();
  };

  const handleSave = () => {
    alert("Data saved successfully!");
  };

  const handleClose = () => {
    window.close();
  };

  return (
    <div className="Machine_info_container">
      
      {/* Machine Information Section */}
      {/* *********************************** 1st Section *********************************** */}
      <div className="quadrant">
        <div className="formGroup">
          <label className="label">Processor :</label>
          <input type="text" className="textBox" />
          <label className="labelDate">Date:</label>
          <input type="date" className="datePicker" />
        </div>
        <div className="formGroup">
          <label className="label">Process Sheet ID: *</label>
          <input type="text" className="textBoxWide" />
        </div>
        <div className="formGroup">
          <label className="label">Notes :</label>
          <textarea className="rtfBoxWide" />
        </div>
      </div>

      {/* Nozzle Settings Section */}
      {/* *********************************** 2nd Section *********************************** */}
      <div className="nozzleSettingsContainer">
        <div className="nozzleSettings">
          {/* Existing Nozzle Settings */}
          <div className="existingNozzleSettings">
            <div className="formGroup">
              <label className="label">Pressure* :</label>
              <select
                className="selectBox"
                value={pressure}
                onChange={(e) => setPressure(e.target.value)}
              >
                <option value="psi">psi</option>
                <option value="MPa">MPa</option>
                <option value="bar">bar</option>
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Temperature* :</label>
              <select
                className="selectBox"
                value={temperature}
                onChange={(e) => setTemperature(e.target.value)}
              >
                <option value="degF">deg F</option>
                <option value="degC">deg C</option>
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Distances* :</label>
              <select
                className="selectBox"
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
              >
                <option value="inches">in</option>
                <option value="mm">mm</option>
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Time* :</label>
              <select
                className="selectBox"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              >
                <option value="sec">sec</option>
                <option value="min">min</option>
                <option value="hrs">hrs</option>
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Velocity* :</label>
              <select
                className="selectBox"
                value={velocity}
                onChange={(e) => setVelocity(e.target.value)}
              >
                <option value="inches/sec">inches/sec</option>
                <option value="mm/sec">mm/sec</option>
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Weight* :</label>
              <select
                className="selectBox"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              >
                <option value="Gms">Gms</option>
                <option value="Kg">kg</option>
                <option value="oz">oz</option>
                <option value="lbs">lbs</option>
              </select>
            </div>
          </div>

          {/* New Box on the Right */}
          <div className="nozzleSettingsBox">
            <div className="nozzleSettingsHeader">Nozzle Settings</div>
            <div className="nozzleSettingsContent">
              <div className="nozzleSettingsItem">
                <label className="nozzleSettingsLabel">Nozzle Type :</label>
                <input type="text" className="smallTextBox" />
              </div>
              <div className="nozzleSettingsItem">
                <label className="nozzleSettingsLabel">Nozzle Length :</label>
                <input type="text" className="smallTextBox" />
              </div>
              <div className="nozzleSettingsItem">
                <label className="nozzleSettingsLabel">
                  Nozzle Orifice Size :
                </label>
                <input type="text" className="smallTextBox" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mold Information Section */}
      {/* *********************************** 3rd Section *********************************** */}
      <div className="quadrant">
        <h1>3</h1>
      </div>

      {/* Machine Number Section */}
      {/* *********************************** 4th Section *********************************** */}
      <div className="quadrant">
        <h1>4</h1>
      </div>

      {/* Fixed Bottom Pane */}
      <div className="fixedBottomPane">
        <span>Pressure: {pressure} </span>
        <span>Temp: {temperature} </span>
        <span>Distance: {distance} </span>
        <span>Time: {time} </span>
        <span>Velocity: {velocity} </span>
        <span>Weight: {weight} </span>
        <div className="buttonGroup">
          <button
            className="btn btn-info btn-air-info ml-3"
            onClick={handlePrint}
          >
            Print
          </button>
          <button
            className="btn btn-info btn-air-info ml-3"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="btn btn-info btn-air-info ml-3"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
      
    </div>
  );
};

export default MachineInformation;
