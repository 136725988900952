import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ColdAddRow = ({ toggle2, modal2, addRow, increaseRow }) => {

  return (
    <div /*className="btn-showcase"*/>
      <button
        className="btn btn-info btn-air-info mr-2"
        type="button"
        onClick={toggle2}
      >
        {" "}
        Add Row{" "}
      </button>
      <Modal isOpen={modal2} toggle={toggle2} centered={true}>
        <ModalHeader toggle={toggle2}>Add Row</ModalHeader>
        <ModalBody>
          <form autoComplete="off">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="add-column" className="lbl_style">
                        Enter Number Of Rows:{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Number Of Rows"
                      name="rows"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={addRow}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={increaseRow}>
            {" "}
            Add{" "}
          </Button>
          <Button color="fourth" onClick={toggle2}>
            {" "}
            Cancel{" "}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ColdAddRow;
