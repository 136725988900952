import React from "react";
import ProcessMachineGrid from "./ProcessMachineGrid";

const ProcessMachine = ({ MachineDataList, SelectedMachine, ProcessRefMachineData, ProcessTgtMachineData }) => {

  return (
    <div className="b-primary b-r-4 p-3 ml-2" style={{ border: "1px grey solid"}}>
      <ProcessMachineGrid
        MachineDataList={MachineDataList}
        SelectedMachine={SelectedMachine}
        ProcessRefMachineData={ProcessRefMachineData}
        ProcessTgtMachineData={ProcessTgtMachineData}
      />
    </div>
  );
};

export default ProcessMachine;
