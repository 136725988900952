import React, { useState } from "react";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "../../App.css";
import DataService from "../../../services/ApiService";

const CavitySelection = ({ session, Cavities, onClose }) => {
  const [status, setStatus] = useState({ hideDialog: true });
  const [checkboxes, setCheckboxes] = useState(
    Cavities.map(() => ({
      all: true,
      calculate: true,
      display: true,
    }))
  );


  const buttons = [
    {
      buttonModel: {
        content: "Ok",
        cssClass: "btn",
        isPrimary: true,
      },
      click: () => {
        const selectedDisplay = [];
        const excludedDisplay = [];
        const selectedCalculate = [];
        const excludedCalculate = [];

        Cavities.forEach((item, index) => {
          const unit =item[0].replace("dimension~", "")
          if (checkboxes[index].display) {
            selectedDisplay.push(unit);
          } else {
            excludedDisplay.push(unit);
          }
          if (checkboxes[index].calculate) {
            selectedCalculate.push(unit);
          } else {
            excludedCalculate.push(unit);
          }
        });
       
        const data = {
          session_id: session,
          display: selectedDisplay,
          excludeDisplay: excludedDisplay,
          calculate: selectedCalculate,
          excludeCalaulate: excludedCalculate
        };
       
        // console.log(data)
        DataService.PostNumericalPrediction(session, data);
        setStatus({ hideDialog: false });
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "btn",
      },
      click: () => {
        setStatus({ hideDialog: false });
      },
    },
  ];


  function dialogClose() {
    setStatus({ hideDialog: false });
    if (onClose) onClose();
  }

  const handleAllChange = (index) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      const allChecked = !newCheckboxes[index].all;

      newCheckboxes[index] = {
        all: allChecked,
        calculate: allChecked,
        display: allChecked,
      };

      return newCheckboxes;
    });
  };

  const handleCheckChange = (index, type) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index][type] = !newCheckboxes[index][type];

      // Check if both 'calculate' and 'display' are checked
      const bothChecked =
        newCheckboxes[index].calculate && newCheckboxes[index].display;

      // Update 'all' based on 'calculate' and 'display'
      newCheckboxes[index].all = bothChecked;

      return newCheckboxes;
    });
  };

  const handleSelectAll = () => {
    setCheckboxes(
      Cavities.map(() => ({
        all: true,
        calculate: true,
        display: true,
      }))
    );
  };

  const renderTableRows = () => {
    return Cavities.map((item, index) => (
      <tr key={index}>
        <td>
          {item[0]
            .replace(/dimension~/g, "")
            .charAt(0)
            .toUpperCase() + item[0].replace(/dimension~/g, "").slice(1)}
        </td>
        <td style={{ textAlign: "center" }}>
          <input
            type="checkbox"
            id={`all-${index}`}
            checked={checkboxes[index]?.all || false}
            onChange={() => handleAllChange(index)}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <input
            type="checkbox"
            id={`calculate-${index}`}
            checked={checkboxes[index]?.calculate || false}
            onChange={() => handleCheckChange(index, "calculate")}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <input
            type="checkbox"
            id={`display-${index}`}
            checked={checkboxes[index]?.display || false}
            onChange={() => handleCheckChange(index, "display")}
          />
        </td>
      </tr>
    ));
  };

  return (
    <div
      className="App"
      id="dialog-target"
      // style={{top:"20px" , position:"relative", width:"400px", height:"400px" }}
    >
      <DialogComponent
        width="30%"
        height="50%"
        target="#dialog-target"
        close={dialogClose}
        header="Dimension and Cavity Selection"
        visible={status.hideDialog}
        showCloseIcon={false}
        buttons={buttons}
        isModal={true}
      >
        <div
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            position: "sticky",
            top: "0",
            zIndex: "2000",
            background: "#fff",
          }}
        >
          <button
            onClick={handleSelectAll}
            className="btn btn-secondary btn-air-secondary m-2"
          >
            Select All
          </button>
        </div>
        <table className="e-table o-auto">
          <thead
            style={{
              marginTop: "30px",
              position: "sticky",
              top: "45px",
              zIndex: "1998",
              background: "#fff",
            }}
          >
            <tr>
              <th>Dimension</th>
              <th>All</th>
              <th>Calculate</th>
              <th>Display</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </DialogComponent>
    </div>
  );
};

export default CavitySelection;
