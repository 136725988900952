import React, { useEffect } from 'react'
import {
    SpreadsheetComponent, SheetsDirective, SheetDirective, ColumnsDirective, ColumnDirective, getCell,
} from '@syncfusion/ej2-react-spreadsheet';
import '../App.css';
import '../../assets/custom-stylesheet/grid_stylecss.css';

const CosmeticGrid = ({ Melting, Hydraulic, CosmeticSpreadsheet, setCosmeticGridData, showAlert }) => {

    const protectSettings = { selectCells: true };

    const scrollSettings = { isFinite: true };

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
            args.content = 'This cell is read only';
        }

        // Edit the dialog content using the dialogBeforeOpen event.
        if (args.dialogName === 'ValidationErrorDialog') {
            args.cancel = true
        }

    }

    useEffect(() => {

        // Adding custom function for calculating the percentage between two cells.
        CosmeticSpreadsheet.current.lockCells('A2:C3', false)

        // eslint-disable-next-line
    }, [])

    const getIndex = () => {

        let TempArray = [];

        for (let i = 0; i < 2; i++) {

            TempArray.push({

                "Melt_Temp": getCell(i, 0, CosmeticSpreadsheet.current.getActiveSheet()).value ? getCell(i, 0, CosmeticSpreadsheet.current.getActiveSheet()).value : "",

                "Low": getCell(i, 1, CosmeticSpreadsheet.current.getActiveSheet()).value ? getCell(i, 1, CosmeticSpreadsheet.current.getActiveSheet()).value : "",

                "High": getCell(i, 2, CosmeticSpreadsheet.current.getActiveSheet()).value ? getCell(i, 2, CosmeticSpreadsheet.current.getActiveSheet()).value : ""

            })

        }

        setCosmeticGridData(TempArray)

        // console.log(TempArray)

    }

    const beforeCellRenderHandler = (args) => {

        // Condition to check whether the rendered element is header cell.
        if (
            args.colIndex === 0 &&
            args.element.classList.contains('e-header-cell')
        ) {
            const text = `${Melting}`
            args.element.innerText = text;
        }
        if (
            args.colIndex === 1 &&
            args.element.classList.contains('e-header-cell')
        ) {
            const text = `Low ${Hydraulic}`
            args.element.innerText = text;
        }
        if (
            args.colIndex === 2 &&
            args.element.classList.contains('e-header-cell')
        ) {
            const text = `High ${Hydraulic}`
            args.element.innerText = text;
        }

    }

    function onCreated() {

        //Applies data validation to specified range in the active sheet.
        CosmeticSpreadsheet.current.addDataValidation(
            {
                type: 'Decimal',
                operator: 'Between',
                value1: '-10000.0000',
                value2: '10000.0000',
            },
            `A1:C${CosmeticSpreadsheet.current.getActiveSheet().rowCount}`
        );

    }

    const UnlockCells = () => {

        if (CosmeticSpreadsheet) {
            CosmeticSpreadsheet.current.lockCells('A1:C3', false);
        }

    }

    const cellEditing = (args) => {

        if (args.value !== args.oldValue && !isNaN(args.value)) {

            CosmeticSpreadsheet.current.updateCell({ value: args.value }, args.address);

            showAlert.current = true

            getIndex()

        }else {

            showAlert.current = false
      
        }

    };

    return (
        <div>
            
            <div id="CosameticGrid" className="spreadsheet" onMouseEnter={UnlockCells}>

                <SpreadsheetComponent onBlur={getIndex} cellEdited={getIndex} cellEditing={cellEditing} ref={CosmeticSpreadsheet} height={400} width={"100%"} showFormulaBar={false} showSheetTabs={false} showRibbon={false} dialogBeforeOpen={dialogBeforeOpen.bind(this)} scrollSettings={scrollSettings} beforeCellRender={beforeCellRenderHandler} allowAutoFill={false} created={onCreated}enableContextMenu={false} allowImage={false}>

                    <SheetsDirective>

                        <SheetDirective showHeaders={true} rowCount={2} colCount={3} isProtected={true} protectSettings={protectSettings}>

                            <ColumnsDirective>

                                <ColumnDirective width={130} />
                                <ColumnDirective width={130} />
                                <ColumnDirective width={130} />

                            </ColumnsDirective>

                        </SheetDirective>

                    </SheetsDirective>

                </SpreadsheetComponent>

            </div>
        </div>

    )
}

export default CosmeticGrid
