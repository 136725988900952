import React from 'react';
import { ChartComponent, LineSeries, SeriesCollectionDirective, Inject, SeriesDirective, Category, DataLabel } from '@syncfusion/ej2-react-charts';
import { HtmlEditor, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';

import '../../assets/custom-stylesheet/excel_table.css'

const PressurePrint = ({ Units, Max_Press_Available, PressureGridRow, Mold_Name, Session_Name, MaxPressData, Comment, ChartData, niceNumbers }) => {

  return (
    <div className='Print'>
      <div>
        <h1> Pressure Drop Study Data </h1>
      </div>

      {/* Div for input's */}
      <div className='Input-Table'>
        <table>
          <thead>
            <tr>
              <th> Mold Id </th>
              <th> Session Name </th>
              <th> Units  </th>
              <th> Max Pressure Available </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {Mold_Name} </td>
              <td> {Session_Name} </td>
              <td> {Units ? Units : "psi"} </td>
              <td> {Max_Press_Available} </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Div for Grid */}
      <div>
        <h3> Data View </h3>
      </div>

      <div className="excel-table">
        <table>
          <thead>
            <tr>
              <th>
                <span> Flow Area </span>
              </th>
              <th>
                <span> Peak Pressure </span>
              </th>
              <th>
                <span> % Maximum </span>
              </th>
              <th>
                <span> Delta P </span>
              </th>
              <th>
                <span> % Delta P </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {PressureGridRow.map((PressureRow, rowId) => (
              <tr key={rowId}>

                <td> {PressureRow.Flow_Area} </td>

                <td> {PressureRow.Peak_Pressure} </td>

                <td> {PressureGridRow[rowId].Percent_Maximum === "" ? ('-') : (Number(PressureGridRow[rowId].Percent_Maximum).toFixed(3))} </td>

                <td> {PressureRow.Peak_Pressure ? rowId === 0 ? (PressureGridRow[rowId].Peak_Pressure) : (PressureGridRow[rowId].Peak_Pressure === "" ? '-' : (Math.round(PressureGridRow[rowId].Peak_Pressure - PressureGridRow[rowId - 1].Peak_Pressure))) : "--"} </td>

                <td> {PressureRow.Peak_Pressure ? rowId === 0 ? (PressureGridRow[rowId].Percent_Maximum === "" ? '-' : Number(PressureGridRow[rowId].Percent_Maximum).toFixed(3)) : (PressureGridRow[rowId].Peak_Pressure === "" ? '-' : (Number((PressureGridRow[rowId].Peak_Pressure - PressureGridRow[rowId - 1].Peak_Pressure) * 100 / (Max_Press_Available)).toFixed(3))) : "--"} </td>

              </tr>

            ))}
          </tbody>
        </table>
      </div>

      {/* Div for Chart */}
      <div className='Printable-Chart'>
        <ChartComponent title="Pressure Drop Study" primaryXAxis={{ valueType: "Category", title: "Flow Area" }} primaryYAxis={{ title: "Peak Pressure", minimum: niceNumbers.min, maximum: niceNumbers.max, interval: niceNumbers.step }}>

          <Inject services={[LineSeries, Category, DataLabel]} />

          <SeriesCollectionDirective>

            <SeriesDirective type="Line" dataSource={MaxPressData} xName="Flow_Area" yName="Max_Press_Available" fill="rgb(255,0,0)" width={2.5}></SeriesDirective>

            <SeriesDirective type="Line" dataSource={ChartData} xName="Flow_Area" yName="Peak_Pressure" marker={{ visible: true }} width={2.5}></SeriesDirective>

          </SeriesCollectionDirective>

        </ChartComponent>
      </div>

      {/* Div for Comment */}
      <div className='Print pb-4'>

        <div>
          <h3> Comments </h3>
        </div>

        <div style={{ border: "2px solid black" }}>
          <RichTextEditorComponent
            value={Comment}
            height={200}
            width={800}
          >
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>

      </div>

    </div>
  )
}

export default PressurePrint;