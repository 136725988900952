import React from 'react';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, ColumnsDirective, ColumnDirective, RowsDirective, RowDirective, CellsDirective, CellDirective } from '@syncfusion/ej2-react-spreadsheet';
import '../App.css';

const ColdGrid2 = ({ ColdGridData, setRowCount, grid2, ColdRunnerCalcSpreadsheet, ColdGridHeader }) => {

    const protectSettings = { selectCells: true };

    const scrollSettings = { isFinite: true };

    const dialogBeforeOpen = (args) => {

        if (args.dialogName === 'EditAlertDialog') {
            args.cancel = true
        }

    }

    function onCreated() {

        ColdRunnerCalcSpreadsheet.current.cellFormat({ backgroundColor: '#21325E', color: '#fff' }, 'A1:B1');

        ColdRunnerCalcSpreadsheet.current.refresh();

    }

    const beforeCellRenderHandler = (args) => {

        setRowCount(ColdGridData.length)

        ColdRunnerCalcSpreadsheet.current.cellFormat({ backgroundColor: '#21325E', color: '#fff' }, 'A1:B1');

    }

    return (
        <>
            <div>

                <div>

                    <SpreadsheetComponent ref={ColdRunnerCalcSpreadsheet} width={"100%"} height={200} showFormulaBar={false} showSheetTabs={false} showRibbon={false} dialogBeforeOpen={dialogBeforeOpen.bind(this)} scrollSettings={scrollSettings} beforeCellRender={beforeCellRenderHandler} created={onCreated} allowAutoFill={false} enableContextMenu={false} allowImage={false}>

                        <SheetsDirective>

                            <SheetDirective showHeaders={false} rowCount={ColdGridData.length + 1} colCount={2} isProtected={true} protectSettings={protectSettings}>

                                <RowsDirective>

                                    <RowDirective index={0}>

                                        <CellsDirective>

                                            <CellDirective value={`Weight Increase [${ColdGridHeader}]`}></CellDirective>

                                            <CellDirective value={`% Weight Increase [${ColdGridHeader}]`}></CellDirective>

                                        </CellsDirective>

                                    </RowDirective>

                                    {ColdGridData.map((ColdData, key1) => {

                                        return (<RowDirective key={key1}>

                                            {grid2 ? <CellsDirective>

                                                <CellDirective value={ ColdGridData[key1][`${grid2}`] ? key1 === 0 ? ('-') : (isNaN(ColdGridData[key1][`${grid2}`]) ? '-' : (Number(ColdGridData[key1][`${grid2}`] - ColdGridData[key1 - 1][`${grid2}`]).toFixed(3))) : "--"}></CellDirective>

                                                <CellDirective value={ ColdGridData[key1][`${grid2}`] ? key1 === 0 ? ('-') : (isNaN(ColdGridData[key1][`${grid2}`]) ? '-' : (Number((ColdGridData[key1][`${grid2}`] - ColdGridData[key1 - 1][`${grid2}`]) / (ColdGridData[key1 - 1][`${grid2}`]) * 100).toFixed(3))) : "--"} ></CellDirective>

                                            </CellsDirective> : <CellsDirective>

                                                <CellDirective value={"-"}></CellDirective>

                                                <CellDirective value={"-"} ></CellDirective>

                                            </CellsDirective>}

                                        </RowDirective>)
                                    })}

                                </RowsDirective>

                                <ColumnsDirective>

                                    <ColumnDirective width={200} />
                                    <ColumnDirective width={200} />

                                </ColumnsDirective>

                            </SheetDirective>

                        </SheetsDirective>

                    </SpreadsheetComponent>

                </div>
            </div>
        </>
    )
}

export default ColdGrid2;