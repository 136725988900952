import React from 'react'
import SuggestedDimChart from './SuggestedDimChart';


const SuggestedDim = ({
  title,
  Result,
  RangeColumnData,
  ScatterData,
  x_axis,
  GoldSpot,

}) => {
  return (
    <>
        {title.map((item, index) => (
                  <SuggestedDimChart
                    key={index}
                    title={item}
                    result={Result[index]}
                    rangeColumnData={RangeColumnData[index]}
                    scatterData={ScatterData[index]}
                    x_axis={x_axis}
                    GoldSpot = {GoldSpot[index]}
                   
                  />
                ))}
                
    </>
  )
}

export default SuggestedDim
