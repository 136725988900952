import React from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const HopperSizeOutput = ({ result }) => {

    return (
        <div>

            <div style={{ fontWeight: "bold", fontSize: '25px' }}>
                <span> Output </span>
            </div>

            <hr></hr>

            <Form>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>

                        <Col sm={3}>
                            <Form.Label>Min Dryer Size</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="screwDiameter" type="number" value={result.minSize} readOnly />
                        </Col>

                        <Col sm={1}>
                            <Form.Label>kg</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="screwDiameter" type="number" value={Number(result.minSize * 2.20462).toFixed(0)} readOnly />
                        </Col>

                        <Col sm={1}>
                            <Form.Label>lbs</Form.Label> </Col>

                    </Row>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicnumber">
                    <Row>
                        <Col sm={3}>
                            <Form.Label>Max Dryer Size</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="shotWeight" type="number" value={result.maxSize} readOnly />
                        </Col>

                        <Col sm={1}>
                            <Form.Label>kg</Form.Label> </Col>

                        <Col sm={3}>
                            <Form.Control name="screwDiameter" type="number" value={Number(result.maxSize * 2.20462).toFixed(0)} readOnly />
                        </Col>

                        <Col sm={1}>
                            <Form.Label>lbs</Form.Label> </Col>
                    </Row>
                </Form.Group>
            </Form>
        </div>
    )
}

export default HopperSizeOutput