import React from "react";
import ContourPlot from "./ContourPlot";

const ContourChart = ({data, session, limit}) => {

  // console.log(limit)

  return (
    <>
      <div>
      {/* Calling and Passing data to the component */}

      <h6><b>Contour Plot</b></h6>

        {data.contour_data &&
          data.axis_limits.x_axis_limits &&
          data.axis_limits.y_axis_limits &&
          data.contour_lines && (

            <ContourPlot
              data={data.contour_data || []} 
              n={data.contour_lines || []}
              x_axis={data.axis_limits.x_axis_limits || []}
              y_axis={data.axis_limits.y_axis_limits || []}
              axis_elements={data.axis_elements || []}
              limit = {limit || []}
              num_subarrays= {data.num_subarrays || 11}
            />

          )}

      </div>

    </>
  );
};

export default ContourChart;
