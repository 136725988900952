import React, { useEffect, useState, useRef } from "react";

// This is a modal imported from modals folder since we have a button i.e Generate Injection Speed this modal gets displayed
// import Viscocity from '../modals/Viscocity';

// CSS
import "../../App.css";

// This is the Grid/Table of our viscosity curve import from Grids folder
import ViscocityGrid from "../../Grids/ViscocityGrid";

// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";

// A package which scroll's to different section of page on button click
import { scroller } from "react-scroll";

import ViscocityAddRow from "../../columns&rows/ViscocityAddRow";

import { useReactToPrint } from "react-to-print";

import ViscosityPrint from "../../printables/ViscosityPrint";

// Event having multiple method's to deal with the back-end
import DataService from "../../../services/ApiService";

import InputSection from "./InputSection";
import ButtonSection from "./ButtonSection";
import ChartSection from "./ChartSection";

import Loader from "../../../assets/Loader";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ViscocityCurve = ({ showAlert, ViscosityData, ToSaveViscosityData }) => {
  // Set's the visibility of the Generate Injection Speed modal

  // const [modal, setModal] = useState();
  // const toggle = () => {
  //     setModal(!modal)
  // }

  const [isLoading, setIsLoading] = useState(true);

  // There is an input field in viscosity curve asking for Intensification Ratio so this is a variable which holds the value of it and is used for calculations wherever needed.
  const [IntensificationRatio, setIntensificationRatio] = useState(1);
  
  const [Injection_Speed_Units, setInjection_Speed_Units] = useState("in/sec");
  
  const [Pressure_Units, setPressure_Units] = useState("");
  const [ViscosityGridData, setViscosityGridData] = useState([]);

  const [YTitle, setYTitle] = useState("");

  // Total Number of row's to be displayed in the ViscositySpreadsheet
  const [rowCount, setRowCount] = useState(10);

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  // Set's the visibility of the modal which we use to get the number of row's to be generated which is imported in Viscosity Grid.
  const [ViscosityAddRowModal, setViscosityAddRowModal] = useState();

  const ToggleAddRowModal = () => {
    setViscosityAddRowModal(!ViscosityAddRowModal);
  };

  const [InjectionSpeedAlert, setInjectionSpeedAlert] = useState(true);

  const ToggleInjectionSpeedAlert = () => {
    setInjectionSpeedAlert(!InjectionSpeedAlert);
  };

  let ViscositySpreadsheet = useRef();

  // As the user enter's the number of row's it get's set in this variable.
  const [row, setRow] = useState(null);

  const [rowToBeDeleted, setRowToBeDeleted] = useState();

  const [Alert, setAlert] = useState(false);

  // This is the event to do the above said thing.
  const addRow = (e) => {
    e.preventDefault();

    // console.log(e.target.value)

    // Storing the number entered
    setRow(e.target.value);
    // console.log(e.target.value);
  };

  // This is the event which gets called as the user click's ok in the add row modal.
  // what it does is it run's a loop as many times the row variable is and along with that it pushes an object containing all the key's based on the grid with an id generated using nanoid library and then set's the row1 in the main array i.e ViscosityGridData.

  // Then using editFormData object, handleEditFormChange and handleEditFormSubmit we store the data in these objects as the user enter's in the grid's input field's
  const increaseRow = () => {
    // console.log(row)

    // Updating the total rows variable
    if (row) {
      setRowCount(parseInt(rowCount) + parseInt(row));

      // console.log(row)

      showAlert.current = true;

      setAlert(true);

      setRow(null);

      ToggleAddRowModal();
    } else {
      alert("Please enter number of rows.");
    }
  };

  const PopulateViscositySheet = (CalcData) => {
    // console.log(CalcData)

    for (let i = 0; i < CalcData.length; i++) {
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["Injection Speed"] },
        `A${i + 1}`
      );
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["FillTime (sec)"] },
        `B${i + 1}`
      );
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["Peak Inj Press"] },
        `C${i + 1}`
      );
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["Viscosity"] },
        `D${i + 1}`
      );
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["Shear Rate"] },
        `E${i + 1}`
      );
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["Absolute Drop Viscosity"] },
        `F${i + 1}`
      );
      ViscositySpreadsheet.current.updateCell(
        { value: CalcData[i]["%Drop Viscosity"] },
        `G${i + 1}`
      );
    }
  };

  // This is the event which deletes the row as clicked on the delete icon, id of the row gets passed and using filter method that row is filtered out.
  const deleteRow2 = (id) => {
    // console.log(rowCount)

    if (rowCount > 1) {
      ViscositySpreadsheet.current.delete(
        rowToBeDeleted,
        rowToBeDeleted,
        "Row",
        0
      );

      setRowCount(parseInt(rowCount) - 1);

      const NewRows = [...ViscosityGridData].filter((value) => {
        return value.id !== id;
      });

      // console.log(NewRows)

      setViscosityGridData(NewRows);

      showAlert.current = true;

      setAlert(true);
    }
  };

  const handleIntensification = (e) => {
    e.preventDefault();

    if (e.target.value) {
      setIntensificationRatio(e.target.value);
      showAlert.current = true;
    } else {
      setIntensificationRatio(1);
    }
  };

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // This event get's called on the Show Graph button and based on the data in the ViscosityGridData array it set's the values.
  const setGraph = () => {
    scaleGraph();
    scaleGraph2();
  };

  // Based on the grid we will be showing two chart's one is Injection Speed and other is shear rate so this is a boolean variable which switches between true/false on a Drop Down below and due to that the respective chart code gets rendered.
  const [Injection_Speed, setInjection_Speed] = useState(true);

  // This is the event which does the switching part.
  const ChangeGraph = () => {
    setInjection_Speed(!Injection_Speed);
  };

  // To get the Id's from url using useParams hook
  var { sessionId, moldName, sessionName } = useParams();

  // Variable to store the Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Session_Name, setSession_Name] = useState();

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
      showAlert.current = true;
    } else {
      // console.log(e.value)
      setComment(e.value);
      showAlert.current = true;
    }
  };

  useEffect(() => {
    // Simulate loading for a few seconds (you can replace this with your actual loading logic)
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  // Event to GET the current session's viscosity data
  const handleGet = (SessionId) => {
    if (SessionId) {
      DataService.FetchViscosity(SessionId)
        .then((res) => {
          if (res.data) {
            ToSaveViscosityData.current = false;

            setViscosityGridData(res.data.Grid_Data);

            // console.log(res.data.Grid_Data)

            setIntensificationRatio(res.data.IntensificationRatio);
            setComment(res.data.Comment);
            setPressure_Units(
              res.data.Pressure_Units === "NA" ? "" : res.data.Pressure_Units
            );

            setRowCount(
              res.data.Grid_Data.length === 0 ? 10 : res.data.Grid_Data.length
            );

            // console.log(res.data.Grid_Data.length);

            setYTitle(
              res.data.Pressure_Units === "NA" ? "" : res.data.Pressure_Units
            );
          } else {
            setViscosityGridData([]);
          }
        })
        .catch((err) => {});
    } else {
      setViscosityGridData([]);
    }
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      Injection_Speed_Units: Injection_Speed_Units
        ? Injection_Speed_Units
        : "in/sec",
      IntensificationRatio: IntensificationRatio ? IntensificationRatio : 0,
      Pressure_Units: Pressure_Units ? Pressure_Units : "NA",
      Grid_Data: ViscosityGridData,
      Comment: Comment ? Comment : "N/A",
      PrintData: {
        Injection_Speed_Units: Injection_Speed_Units,
        IntensificationRatio: IntensificationRatio,
        Pressure_Units: Pressure_Units === "" ? "NA" : Pressure_Units,
        Injection_Speed: Injection_Speed,
        Mold_Name: Mold_Name,
        Session_Name: Session_Name,
        Comment: Comment,
        niceNumbers: niceNumbers,
        InjectionniceNumbers: InjectionniceNumbers,
        ShearRateNiceNumbers: ShearRateNiceNumbers,
        InjectionSpeedArray: InjectionSpeedArray,
        YTitle: YTitle === "" ? "NA" : YTitle,
      },
    };

    ViscosityData.current = data;

    // console.log(data)

    // eslint-disable-next-line
  }, [
    ViscosityGridData,
    IntensificationRatio,
    Pressure_Units,
    Comment,
    rowCount,
    row,
  ]);

  // Event that will be called as soon as the Viscosity Curve Page load's so that if there is data available will get fetched
  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));

    // After that it call's this event to fetch the data
    handleGet(SessionId);

    // eslint-disable-next-line
  }, [sessionId, SessionId, moldName, sessionName]);

  // Hooks and Event's for RTE Control
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    showAlert.current = true;
  };

  const handleShow = () => setShow(true);

  // Hooks and Events for the Print Control
  const [showPrint, setShowPrint] = useState(false);

  const componentRef = useRef();

  const printPage = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setShowPrint(true),
    onAfterPrint: () => setShowPrint(false),
  });

  // UseState and Toggle Event to give an alert for Insufficient Data for plotting chart.
  const [InsufficientDataAlertForChart, setInsufficientDataAlertForChart] =
    useState(true);

  const ToggleInsufficientDataAlertForChart = () => {
    setInsufficientDataAlertForChart(!InsufficientDataAlertForChart);
  };

  const getInjectionValues = (data) => {
    return new Promise((resolve, reject) => {
      const injectionSpeed = data
        .filter((item) => item["Injection Speed"] !== null)
        .map((item) => item["Injection Speed"]);

      resolve(injectionSpeed);
    });
  };

  const CheckForInjectionSpeed = (injectionSpeed) => {
    return new Promise((resolve, reject) => {
      if (injectionSpeed.length > 0) {
        const injectionSpeedSet =
          new Set(injectionSpeed).size !== injectionSpeed.length;

        // console.log(new Set(injectionSpeed));

        if (injectionSpeedSet) {
          reject();
        } else {
          resolve(injectionSpeed);
        }
      } else {
        ToggleInsufficientDataAlertForChart();
      }
    });
  };

  const CheckForInjectionSpeedOnLoad = (injectionSpeed) => {
    return new Promise((resolve, reject) => {
      if (injectionSpeed.length > 0) {
        const injectionSpeedSet =
          new Set(injectionSpeed).size !== injectionSpeed.length;

        // console.log(injectionSpeedSet);

        if (injectionSpeedSet) {
          reject();
        } else {
          resolve(injectionSpeed);
        }
      } else {
      }
    });
  };

  const CheckForInjectionSpeedOnPrint = (injectionSpeed) => {
    return new Promise((resolve, reject) => {
      if (injectionSpeed.length > 0) {
        const injectionSpeedSet =
          new Set(injectionSpeed).size !== injectionSpeed.length;

        // console.log(new Set(injectionSpeed));

        if (injectionSpeedSet) {
          reject();
        } else {
          resolve(injectionSpeed);
        }
      } else {
        ToggleInsufficientDataAlertForChart();
      }
    });
  };

  // Event to Scroll to Chart
  const PlotChart = () => {
    const nonZeroViscosityArray = ViscosityGridData.filter(
      (item) => item.Viscosity !== 0
    ).map((item) => item.Viscosity);

    setYTitle(Pressure_Units === "NA" ? "" : Pressure_Units);

    getInjectionValues(ViscosityGridData).then((injectionSpeed) => {
      CheckForInjectionSpeed(injectionSpeed)
        .then(() => {
          if (nonZeroViscosityArray.length > 0) {
            setGraph();
          }

          //   console.log(nonZeroViscosityArray)
        })
        .catch((err) => {
          // Toggle Event to Plot the chart on Load
          ToggleInjectionSpeedAlert();

          setInjectionSpeedArray([]);
        });
    });
  };

  const [InjectionSpeedArray, setInjectionSpeedArray] = useState([]);

  const PlotChartOnLoad = () => {
    const nonZeroViscosityArray = ViscosityGridData.filter(
      (item) => item.Viscosity !== 0
    ).map((item) => item.Viscosity);

    setYTitle(Pressure_Units === "NA" ? "" : Pressure_Units);

    getInjectionValues(ViscosityGridData).then((injectionSpeed) => {
      // console.log(ViscosityGridData, injectionSpeed)
      // console.log(nonZeroViscosityArray)

      CheckForInjectionSpeedOnLoad(injectionSpeed)
        .then(() => {
          if (nonZeroViscosityArray.length > 0) {
            setGraph();
          }
        })
        .catch((err) => {
          // setInjectionSpeedArray([]);
        });
    });
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) PlotChartOnLoad();

    // eslint-disable-next-line
  }, [ViscosityGridData]);

  // Set's the visibility of HTML Part to true and then call's print event
  const handlePrint = () => {
    getInjectionValues(ViscosityGridData).then((injectionSpeed) => {
      CheckForInjectionSpeedOnPrint(injectionSpeed)
        .then((injectionSpeed) => {
          // console.log(injectionSpeed)

          if (injectionSpeed.length > 0) {
            setShowPrint(true);

            setTimeout(() => {
              printPage();
            }, 100);

            setGraph();
          } else {
            ToggleInsufficientDataAlertForChart();
          }
        })
        .catch((err) => {
          ToggleInjectionSpeedAlert();
        });
    });
  };

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [niceNumbers, setNiceNumbers] = useState({
    min: null,
    max: null,
    step: null,
  });

  const [InjectionniceNumbers, setInjectionNiceNumbers] = useState({
    min: null,
    max: null,
    step: null,
  });

  const [ShearRateNiceNumbers, setShearRateNiceNumbers] = useState({
    min: null,
    max: null,
    step: null,
  });

  // Event to set the Min, Max and Interval of graph i.e scalling the graph
  const scaleGraph = () => {
    if (ViscosityGridData.length > 0) {
      // To store the sorted Array
      let InjectionArray = [],
        ViscosityArray = [];

      // This is the event to sort the data based on Injection Speed
      const CompareInjectionSpeed = (a, b) => {
        return a["Injection Speed"] - b["Injection Speed"];
      };

      // This is the event to sort the data based on Viscosity
      const CompareViscosity = (a, b) => {
        return a.Viscosity - b.Viscosity;
      };

      for (let i = 0; i < ViscosityGridData.length; i++) {
        if (ViscosityGridData[i]["Injection Speed"]) {
          InjectionArray.push(ViscosityGridData[i]);
          ViscosityArray.push(ViscosityGridData[i]);
        } else {
        }
      }

      // ViscosityGridData.sort(CompareInjectionSpeed);
      InjectionArray.sort(CompareInjectionSpeed);
      ViscosityArray.sort(CompareViscosity);

      setInjectionSpeedArray(InjectionArray);

      // PopulateViscositySheet(InjectionArray);

      if (
        parseFloat(ViscosityArray[0].Viscosity) !==
        parseFloat(ViscosityArray[InjectionArray.length - 1].Viscosity)
      ) {
        setNiceNumbers(
          calculateNiceNumbers(
            ViscosityArray[0].Viscosity,
            ViscosityArray[InjectionArray.length - 1].Viscosity,
            5
          )
        );
      }

      if (
        parseFloat(InjectionArray[0]["Injection Speed"]) !==
        parseFloat(InjectionArray[InjectionArray.length - 1]["Injection Speed"])
      ) {
        setInjectionNiceNumbers(
          calculateNiceNumbers(
            InjectionArray[0]["Injection Speed"],
            InjectionArray[InjectionArray.length - 1]["Injection Speed"],
            5
          )
        );
      }
    } else {
      scroller.scrollTo("viscosity-Chart", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  useEffect(() => {
    // console.log(
    //   InjectionSpeedArray,
    //   "Viscosity",
    //   niceNumbers,
    //   "Injection",
    //   InjectionniceNumbers,
    //   "Shear Rate",
    //   ShearRateNiceNumbers
    // );
  }, [
    InjectionSpeedArray,
    niceNumbers,
    InjectionniceNumbers,
    ShearRateNiceNumbers,
  ]);

  let shearRateArray = [];

  // Event to set the Min, Max and Interval of graph i.e scalling the graph
  const scaleGraph2 = () => {
    if (ViscosityGridData.length > 0) {
      // This is the event to sort the data based on Shear Rate
      const compareShearRate = (a, b) => {
        return a["Shear Rate"] - b["Shear Rate"];
      };

      for (let i = 0; i < ViscosityGridData.length; i++) {
        if (ViscosityGridData[i]["Injection Speed"]) {
          // InjectionArray.push(ViscosityGridData[i]);
          shearRateArray.push(ViscosityGridData[i]);
        } else {
        }
      }

      // InjectionArray.sort(CompareInjectionSpeed);
      shearRateArray.sort(compareShearRate);

      // PopulateViscositySheet(InjectionSpeedArray);

      if (
        parseFloat(shearRateArray[0]["Shear Rate"]) !==
        parseFloat(shearRateArray[shearRateArray.length - 1]["Shear Rate"])
      ) {
        setShearRateNiceNumbers(
          calculateNiceNumbers(
            shearRateArray[0]["Shear Rate"],
            shearRateArray[shearRateArray.length - 1]["Shear Rate"],
            5
          )
        );
      }
    } else {
      scroller.scrollTo("viscosity-Chart", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Modal isOpen={!InjectionSpeedAlert} centered={true}>
            <ModalHeader toggle={ToggleInjectionSpeedAlert}>
              {" "}
              Nautilus{" "}
            </ModalHeader>
            <ModalBody>
              <span> Injection Speed values cannot repeat. </span>
            </ModalBody>
            <ModalFooter>
              <Button color="warning" onClick={ToggleInjectionSpeedAlert}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={!InsufficientDataAlertForChart} centered={true}>
            <ModalHeader toggle={ToggleInsufficientDataAlertForChart}>
              {" "}
              Nautilus{" "}
            </ModalHeader>
            <ModalBody>
              <span> The data is Insufficient. </span>
            </ModalBody>
            <ModalFooter>
              <Button
                color="warning"
                onClick={ToggleInsufficientDataAlertForChart}
              >
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <div className="card sixstepCard p-3 ml-2">
            <div
              /*className="card"style={{ border: "1px solid #573DAC"  }}*/ className="b-primary b-r-4 mb-2"
            >
              <form autoComplete="off">
                <div className="viscocity-curve">
                  <InputSection
                    setInjection_Speed_Units={setInjection_Speed_Units}
                    handleIntensification={handleIntensification}
                    setPressure_Units={setPressure_Units}
                    Pressure_Units={Pressure_Units}
                    IntensificationRatio={IntensificationRatio}
                    showAlert={showAlert}
                  />
                </div>
              </form>
            </div>

            <div
              /*className="card" style={{ border: "1px solid #573DAC" }}*/ className="b-primary b-r-4 mb-1 pt-2 pr-2"
            >
              <div className="mt-1">
                <div>
                  <ButtonSection
                    ToggleAddRowModal={ToggleAddRowModal}
                    deleteRow2={deleteRow2}
                    ViscocityAddRow={ViscocityAddRow}
                    ViscosityAddRowModal={ViscosityAddRowModal}
                    addRow={addRow}
                    increaseRow={increaseRow}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    PlotChart={PlotChart}
                    handlePrint={handlePrint}
                    show={show}
                    toolbarSettings={toolbarSettings}
                    getComment={getComment}
                    Comment={Comment}
                    ChangeGraph={ChangeGraph}
                  />
                </div>

                <div className="d-flex">
                  <div
                    className="col-md-6"
                    style={{ width: "50%" }}
                    onClick={() => setToPlotChart(false)}
                  >
                    <ViscocityGrid
                      ViscosityGridData={ViscosityGridData}
                      IntensificationRatio={IntensificationRatio}
                      Alert={Alert}
                      rowCount={rowCount}
                      ViscositySpreadsheet={ViscositySpreadsheet}
                      rowToBeDeleted={rowToBeDeleted}
                      setRowToBeDeleted={setRowToBeDeleted}
                      setViscosityGridData={setViscosityGridData}
                      PopulateViscositySheet={PopulateViscositySheet}
                      showAlert={showAlert}
                    />
                  </div>

                  <div className="ml-2 col-md-6" style={{ width: "50%" }}>
                    <ChartSection
                      Injection_Speed={Injection_Speed}
                      Injection_Speed_Units={Injection_Speed_Units}
                      InjectionSpeedArray={InjectionSpeedArray}
                      niceNumbers={niceNumbers}
                      InjectionniceNumbers={InjectionniceNumbers}
                      ShearRateNiceNumbers={ShearRateNiceNumbers}
                      YTitle={YTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ******************** Printable Part ******************** */}
          <section
            className={showPrint ? "Printable_Part" : "Showable_Part"}
            ref={componentRef}
          >
            <ViscosityPrint
              Injection_Speed_Units={Injection_Speed_Units}
              IntensificationRatio={IntensificationRatio}
              Pressure_Units={Pressure_Units}
              Injection_Speed={Injection_Speed}
              Mold_Name={Mold_Name}
              Session_Name={Session_Name}
              Comment={Comment}
              niceNumbers={niceNumbers}
              InjectionniceNumbers={InjectionniceNumbers}
              ShearRateNiceNumbers={ShearRateNiceNumbers}
              InjectionSpeedArray={InjectionSpeedArray}
              YTitle={YTitle}
            />
          </section>
        </>
      )}
    </>
  );
};

export default ViscocityCurve;
