function in_To_mm(value, Decimals) {
  let CalcValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value) * 25.4;
  return isNaN(parseFloat(value))
    ? "NA"
    : parseFloat(CalcValue).toFixed(Decimals);
}

const CalculateVolume = (Tgt_Mach_Unit, Param1, Param2, SelectedMachine) => {
  let Selected_Mach_Screw_Diam =
    parseInt(Tgt_Mach_Unit) === 6
      ? in_To_mm(parseFloat(SelectedMachine.Screw_Diameter), 2)
      : parseFloat(SelectedMachine.Screw_Diameter);

  let RefVolume = (
    0.785 *
    parseFloat(Selected_Mach_Screw_Diam) *
    parseFloat(Selected_Mach_Screw_Diam) *
    (parseFloat(Param1) - parseFloat(Param2))
  ).toFixed(5);

  return RefVolume;
};

const CalculateFillTime = (RefParam1, RefParam2, RefParam3) => {
  let FillTime =
    (parseFloat(RefParam1) - parseFloat(RefParam2)) / parseFloat(RefParam3);

  //   console.log(RefParam1, RefParam2, RefParam3);

  return FillTime;
};

const CalculateMoldParam = ({
  Tgt_Mach_Screw_Dia,
  Tgt_Mach_Unit,
  RefParam1,
  RefParam2,
  TgtParam,
  SelectedMachine,
  Param,
}) => {
  return new Promise((resolve, reject) => {
    if (RefParam1 !== 0 && RefParam2 !== 0) {
      let RefParam1Val =
        parseInt(Tgt_Mach_Unit) === 6 ? in_To_mm(RefParam1, 2) : RefParam1;

      let RefParam2Val =
        parseInt(Tgt_Mach_Unit) === 6 ? in_To_mm(RefParam2, 2) : RefParam2;

      let CalcVal;

      let RefVolume = CalculateVolume(
        Tgt_Mach_Unit,
        RefParam1Val,
        RefParam2Val,
        SelectedMachine
      );

      CalcVal =
        RefVolume /
          (0.785 *
            parseFloat(Tgt_Mach_Screw_Dia) *
            parseFloat(Tgt_Mach_Screw_Dia)) +
        parseFloat(TgtParam);

      resolve([CalcVal]);
    } else {
      resolve([null]);
    }
  });
};

const CalculateIVParams = ({
  RefParam1,
  RefParam2,
  RefParam3,
  TgtParam1,
  TgtParam2,
  MachineParam,
}) => {
  if (
    parseFloat(TgtParam1) &&
    parseFloat(TgtParam2) &&
    parseFloat(RefParam1) &&
    parseFloat(RefParam2) &&
    parseFloat(RefParam3)
  ) {
    let FillTime = CalculateFillTime(RefParam1, RefParam2, RefParam3);

    // console.log(RefParam1, RefParam2, RefParam3, TgtParam1, TgtParam2);

    let Calc =
      (parseFloat(TgtParam1) - parseFloat(TgtParam2)) /
      parseFloat(FillTime).toFixed(5);

    //   console.log(parseFloat(Calc) , parseFloat(MachineParam));

    return {
      Calc: parseFloat(Calc.toFixed(2)),
      result: parseFloat(Calc) < parseFloat(MachineParam),
    };
  } else {
    return { Calc: null, result: null };
  }
};

const CalculateScrewRot = ({
  Tgt_Mach_Unit,
  RefParam1,
  RefParam2,
  TgtParam,
  MachineParam,
}) => {
  let RefParam1Val =
    parseInt(Tgt_Mach_Unit) === 6 ? in_To_mm(RefParam1, 2) : RefParam1;

  let Calc =
    (parseFloat(RefParam1Val) * parseFloat(RefParam2)) / parseFloat(TgtParam);

  return {
    Calc: Math.round(Calc),
    result: parseFloat(Calc) < parseFloat(MachineParam),
  };
};

const ComputeIRFields = ({ RefParam1, RefParam2, TgtParam, MachineParam }) => {
  let Calc =
    (parseFloat(RefParam1) * parseFloat(RefParam2)) / parseFloat(TgtParam);

  // console.log(RefParam1, RefParam2, TgtParam)

  return {
    Calc: Math.round(Calc),
    result: parseFloat(Calc) < parseFloat(MachineParam),
  };
};

export default {
  CalculateIVParams,
  CalculateMoldParam,
  CalculateScrewRot,
  ComputeIRFields,
};
