import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import '../../../assets/custom-stylesheet/grid_stylecss.css';

const BackCalcPressure = ({ column, Average, MaxPart, MinPart, Range }) => {

    useEffect(() => {

        // console.log('Average', Average, 'Max', MaxPart, 'Min', MinPart, 'Range', Range)

    }, [Average, MaxPart, MinPart, Range])

    return (
        <div>
            <div className="spreadsheet" style={{ width: '100%', height: '170px' }}>

                <div className='container-fluid mt-1'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div>
                                    <div className="Cavity-Grid-Container backGrid" style={{ border: '1px solid #573DAC' }}>
                                        <div>
                                            <Table striped bordered hover responsive variant="light">
                                                <thead>
                                                    <tr>
                                                        <th> <span> {"Title"} </span> </th>

                                                        {column.map((value, key) => (
                                                            <React.Fragment key={value.id}>
                                                                <th key={key}> <span> {value.header} </span> </th>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody className="grid_style">

                                                    <tr>
                                                        <td> {"Range"} </td>

                                                        {column.map((value1, key1) => (
                                                            <React.Fragment key={value1.id}>
                                                                <td> {Range[key1] || ''} </td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td> {"Min"} </td>

                                                        {column.map((value1, key1) => (
                                                            <React.Fragment key={value1.id}>
                                                                <td> {MinPart[key1] || ''} </td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td> {"Max"} </td>
                                                        {column.map((value1, key1) => (
                                                            <React.Fragment key={value1.id}>
                                                                <td> {MaxPart[key1] || ''} </td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td> {"Average"} </td>
                                                        {column.map((value1, key1) => (
                                                            <React.Fragment key={value1.id}>
                                                                <td> {Average[key1] || ''} </td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default BackCalcPressure;