// Auth Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const LOGOUT = 'LOGOUT';

// Database Types
export const GET_STORED_UNITS = 'GET_STORED_UNITS';
export const NO_STORED_UNITS = 'NO_STORED_UNITS';

export const USER_SELECTED_UNITS = 'USER_SELECTED_UNITS';
export const NO_SELECTED_UNITS = 'NO_SELECTED_UNITS';

export const GET_MATERIAL_DATA = 'GET_MATERIAL_DATA';
export const NO_MATERIAL_DATA = 'NO_MATERIAL_DATA';

export const GET_MACHINE_DATA = 'GET_MACHINE_DATA';
export const NO_MACHINE_DATA = 'NO_MACHINE_DATA';

export const GET_MOLD_DATA = 'GET_MOLD_DATA';
export const NO_MOLD_DATA = 'NO_MOLD_DATA';

export const GET_FIELDS_DATA = 'GET_FIELDS_DATA';
export const NO_FIELDS_DATA = 'NO_FIELDS_DATA';

export const GET_PPS_DATA = 'GET_PPS_DATA';
export const NO_PPS_DATA = 'NO_PPS_DATA';

