import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  Toolbar,
  Page,
} from "@syncfusion/ej2-react-grids";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

// CSS
import "../../../App.css";
import DataService from "../../../../services/ApiService";
import { connect } from "react-redux";

// CSS
import "../../../App.css";

const MachineDatabase = ({ user }) => {
  var grid;

  const toolbar = ["Search"];

  const history = useHistory();

  const [SelectRowModal, setSelectRowModal] = useState(false);

  const [column, setColumn] = useState([
    {
      field: "Machine_Number",
      headerText: "Mach No",
      textAlign: "center",
      // width: "120",
    },
    {
      field: "Make",
      headerText: "Make",
      textAlign: "center",
      // width: "75",
    },
    {
      field: "Type(Platen_Orientation)",
      headerText: "Type",
      textAlign: "center",
      // width: "90",
    },
    {
      field: "Tonnage",
      headerText: "Tonnage",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Screw_Diameter",
      headerText: "Screw Dia",
      textAlign: "center",
      // width: "50",
    },
    {
      field: "Max_Screw_Rotation_Speed",
      headerText: "Max Screw Rotation Speed",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Max_Screw_Rotation_Liner_Speed",
      headerText: "Max Screw Rotation Liner Speed",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Max_Machine_Pressure",
      headerText: "Max Hydraulic Pressure",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Intensification_Ratio",
      headerText: "IR",
      textAlign: "center",
      // width: "60",
    },
    {
      field: "Max_Plastic_Pressure",
      headerText: "Max PL Press",
      textAlign: "center",
      // width: "90",
    },
    {
      field: "Max_shot_Capacity(Wt)",
      headerText: "Short Size(Wt)",
      textAlign: "center",
      // width: "65",
    },
    {
      field: "Max_Melt_Temperature",
      headerText: "Max Melt Temp",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Min_allowable_Mold_Stack_Height",
      headerText: "Min Stack HT",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Max_allowable_Mold_Stack_Height",
      headerText: "Max Stack HT",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Max_Mold_Open_Daylight",
      headerText: "Max Mold Open Daylight",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Tiebar_Clearance-Width",
      headerText: "Tiebar Clearance-Width**",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Max_Mold_Vertical_Height",
      headerText: "Max Mold Vertical Ht",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Max_Mold_Width",
      headerText: "Max Mold Width",
      textAlign: "center",
      // width: "70",
    },
    {
      field: "Number_of_Core_Pulls",
      headerText: "Number of Core Pulls",
      textAlign: "center",
      // width: "70",
    },
  ]);

  const pathN = () => {
    history.push({
      pathname: `/database/Options/MachineNew`,
    });
  };

  const pathE = () => {
    // console.log(selectedRowIndexes.Machine_Id);

    if (selectedRowIndexes.Machine_Id) {
      let RowId = btoa(selectedRowIndexes.Machine_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MachineEdit`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const pathV = () => {
    if (selectedRowIndexes.Machine_Id) {
      let RowId = btoa(selectedRowIndexes.Machine_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MachineView`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const pathD = () => {
    if (selectedRowIndexes.Machine_Id) {
      let RowId = btoa(selectedRowIndexes.Machine_Id);

      history.push({
        pathname: `/database/Options/${RowId}/MachineDuplicate`,
      });
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const [selectedRowIndexes, setSelectedRowIndexes] = useState({
    rowIdx: "",
    Material_Id: "",
  });

  const [MachineId, setMachineId] = useState([]);

  const [AllMachineData, setAllMachineData] = useState([]);

  const selectionSettings = { type: "Single" };

  const click = () => {
    setSelectedRowIndexes({
      rowIdx: grid.getSelectedRowIndexes(),
      Machine_Id: grid.getSelectedRecords()[0]?.id,
    });

    // console.log(grid.getSelectedRowIndexes())
  };

  const [DeleteConfirm, setDeleteConfirm] = useState(false);

  const [DeleteConfirmHeader, setDeleteConfirmHeader] = useState("");

  const ToggleDeleteConfirm = () => {
    if (selectedRowIndexes.Machine_Id) {
      setDeleteConfirm(!DeleteConfirm);

      setDeleteConfirmHeader(
        selectedRowIndexes
          ? AllMachineData[selectedRowIndexes.rowIdx].Machine_Number
          : ""
      );
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  function created(args) {
    if (grid) {
      document
        .getElementById(grid.element.id + "_searchbar")
        .addEventListener("keyup", (args) => {
          var gridObj = document.getElementById("MachineGrid").ej2_instances[0];
          gridObj.search(args.target.value);
        });
    }
  }

  const OnDelete = () => {
    if (selectedRowIndexes.Machine_Id) {
      const updatedRows = [...AllMachineData].filter((value, idx) => {
        return value.id !== selectedRowIndexes.Machine_Id;
      });

      setAllMachineData(updatedRows);

      DataService.DeleteMachinesData(selectedRowIndexes.Machine_Id).then(
        (res) => {
          // console.log(res.data.Machine_Data);

          ToggleDeleteConfirm();
        }
      );
    } else {
      setSelectRowModal(!SelectRowModal);
    }
  };

  const GetData = (id) => {
    DataService.GetMachinesData(id).then((res) => {
      if (res.data.length > 0) {
        // console.log(res.data);

        let TempArray = [];

        for (let i = 0; i < res.data.length; i++) {
          TempArray.push(
            res.data[i].Machine_Data[0] || res.data[i].Machine_Data
          );

          TempArray[i]["id"] = res.data[i].Machine_Id;

          MachineId.push(res.data[i].Machine_Id);
        }

        setAllMachineData(TempArray);
      } else {
        setAllMachineData([]);
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);

      DataService.GetFieldsData(user.id).then((res) => {
        // console.log(res.data)

        if (res.data.length > 0) {
          if (res.data[0].Machine_Custom_Fields.length > 0) {
            // console.log(...res.data[0].Machine_Custom_Fields);
            setColumn([...column, ...res.data[0].Machine_Custom_Fields]);
          }
        }
      });
    }
  }, [user]);

  useEffect(() => {
    // console.log(MachineId)
  }, [MachineId]);

  return (
    <>
      <Modal isOpen={SelectRowModal} centered={true} style={{ width: "300" }}>
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> Please click on a row to select. </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => setSelectRowModal(!SelectRowModal)}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={DeleteConfirm} centered={true} style={{ width: "400px" }}>
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span>
            {" "}
            Do you want to delete Machine Number {DeleteConfirmHeader} ?{" "}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={OnDelete}>
            {" "}
            Delete{" "}
          </Button>
          <Button color="primary" onClick={ToggleDeleteConfirm}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="card p-3 ml-2">
        <div className="d-flex col-md-16">
          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-info btn-air-info mr-2"
              onClick={pathN}
            >
              New
            </button>
          </div>

          <div className="pt-2 pb-2">
            <button
              className="btn btn-secondary btn-air-secondary mr-2"
              onClick={pathE}
            >
              Edit
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={pathV}
            >
              View
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-primary btn-air-primary mr-2"
              onClick={pathD}
            >
              Duplicate
            </button>
          </div>

          <div className="pt-2 pb-2 text-left">
            <button
              className="btn btn-warning btn-air-warning mr-2"
              onClick={ToggleDeleteConfirm}
            >
              Delete
            </button>
          </div>

          <div className="pt-2 pb-2">
            <button className="btn btn-secondary btn-air-secondary mr-2">
              Print
            </button>
          </div>
        </div>

        <div className="table-responsive" id="DBTable">
          <table className="table">
            <tr>
              <td onClick={click}>
                <GridComponent
                  ref={(g) => (grid = g)}
                  id="MachineGrid"
                  toolbar={toolbar}
                  dataSource={AllMachineData}
                  columns={column}
                  allowPaging={true}
                  pageSettings={{ pageSize: 10 }}
                  selectionSettings={selectionSettings}
                  created={created.bind(this)}
                  height={300}
                >
                  <Inject services={[Toolbar, Page]} />
                </GridComponent>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MachineDatabase);
